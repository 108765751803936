import { Sidebar } from 'components/UI';

import { ContentWrapper, Wrapper } from './styles';
import { MainLayoutPropsType } from './types';

export const MainLayout = ({ children }: MainLayoutPropsType) => {
  return (
    <Wrapper>
      <Sidebar />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
