import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { DeleteIcon, EditIcon, UsersIcon } from 'components/Icons';
import { getUserInfoData } from 'connectors';
import { MODAL_NAME, ROUTES } from 'constants/index';
import { useModalContext } from 'context';
import { GetEarnedPaidDataByUserQuery, GetUserByIdQuery } from 'graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { palette } from 'theme/foundations';

import { EarnedInfoItem, InfoItem } from './styles';

export type UserInfoType = {
  userData?: GetUserByIdQuery['getUserById'];
  earnedData?: GetEarnedPaidDataByUserQuery['getEarnedPaidDataByUser'];
};

export const UserInfo = ({ userData, earnedData }: UserInfoType) => {
  const navigate = useNavigate();

  const data = getUserInfoData({ userData, earnedData });

  const { onOpenModal } = useModalContext();

  if (!data) {
    return null;
  }

  const { username, role, detailInfo, id, profit, earnedInfo, photo } = data;

  const handleNavigateUserList = () => {
    navigate(ROUTES.users);
  };

  const handleEditUser = (id: number) => {
    onOpenModal(MODAL_NAME.ADD_EDIT_USER, { id });
  };

  const handleDeleteUser = (id: number, username?: string) => {
    onOpenModal(MODAL_NAME.DELETE_USER, { id, username, handleNavigate: handleNavigateUserList });
  };

  return (
    <Stack direction={'row'} gap='25px' mb={'24px'}>
      <InfoItem sx={{ flexDirection: 'row', gap: '16px' }}>
        <Avatar sx={{ width: 128, height: 128 }} src={photo}>
          <UsersIcon size={64} fill={palette.secondary.main} />
        </Avatar>
        <Stack sx={{ gap: '4px' }}>
          <Typography variant='h4'>{username}</Typography>
          <Typography variant='h5' fontWeight={400} color={palette.black1} mb={'8px'}>
            {role}
          </Typography>
          {detailInfo?.map(({ label, value }, index) => {
            return (
              <Stack direction={'row'} gap={'4px'} key={index}>
                <Typography
                  variant='body1'
                  lineHeight={'24px'}
                  color={palette.gray41}
                  sx={{ textWrap: 'nowrap' }}
                >
                  {label}:
                </Typography>
                <Typography
                  variant='body1'
                  lineHeight={'24px'}
                  fontWeight={500}
                  sx={{ textWrap: 'nowrap' }}
                >
                  {value}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
        <Stack sx={{ marginLeft: 'auto', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Stack direction={'row'} gap={'24px'}>
            <IconButton sx={{ padding: 0 }} onClick={() => handleEditUser(Number(id))}>
              <EditIcon fill={palette.green100} width='24' height='24' />
            </IconButton>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => handleDeleteUser(Number(id), String(username))}
            >
              <DeleteIcon fill={palette.red} width='24' height='24' />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              padding: '4px 12px',
              border: `1px solid ${palette.blue90}`,
              borderRadius: '4px',
              boxShadow: '0px 2px 20px 0px rgba(18, 18, 18, 0.08)',
            }}
          >
            <Typography
              variant='body1'
              fontWeight={500}
              color={palette.black50}
              sx={{ textWrap: 'nowrap' }}
            >
              Profit {profit}%
            </Typography>
          </Stack>
        </Stack>
      </InfoItem>
      <InfoItem sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h4'>Earned for all time</Typography>
        <Stack direction={'row'} gap={'24px'}>
          {earnedInfo.map(({ Icon, value }, index) => {
            return (
              <EarnedInfoItem key={index}>
                <Icon />
                <Typography variant='h4' color={palette.black50}>
                  {value.toLocaleString('en')}
                </Typography>
              </EarnedInfoItem>
            );
          })}
        </Stack>
      </InfoItem>
    </Stack>
  );
};
