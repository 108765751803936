import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.gray5,
  paddingTop: 16,
  paddingBottom: 16,
  paddingRight: 32,
  paddingLeft: 20,
  maxHeight: 'calc(100vh - 72px)',
  overflowY: 'auto',
}));
