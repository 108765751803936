export const getActionButtonTitleForEducations = (tabIndex: number) => {
  switch (tabIndex) {
    case 0:
      return 'Add new training';
    case 1:
      return 'Add new contacts';
    case 2:
      return 'Gostelow report';
    default:
      return 'Add new hotel';
  }
};
