import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
};

export type AccountObject = {
  __typename?: 'AccountObject';
  /** account's comment */
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** account's link */
  link?: Maybe<Scalars['String']['output']>;
  /** account's login */
  login?: Maybe<Scalars['String']['output']>;
  /** account's password */
  password?: Maybe<Scalars['String']['output']>;
  /** account's title */
  title?: Maybe<Scalars['String']['output']>;
  userAccounts?: Maybe<Array<UserAccountObject>>;
  users?: Maybe<Array<UserObject>>;
};

export type AccountObjectRowsAndCountType = {
  __typename?: 'AccountObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<AccountObject>;
};

export type AdditionalDataObject = {
  __typename?: 'AdditionalDataObject';
  address?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  contact_person?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Array<EmailObject>>;
  id?: Maybe<Scalars['Int']['output']>;
  phones?: Maybe<Array<PhoneObject>>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  whats_app?: Maybe<Scalars['String']['output']>;
};

export type ContactFiltersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  /** Available variants: [desc,asc]. */
  sort?: InputMaybe<SortTypeEnum>;
  /** Available variants: [contact_person,title]. */
  sortBy?: InputMaybe<ContactSortVariantsEnum>;
  /** Available variants: [hotel,cruises,dmc,other] */
  type?: InputMaybe<ContactTypesEnum>;
};

export type ContactObject = {
  __typename?: 'ContactObject';
  /** additional Information */
  additional_data?: Maybe<AdditionalDataObject>;
  /** contact city */
  city?: Maybe<Scalars['String']['output']>;
  /** commission */
  commission?: Maybe<Scalars['String']['output']>;
  /** contact company */
  company?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** profit */
  profit?: Maybe<Scalars['Float']['output']>;
  /** contact title */
  title?: Maybe<Scalars['String']['output']>;
  /** contact type */
  type?: Maybe<ContactTypesEnum>;
};

export type ContactObjectRowsAndCountType = {
  __typename?: 'ContactObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<ContactObject>;
};

export enum ContactSortVariantsEnum {
  ContactPerson = 'contact_person',
  Title = 'title',
}

export enum ContactTypesEnum {
  Cruises = 'CRUISES',
  Dmc = 'DMC',
  Hotel = 'HOTEL',
  Other = 'OTHER',
}

export type CreateAccountInput = {
  /** account's comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** account's link */
  link: Scalars['String']['input'];
  /** account's login */
  login: Scalars['String']['input'];
  /** account's password */
  password: Scalars['String']['input'];
  /** account's title */
  title: Scalars['String']['input'];
};

export type CreateContactInput = {
  /** offer's address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** offer's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** only for type other */
  commission?: InputMaybe<Scalars['String']['input']>;
  /** only for type cruises, other */
  company?: InputMaybe<Scalars['String']['input']>;
  /** contact person */
  contact_person: Scalars['String']['input'];
  /** offer's country */
  country: Scalars['String']['input'];
  /** list of emails */
  emails: Array<Scalars['String']['input']>;
  /** list of prone numbers */
  phones: Array<Scalars['String']['input']>;
  /** only for type cruises */
  profit?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** contact type */
  type: ContactTypesEnum;
  /** whatsapp phone number or url */
  whats_app?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEarnedInput = {
  almont_account: Scalars['Float']['input'];
  booking_amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  current_profit: Scalars['Float']['input'];
  date_to_travel: Scalars['String']['input'];
  description: Scalars['String']['input'];
  invoice: Scalars['Int']['input'];
  mark_up: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  total_profit: Scalars['Float']['input'];
  user_account: Scalars['Float']['input'];
  user_id: Scalars['Int']['input'];
};

export type CreateEducationInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  fileIds: Array<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: EducationTypeEnum;
};

export type CreateMembershipInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  exclusiveBenefits?: InputMaybe<Scalars['String']['input']>;
  howToUse?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  participating?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNotificationInput = {
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateOfferInput = {
  /** offer's address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** offer's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** contact person */
  contact_person: Scalars['String']['input'];
  /** offer's country */
  country: Scalars['String']['input'];
  /** currency iso */
  currency: Scalars['String']['input'];
  date_from: Scalars['DateTime']['input'];
  date_to: Scalars['DateTime']['input'];
  /** list of emails */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** information */
  information: Scalars['String']['input'];
  /** link */
  link?: InputMaybe<Scalars['String']['input']>;
  /** only for client */
  onlyForClient?: InputMaybe<Scalars['Boolean']['input']>;
  order: Scalars['Int']['input'];
  /** list of prone numbers */
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Id of file */
  preview_file_id: Scalars['Int']['input'];
  /** offer min price */
  price_from?: InputMaybe<Scalars['Float']['input']>;
  /** offer max price */
  price_to?: InputMaybe<Scalars['Float']['input']>;
  /** offer's title */
  title: Scalars['String']['input'];
  /** whatsapp phone number or url */
  whats_app?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOwnDocument = {
  comment?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  owner: Scalars['String']['input'];
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title: Scalars['String']['input'];
};

export type CreatePaidInput = {
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  current_profit: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  invoice: Scalars['Int']['input'];
  note: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};

export type CreatePassportDocumentInput = {
  birthDate: Scalars['DateTime']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  expiry: Scalars['DateTime']['input'];
  issued: Scalars['DateTime']['input'];
  owner: Scalars['String']['input'];
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serialNumber: Scalars['String']['input'];
};

export type CreateUserInput = {
  birthday: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  is_success_manager?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number: Scalars['String']['input'];
  profit: Scalars['Float']['input'];
  section_ids: Array<Scalars['Int']['input']>;
  success_manager_id?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateVisaDocumentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  expiry: Scalars['DateTime']['input'];
  issued: Scalars['DateTime']['input'];
  owner: Scalars['String']['input'];
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serialNumber: Scalars['String']['input'];
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export type DocumentFiltersInput = {
  /** Available variants: [type,owner,issued] */
  field?: InputMaybe<DocumentSortFieldsEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  /** Available variants: [desc,asc]. */
  sort?: InputMaybe<SortTypeEnum>;
};

export type DocumentObject = {
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  owner: Scalars['String']['output'];
  photos?: Maybe<Array<FileObject>>;
  type: DocumentTypeEnum;
};

export type DocumentOptionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum DocumentSortFieldsEnum {
  DateFrom = 'DATE_FROM',
  Owner = 'OWNER',
  Type = 'TYPE',
}

export enum DocumentTypeEnum {
  Own = 'OWN',
  Passport = 'PASSPORT',
  Visa = 'VISA',
}

export type DocumentUnion = OwnDocumentObject | PassportDocumentObject | VisaDocumentObject;

export type EarnedGroupObject = {
  __typename?: 'EarnedGroupObject';
  EUR?: Maybe<Array<EarnedObject>>;
  GBP?: Maybe<Array<EarnedObject>>;
  USD?: Maybe<Array<EarnedObject>>;
};

export type EarnedObject = {
  __typename?: 'EarnedObject';
  almont_account: Scalars['Float']['output'];
  booking_amount: Scalars['Float']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  currency: CurrencyEnum;
  current_profit: Scalars['Float']['output'];
  date_to_travel: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  invoice: Scalars['Int']['output'];
  mark_up: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  total_profit: Scalars['Float']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_account: Scalars['Float']['output'];
  user_id: Scalars['Float']['output'];
};

export type EarnedObjectRowsAndCountType = {
  __typename?: 'EarnedObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<EarnedObject>;
};

export type EarnedPaidDiffByCurrencyObject = {
  __typename?: 'EarnedPaidDiffByCurrencyObject';
  EUR: Scalars['Float']['output'];
  GBP: Scalars['Float']['output'];
  USD: Scalars['Float']['output'];
};

export type EarnedTotalGroupObject = {
  __typename?: 'EarnedTotalGroupObject';
  EUR: EarnedTotalObject;
  GBP: EarnedTotalObject;
  USD: EarnedTotalObject;
};

export type EarnedTotalObject = {
  __typename?: 'EarnedTotalObject';
  count_records?: Maybe<Scalars['Float']['output']>;
  total_almont_account?: Maybe<Scalars['Float']['output']>;
  total_booking_amount?: Maybe<Scalars['Float']['output']>;
  total_mark_up?: Maybe<Scalars['Float']['output']>;
  total_total_profit?: Maybe<Scalars['Float']['output']>;
  total_user_account?: Maybe<Scalars['Float']['output']>;
};

export type EarnedTotalWithListObject = {
  __typename?: 'EarnedTotalWithListObject';
  group: EarnedGroupObject;
  total: EarnedTotalGroupObject;
};

export type EducationObject = {
  __typename?: 'EducationObject';
  comment?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<FileObject>>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EducationTypeEnum>;
};

export type EducationObjectRowsAndCountType = {
  __typename?: 'EducationObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<EducationObject>;
};

export enum EducationTypeEnum {
  Contract = 'CONTRACT',
  Report = 'REPORT',
  Training = 'TRAINING',
}

export type EmailObject = {
  __typename?: 'EmailObject';
  additional_data_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum FieldsNames {
  DateFrom = 'DATE_FROM',
  DateTo = 'DATE_TO',
  PriceFrom = 'PRICE_FROM',
  PriceTo = 'PRICE_TO',
}

export type FileObject = {
  __typename?: 'FileObject';
  id: Scalars['Int']['output'];
  mimetype: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type FilterEducationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  /** Available variants: [desc,asc]. */
  sort?: InputMaybe<SortTypeEnum>;
  /** Available variants: [TRAINING,CONTRACT,REPORT,0,1,2]. */
  type?: InputMaybe<EducationTypeEnum>;
};

export type ListDocumentObject = {
  __typename?: 'ListDocumentObject';
  comment?: Maybe<Scalars['String']['output']>;
  date_from: Scalars['DateTime']['output'];
  date_to?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  owner: Scalars['String']['output'];
  type: DocumentTypeEnum;
};

export type ListDocumentObjectRowsAndCountType = {
  __typename?: 'ListDocumentObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<ListDocumentObject>;
};

export type MainFiedsObject = {
  comment?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  expiry: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  issued: Scalars['DateTime']['output'];
  owner: Scalars['String']['output'];
  photos?: Maybe<Array<FileObject>>;
  serialNumber: Scalars['String']['output'];
  type: DocumentTypeEnum;
};

export type MembershipFiltersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MembershipObject = {
  __typename?: 'MembershipObject';
  brand: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  exclusiveBenefits: Scalars['String']['output'];
  howToUse: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  participating: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MembershipObjectRowsAndCountType = {
  __typename?: 'MembershipObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<MembershipObject>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeActiveStatusUserNotificationDevice: Scalars['Boolean']['output'];
  createAccount: AccountObject;
  createContact: ContactObject;
  createEarned: EarnedObject;
  createEducation: Scalars['Boolean']['output'];
  createMembership: Scalars['Boolean']['output'];
  createNotification: Scalars['Boolean']['output'];
  createOffer: OfferObject;
  createOwnDocumentObject: Scalars['Boolean']['output'];
  createPaid: PaidObject;
  createPassportDocumentObject: Scalars['Boolean']['output'];
  createUser: Scalars['Boolean']['output'];
  createVisaDocumentObject: Scalars['Boolean']['output'];
  deleteAccount: Scalars['Boolean']['output'];
  deleteContact: Scalars['Boolean']['output'];
  deleteDocument: Scalars['Boolean']['output'];
  deleteEarned: EarnedObject;
  deleteEducation: Scalars['Boolean']['output'];
  deleteMembership: Scalars['Boolean']['output'];
  deleteMyProfile: Scalars['Boolean']['output'];
  deleteOffer: Scalars['Boolean']['output'];
  deletePaid: PaidObject;
  deleteUser: Scalars['Boolean']['output'];
  readNotifications: Scalars['Boolean']['output'];
  recomendPartner: Scalars['Boolean']['output'];
  recomendSuplier: Scalars['Boolean']['output'];
  registerUserNotificationDevice: Scalars['Boolean']['output'];
  removeUserNotificationDevice: Scalars['Boolean']['output'];
  requestChangePasswordToken: Scalars['String']['output'];
  setNewPasword: Scalars['Boolean']['output'];
  signUp: Scalars['Boolean']['output'];
  updateAccount: AccountObject;
  updateContact: Scalars['Boolean']['output'];
  updateEarned: EarnedObject;
  updateEducation: Scalars['Boolean']['output'];
  updateMembership: Scalars['Boolean']['output'];
  updateOffer: Scalars['Boolean']['output'];
  updateOwnDocumentObject: Scalars['Boolean']['output'];
  updatePaid: PaidObject;
  updatePassportDocumentObject: Scalars['Boolean']['output'];
  updateProfileInfo: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  updateVisaDocumentObject: Scalars['Boolean']['output'];
};

export type MutationChangeActiveStatusUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
  userAccounts?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateEarnedArgs = {
  input: CreateEarnedInput;
};

export type MutationCreateEducationArgs = {
  input: CreateEducationInput;
};

export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};

export type MutationCreateNotificationArgs = {
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  notification: CreateNotificationInput;
  options: SendNotificationOptionsInput;
  skipHistory?: Scalars['Boolean']['input'];
};

export type MutationCreateOfferArgs = {
  excludeForUser?: InputMaybe<Array<Scalars['Int']['input']>>;
  input: CreateOfferInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateOwnDocumentObjectArgs = {
  input: CreateOwnDocument;
};

export type MutationCreatePaidArgs = {
  input: CreatePaidInput;
};

export type MutationCreatePassportDocumentObjectArgs = {
  input: CreatePassportDocumentInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVisaDocumentObjectArgs = {
  input: CreateVisaDocumentInput;
};

export type MutationDeleteAccountArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteDocumentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteEarnedArgs = {
  earnedId: Scalars['Int']['input'];
};

export type MutationDeleteEducationArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteMembershipArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteOfferArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeletePaidArgs = {
  earnedId: Scalars['Int']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRecomendPartnerArgs = {
  input: RecomendPartnerInput;
};

export type MutationRecomendSuplierArgs = {
  input: RecomendSupplierInput;
};

export type MutationRegisterUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String']['input'];
};

export type MutationRemoveUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String']['input'];
};

export type MutationRequestChangePasswordTokenArgs = {
  code: Scalars['Float']['input'];
};

export type MutationSetNewPaswordArgs = {
  password: Scalars['String']['input'];
};

export type MutationSignUpArgs = {
  input: SingUpInput;
};

export type MutationUpdateAccountArgs = {
  id: Scalars['Int']['input'];
  input: UpdateAccountInput;
  userAccounts?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactFullInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateEarnedArgs = {
  earnedId: Scalars['Int']['input'];
  input: UpdateEarnedInput;
};

export type MutationUpdateEducationArgs = {
  id: Scalars['Int']['input'];
  input: UpdateEducationInput;
};

export type MutationUpdateMembershipArgs = {
  id: Scalars['Int']['input'];
  input: UpdateMembershipInput;
};

export type MutationUpdateOfferArgs = {
  excludeForUser?: InputMaybe<Array<Scalars['Int']['input']>>;
  input: UpdateOfferFullInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateOwnDocumentObjectArgs = {
  id: Scalars['Int']['input'];
  input: UpdateOwnDocumentInput;
};

export type MutationUpdatePaidArgs = {
  earnedId: Scalars['Int']['input'];
  input: UpdatePaidInput;
};

export type MutationUpdatePassportDocumentObjectArgs = {
  id: Scalars['Int']['input'];
  input: UpdatePassportDocumentInput;
};

export type MutationUpdateProfileInfoArgs = {
  avatarId?: InputMaybe<Scalars['Int']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVisaDocumentObjectArgs = {
  id: Scalars['Int']['input'];
  input: UpdateVisaDocumentInput;
};

export type MyNotificationsFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum NotifationTypeEnum {
  Birthday = 'BIRTHDAY',
  Custom = 'CUSTOM',
  Document = 'DOCUMENT',
}

export type NotificationHistoryObject = {
  __typename?: 'NotificationHistoryObject';
  created_at: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['Int']['output'];
  notificationMessage: Scalars['String']['output'];
  notificationType?: Maybe<NotifationTypeEnum>;
  receiverIds: Array<Scalars['Int']['output']>;
  sender?: Maybe<UserObject>;
  senderId: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  users?: Maybe<Array<UserObject>>;
};

export type NotificationHistoryObjectRowsAndCountType = {
  __typename?: 'NotificationHistoryObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<NotificationHistoryObject>;
};

export type NotificationObject = {
  __typename?: 'NotificationObject';
  created_at: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['Int']['output'];
  isRead: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type?: Maybe<NotifationTypeEnum>;
};

export type NotificationObjectRowsAndCountType = {
  __typename?: 'NotificationObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<NotificationObject>;
};

export type OfferFiltersInput = {
  /** Available variants: [date_from,date_to,price_from,price_to] */
  field?: InputMaybe<FieldsNames>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Available variants: [desc,asc]. */
  sort?: InputMaybe<SortTypeEnum>;
};

export type OfferObject = {
  __typename?: 'OfferObject';
  /** additional Information */
  additional_data?: Maybe<AdditionalDataObject>;
  /** offer city */
  city?: Maybe<Scalars['String']['output']>;
  /** currency iso */
  currency?: Maybe<Scalars['String']['output']>;
  /** offer start date */
  date_from?: Maybe<Scalars['DateTime']['output']>;
  /** offer end date */
  date_to?: Maybe<Scalars['DateTime']['output']>;
  excludeUsers?: Maybe<Array<UsersOffersEntity>>;
  id?: Maybe<Scalars['Int']['output']>;
  /** info about offer */
  information?: Maybe<Scalars['String']['output']>;
  /** url link */
  link?: Maybe<Scalars['String']['output']>;
  /** only for client */
  onlyForClient?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  /** file */
  preview?: Maybe<FileObject>;
  /** offer start price */
  price_from?: Maybe<Scalars['Float']['output']>;
  /** offer end price */
  price_to?: Maybe<Scalars['Float']['output']>;
  /** offer title */
  title?: Maybe<Scalars['String']['output']>;
};

export type OfferObjectRowsAndCountType = {
  __typename?: 'OfferObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<OfferObject>;
};

export type OwnDocumentObject = DocumentObject & {
  __typename?: 'OwnDocumentObject';
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  owner: Scalars['String']['output'];
  photos?: Maybe<Array<FileObject>>;
  title: Scalars['String']['output'];
  type: DocumentTypeEnum;
};

export type OwnDocumentObjectRowsAndCountType = {
  __typename?: 'OwnDocumentObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<OwnDocumentObject>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaidGroupObject = {
  __typename?: 'PaidGroupObject';
  EUR?: Maybe<Array<PaidObject>>;
  GBP?: Maybe<Array<PaidObject>>;
  USD?: Maybe<Array<PaidObject>>;
};

export type PaidObject = {
  __typename?: 'PaidObject';
  amount: Scalars['Float']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  currency: CurrencyEnum;
  current_profit: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  invoice: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id: Scalars['Float']['output'];
};

export type PaidObjectRowsAndCountType = {
  __typename?: 'PaidObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<PaidObject>;
};

export type PaidTotalObject = {
  __typename?: 'PaidTotalObject';
  count_records?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

export type PaidTotalWithListObject = {
  __typename?: 'PaidTotalWithListObject';
  group: PaidGroupObject;
  total: TotalGroupObject;
};

export type PassportDocumentObject = DocumentObject &
  MainFiedsObject & {
    __typename?: 'PassportDocumentObject';
    birthDate: Scalars['DateTime']['output'];
    comment?: Maybe<Scalars['String']['output']>;
    country: Scalars['String']['output'];
    expiry: Scalars['DateTime']['output'];
    id: Scalars['Int']['output'];
    issued: Scalars['DateTime']['output'];
    owner: Scalars['String']['output'];
    photos?: Maybe<Array<FileObject>>;
    serialNumber: Scalars['String']['output'];
    type: DocumentTypeEnum;
  };

export type PassportDocumentObjectRowsAndCountType = {
  __typename?: 'PassportDocumentObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<PassportDocumentObject>;
};

export type PhoneObject = {
  __typename?: 'PhoneObject';
  additional_data_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  currentNotificationDeviceByoneSignalDeviceId: UserNotificationDeviceObject;
  currentUser: UserObject;
  getAccountById: AccountObject;
  getAccounts: AccountObjectRowsAndCountType;
  getContactById: ContactObject;
  getContacts: ContactObjectRowsAndCountType;
  getEarnedList: EarnedTotalWithListObject;
  getEarnedListForManager: EarnedObjectRowsAndCountType;
  getEarnedPaidDataByUser: EarnedPaidDiffByCurrencyObject;
  getEducationById: EducationObject;
  getEducations: EducationObjectRowsAndCountType;
  getListOwnDocumentObject: OwnDocumentObjectRowsAndCountType;
  getListPassportDocumentObject: PassportDocumentObjectRowsAndCountType;
  getListVisaDocumentObject: VisaDocumentObjectRowsAndCountType;
  getManyListDocumentObject: ListDocumentObjectRowsAndCountType;
  getMembershipById: MembershipObject;
  getMemberships: MembershipObjectRowsAndCountType;
  getMyNotifications: NotificationObjectRowsAndCountType;
  getNotificationHistories: NotificationHistoryObjectRowsAndCountType;
  getOfferById: OfferObject;
  getOffers: OfferObjectRowsAndCountType;
  getOneDocumentById: DocumentUnion;
  getOneOwnDocumentObjectById: OwnDocumentObject;
  getOnePassportDocumentObjectById: PassportDocumentObject;
  getOneVisaDocumentObjectById: VisaDocumentObject;
  getPaidList: PaidTotalWithListObject;
  getPaidListForManager: PaidObjectRowsAndCountType;
  getSections: Array<SectionObject>;
  getSuccessManagerNames: Array<UserShorInfoObject>;
  getUserById: UserObject;
  getUsers: UserObjectRowsAndCountType;
  myBalance: EarnedPaidDiffByCurrencyObject;
  sentForgotPasswordResetKey: Scalars['Boolean']['output'];
  signIn: Scalars['String']['output'];
  signInMobile: Scalars['String']['output'];
  updateUserPassword: Scalars['Boolean']['output'];
};

export type QueryCurrentNotificationDeviceByoneSignalDeviceIdArgs = {
  oneSignalDeviceId: Scalars['String']['input'];
};

export type QueryGetAccountByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAccountsArgs = {
  input: PaginationInput;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetContactByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetContactsArgs = {
  input: ContactFiltersInput;
};

export type QueryGetEarnedListArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetEarnedListForManagerArgs = {
  input: PaginationInput;
};

export type QueryGetEarnedPaidDataByUserArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetEducationByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetEducationsArgs = {
  input: FilterEducationInput;
};

export type QueryGetListOwnDocumentObjectArgs = {
  input?: InputMaybe<DocumentOptionsInput>;
};

export type QueryGetListPassportDocumentObjectArgs = {
  input?: InputMaybe<DocumentOptionsInput>;
};

export type QueryGetListVisaDocumentObjectArgs = {
  input?: InputMaybe<DocumentOptionsInput>;
};

export type QueryGetManyListDocumentObjectArgs = {
  input: DocumentFiltersInput;
};

export type QueryGetMembershipByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMembershipsArgs = {
  input: MembershipFiltersInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMyNotificationsArgs = {
  input?: InputMaybe<MyNotificationsFilterInput>;
};

export type QueryGetNotificationHistoriesArgs = {
  input: PaginationInput;
};

export type QueryGetOfferByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOffersArgs = {
  input: OfferFiltersInput;
};

export type QueryGetOneDocumentByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOneOwnDocumentObjectByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOnePassportDocumentObjectByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOneVisaDocumentObjectByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPaidListArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetPaidListForManagerArgs = {
  input: PaginationInput;
};

export type QueryGetUserByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUsersArgs = {
  input: UserFiltersInput;
};

export type QuerySentForgotPasswordResetKeyArgs = {
  email: Scalars['String']['input'];
};

export type QuerySignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QuerySignInMobileArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QueryUpdateUserPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type RecomendPartnerInput = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  partner_full_name: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};

export type RecomendSupplierInput = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SectionObject = {
  __typename?: 'SectionObject';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type SendNotificationOptionsInput = {
  allUsers?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SingUpInput = {
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum SortTypeEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TotalGroupObject = {
  __typename?: 'TotalGroupObject';
  EUR: PaidTotalObject;
  GBP: PaidTotalObject;
  USD: PaidTotalObject;
};

export type UpdateAccountInput = {
  /** account's comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** account's link */
  link?: InputMaybe<Scalars['String']['input']>;
  /** account's login */
  login?: InputMaybe<Scalars['String']['input']>;
  /** account's password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** account's title */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdditionalDataInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  whats_app?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactFullInput = {
  additional_data?: InputMaybe<UpdateAdditionalDataInput>;
  additional_data_id?: InputMaybe<Scalars['Int']['input']>;
  contact_data?: InputMaybe<UpdateContactInput>;
  contact_id: Scalars['Int']['input'];
  emails?: InputMaybe<Array<UpdateEmailInput>>;
  phones?: InputMaybe<Array<UpdatePhoneInput>>;
};

export type UpdateContactInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  /** only for type other */
  commission?: InputMaybe<Scalars['String']['input']>;
  /** only for type cruises, other */
  company?: InputMaybe<Scalars['String']['input']>;
  /** only for type cruises */
  profit?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContactTypesEnum>;
};

export type UpdateEarnedInput = {
  almont_account?: InputMaybe<Scalars['Float']['input']>;
  booking_amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  current_profit?: InputMaybe<Scalars['Float']['input']>;
  date_to_travel?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<Scalars['Int']['input']>;
  mark_up?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  total_profit?: InputMaybe<Scalars['Float']['input']>;
  user_account?: InputMaybe<Scalars['Float']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEducationInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EducationTypeEnum>;
};

export type UpdateEmailInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMembershipInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  exclusiveBenefits?: InputMaybe<Scalars['String']['input']>;
  howToUse?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  participating?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOfferFullInput = {
  additional_data?: InputMaybe<UpdateAdditionalDataInput>;
  additional_data_id?: InputMaybe<Scalars['Int']['input']>;
  emails?: InputMaybe<Array<UpdateEmailInput>>;
  offer_data?: InputMaybe<UpdateOfferInput>;
  offer_id: Scalars['Int']['input'];
  phones?: InputMaybe<Array<UpdatePhoneInput>>;
};

export type UpdateOfferInput = {
  /** offer's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** currency iso */
  currency?: InputMaybe<Scalars['String']['input']>;
  date_from: Scalars['DateTime']['input'];
  date_to: Scalars['DateTime']['input'];
  /** information */
  information?: InputMaybe<Scalars['String']['input']>;
  /** link */
  link?: InputMaybe<Scalars['String']['input']>;
  /** only for client */
  onlyForClient?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  /** Id of file */
  preview_file_id?: InputMaybe<Scalars['Int']['input']>;
  /** offer min price */
  price_from?: InputMaybe<Scalars['Float']['input']>;
  /** offer max price */
  price_to?: InputMaybe<Scalars['Float']['input']>;
  /** offer's title */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOwnDocumentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaidInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePassportDocumentInput = {
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['DateTime']['input']>;
  issued?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhoneInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  is_success_manager?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  section_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  success_manager_id?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['Int']['input'];
};

export type UpdateVisaDocumentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['DateTime']['input']>;
  issued?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  photosIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserAccountObject = {
  __typename?: 'UserAccountObject';
  is_own?: Maybe<Scalars['Boolean']['output']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  avatar?: Maybe<FileObject>;
  /** user's birthday */
  birthday: Scalars['DateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  /** user's email */
  email: Scalars['String']['output'];
  /** user's full name */
  full_name: Scalars['String']['output'];
  /** entity's id */
  id: Scalars['Int']['output'];
  is_success_manager?: Maybe<Scalars['Boolean']['output']>;
  managers?: Maybe<Array<UserEntity>>;
  /** user's phone number */
  phone_number: Scalars['String']['output'];
  /** user profit */
  profit: Scalars['Float']['output'];
  /** user's role */
  role: Scalars['Int']['output'];
};

export type UserFiltersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Available variants: [desc,asc]. */
  sort?: InputMaybe<SortTypeEnum>;
  /** Available variants: [full_name,role]. */
  sortBy?: InputMaybe<UserSortVariantsEnum>;
};

export type UserNotificationDeviceObject = {
  __typename?: 'UserNotificationDeviceObject';
  isActive: Scalars['String']['output'];
  oneSignalDeviceId: Scalars['String']['output'];
};

export type UserObject = {
  __typename?: 'UserObject';
  /** file */
  avatar?: Maybe<FileObject>;
  birthday?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  excludeOffers?: Maybe<Array<UsersOffersObject>>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_success_manager?: Maybe<Scalars['Boolean']['output']>;
  managers?: Maybe<Array<UserObject>>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  role: UserRoleEnum;
  sections?: Maybe<Array<SectionObject>>;
};

export type UserObjectRowsAndCountType = {
  __typename?: 'UserObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<UserObject>;
};

export enum UserRoleEnum {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Manager = 'MANAGER',
}

export type UserShorInfoObject = {
  __typename?: 'UserShorInfoObject';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum UserSortVariantsEnum {
  FullName = 'full_name',
  Role = 'role',
}

export type UsersOffersEntity = {
  __typename?: 'UsersOffersEntity';
  offerId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type UsersOffersObject = {
  __typename?: 'UsersOffersObject';
  offerId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type VisaDocumentObject = DocumentObject &
  MainFiedsObject & {
    __typename?: 'VisaDocumentObject';
    comment?: Maybe<Scalars['String']['output']>;
    country: Scalars['String']['output'];
    expiry: Scalars['DateTime']['output'];
    id: Scalars['Int']['output'];
    issued: Scalars['DateTime']['output'];
    owner: Scalars['String']['output'];
    photos?: Maybe<Array<FileObject>>;
    serialNumber: Scalars['String']['output'];
    type: DocumentTypeEnum;
  };

export type VisaDocumentObjectRowsAndCountType = {
  __typename?: 'VisaDocumentObjectRowsAndCountType';
  count: Scalars['Int']['output'];
  rows: Array<VisaDocumentObject>;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
  userAccounts: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createAccount: { __typename?: 'AccountObject'; id?: number | null };
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: { __typename?: 'ContactObject'; id?: number | null };
};

export type CreateEarnedMutationVariables = Exact<{
  input: CreateEarnedInput;
}>;

export type CreateEarnedMutation = {
  __typename?: 'Mutation';
  createEarned: {
    __typename?: 'EarnedObject';
    almont_account: number;
    booking_amount: number;
    currency: CurrencyEnum;
    current_profit: number;
    date_to_travel: any;
    description: string;
    id?: number | null;
    invoice: number;
    mark_up: number;
    title: string;
    total_profit: number;
    user_account: number;
    user_id: number;
  };
};

export type CreateEducationMutationVariables = Exact<{
  input: CreateEducationInput;
}>;

export type CreateEducationMutation = { __typename?: 'Mutation'; createEducation: boolean };

export type CreateMembershipMutationVariables = Exact<{
  input: CreateMembershipInput;
}>;

export type CreateMembershipMutation = { __typename?: 'Mutation'; createMembership: boolean };

export type CreateNotificationMutationVariables = Exact<{
  notification: CreateNotificationInput;
  options: SendNotificationOptionsInput;
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  skipHistory: Scalars['Boolean']['input'];
}>;

export type CreateNotificationMutation = { __typename?: 'Mutation'; createNotification: boolean };

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
  excludeForUser?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type CreateOfferMutation = {
  __typename?: 'Mutation';
  createOffer: { __typename?: 'OfferObject'; id?: number | null };
};

export type CreatePaidMutationVariables = Exact<{
  input: CreatePaidInput;
}>;

export type CreatePaidMutation = {
  __typename?: 'Mutation';
  createPaid: {
    __typename?: 'PaidObject';
    amount: number;
    currency: CurrencyEnum;
    current_profit: number;
    description: string;
    id?: number | null;
    invoice: number;
    note?: string | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: 'Mutation'; createUser: boolean };

export type DeleteAccountMutationVariables = Exact<{
  deleteAccountId: Scalars['Int']['input'];
}>;

export type DeleteAccountMutation = { __typename?: 'Mutation'; deleteAccount: boolean };

export type DeleteContactMutationVariables = Exact<{
  deleteContactId: Scalars['Int']['input'];
}>;

export type DeleteContactMutation = { __typename?: 'Mutation'; deleteContact: boolean };

export type DeleteEarnedMutationVariables = Exact<{
  earnedId: Scalars['Int']['input'];
}>;

export type DeleteEarnedMutation = {
  __typename?: 'Mutation';
  deleteEarned: { __typename?: 'EarnedObject'; id?: number | null };
};

export type DeleteEducationMutationVariables = Exact<{
  deleteEducationId: Scalars['Int']['input'];
}>;

export type DeleteEducationMutation = { __typename?: 'Mutation'; deleteEducation: boolean };

export type DeleteMembershipMutationVariables = Exact<{
  deleteMembershipId: Scalars['Int']['input'];
}>;

export type DeleteMembershipMutation = { __typename?: 'Mutation'; deleteMembership: boolean };

export type DeleteOfferMutationVariables = Exact<{
  deleteOfferId: Scalars['Int']['input'];
}>;

export type DeleteOfferMutation = { __typename?: 'Mutation'; deleteOffer: boolean };

export type DeletePaidMutationVariables = Exact<{
  earnedId: Scalars['Int']['input'];
}>;

export type DeletePaidMutation = {
  __typename?: 'Mutation';
  deletePaid: { __typename?: 'PaidObject'; id?: number | null };
};

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['Int']['input'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: boolean };

export type SetNewPaswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;

export type SetNewPaswordMutation = { __typename?: 'Mutation'; setNewPasword: boolean };

export type UpdateAccountMutationVariables = Exact<{
  updateAccountId: Scalars['Int']['input'];
  input: UpdateAccountInput;
  userAccounts: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: { __typename?: 'AccountObject'; id?: number | null };
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactFullInput;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateContactMutation = { __typename?: 'Mutation'; updateContact: boolean };

export type UpdateEarnedMutationVariables = Exact<{
  input: UpdateEarnedInput;
  earnedId: Scalars['Int']['input'];
}>;

export type UpdateEarnedMutation = {
  __typename?: 'Mutation';
  updateEarned: {
    __typename?: 'EarnedObject';
    almont_account: number;
    booking_amount: number;
    currency: CurrencyEnum;
    current_profit: number;
    date_to_travel: any;
    description: string;
    id?: number | null;
    invoice: number;
    mark_up: number;
    title: string;
    total_profit: number;
    user_account: number;
    user_id: number;
  };
};

export type UpdateEducationMutationVariables = Exact<{
  updateEducationId: Scalars['Int']['input'];
  input: UpdateEducationInput;
}>;

export type UpdateEducationMutation = { __typename?: 'Mutation'; updateEducation: boolean };

export type UpdateMembershipMutationVariables = Exact<{
  updateMembershipId: Scalars['Int']['input'];
  input: UpdateMembershipInput;
}>;

export type UpdateMembershipMutation = { __typename?: 'Mutation'; updateMembership: boolean };

export type UpdateOfferMutationVariables = Exact<{
  input: UpdateOfferFullInput;
  send_notification?: InputMaybe<Scalars['Boolean']['input']>;
  excludeForUser?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type UpdateOfferMutation = { __typename?: 'Mutation'; updateOffer: boolean };

export type UpdatePaidMutationVariables = Exact<{
  input: UpdatePaidInput;
  earnedId: Scalars['Int']['input'];
}>;

export type UpdatePaidMutation = {
  __typename?: 'Mutation';
  updatePaid: {
    __typename?: 'PaidObject';
    amount: number;
    currency: CurrencyEnum;
    current_profit: number;
    description: string;
    id?: number | null;
    invoice: number;
    note?: string | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation'; updateUser: boolean };

export type RequestChangePasswordTokenMutationVariables = Exact<{
  code: Scalars['Float']['input'];
}>;

export type RequestChangePasswordTokenMutation = {
  __typename?: 'Mutation';
  requestChangePasswordToken: string;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: { __typename?: 'UserObject'; id?: number | null; full_name?: string | null };
};

export type SentForgotPasswordResetKeyQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SentForgotPasswordResetKeyQuery = {
  __typename?: 'Query';
  sentForgotPasswordResetKey: boolean;
};

export type GetAccountByIdQueryVariables = Exact<{
  getAccountByIdId: Scalars['Int']['input'];
}>;

export type GetAccountByIdQuery = {
  __typename?: 'Query';
  getAccountById: {
    __typename?: 'AccountObject';
    comment?: string | null;
    id?: number | null;
    link?: string | null;
    login?: string | null;
    password?: string | null;
    title?: string | null;
    users?: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
      avatar?: {
        __typename?: 'FileObject';
        id: number;
        mimetype: string;
        path: string;
        size: number;
      } | null;
    }> | null;
  };
};

export type GetAccountsQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetAccountsQuery = {
  __typename?: 'Query';
  getAccounts: {
    __typename?: 'AccountObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'AccountObject';
      id?: number | null;
      link?: string | null;
      login?: string | null;
      password?: string | null;
      title?: string | null;
      comment?: string | null;
    }>;
  };
};

export type GetContactByIdQueryVariables = Exact<{
  getContactByIdId: Scalars['Int']['input'];
}>;

export type GetContactByIdQuery = {
  __typename?: 'Query';
  getContactById: {
    __typename?: 'ContactObject';
    id?: number | null;
    city?: string | null;
    title?: string | null;
    company?: string | null;
    profit?: number | null;
    commission?: string | null;
    additional_data?: {
      __typename?: 'AdditionalDataObject';
      address?: string | null;
      comment?: string | null;
      contact_person?: string | null;
      country?: string | null;
      id?: number | null;
      whats_app?: string | null;
      emails?: Array<{
        __typename?: 'EmailObject';
        id?: number | null;
        email?: string | null;
      }> | null;
      phones?: Array<{
        __typename?: 'PhoneObject';
        id?: number | null;
        phone?: string | null;
      }> | null;
    } | null;
  };
};

export type GetContactsQueryVariables = Exact<{
  input: ContactFiltersInput;
}>;

export type GetContactsQuery = {
  __typename?: 'Query';
  getContacts: {
    __typename?: 'ContactObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'ContactObject';
      id?: number | null;
      type?: ContactTypesEnum | null;
      title?: string | null;
      city?: string | null;
      company?: string | null;
      commission?: string | null;
      additional_data?: {
        __typename?: 'AdditionalDataObject';
        address?: string | null;
        country?: string | null;
        contact_person?: string | null;
        emails?: Array<{
          __typename?: 'EmailObject';
          additional_data_id?: number | null;
          created_at?: any | null;
          email?: string | null;
          id?: number | null;
          updated_at?: any | null;
        }> | null;
        phones?: Array<{
          __typename?: 'PhoneObject';
          additional_data_id?: number | null;
          created_at?: any | null;
          phone?: string | null;
          id?: number | null;
          updated_at?: any | null;
        }> | null;
      } | null;
    }>;
  };
};

export type GetOneDocumentByIdQueryVariables = Exact<{
  getOneDocumentByIdId: Scalars['Int']['input'];
}>;

export type GetOneDocumentByIdQuery = {
  __typename?: 'Query';
  getOneDocumentById:
    | {
        __typename?: 'OwnDocumentObject';
        comment?: string | null;
        date: any;
        id: number;
        owner: string;
        title: string;
        type: DocumentTypeEnum;
        photos?: Array<{
          __typename?: 'FileObject';
          id: number;
          mimetype: string;
          path: string;
          size: number;
        }> | null;
      }
    | {
        __typename?: 'PassportDocumentObject';
        birthDate: any;
        comment?: string | null;
        country: string;
        expiry: any;
        id: number;
        issued: any;
        owner: string;
        serialNumber: string;
        type: DocumentTypeEnum;
        photos?: Array<{
          __typename?: 'FileObject';
          id: number;
          mimetype: string;
          path: string;
          size: number;
        }> | null;
      }
    | {
        __typename?: 'VisaDocumentObject';
        comment?: string | null;
        country: string;
        expiry: any;
        id: number;
        issued: any;
        owner: string;
        serialNumber: string;
        type: DocumentTypeEnum;
        photos?: Array<{
          __typename?: 'FileObject';
          id: number;
          mimetype: string;
          path: string;
          size: number;
        }> | null;
      };
};

export type GetEarnedListQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetEarnedListQuery = {
  __typename?: 'Query';
  getEarnedList: {
    __typename?: 'EarnedTotalWithListObject';
    group: {
      __typename?: 'EarnedGroupObject';
      EUR?: Array<{
        __typename?: 'EarnedObject';
        almont_account: number;
        booking_amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        date_to_travel: any;
        description: string;
        id?: number | null;
        invoice: number;
        mark_up: number;
        title: string;
        total_profit: number;
        user_account: number;
        user_id: number;
      }> | null;
      GBP?: Array<{
        __typename?: 'EarnedObject';
        almont_account: number;
        booking_amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        date_to_travel: any;
        description: string;
        id?: number | null;
        invoice: number;
        mark_up: number;
        title: string;
        total_profit: number;
        user_account: number;
        user_id: number;
      }> | null;
      USD?: Array<{
        __typename?: 'EarnedObject';
        almont_account: number;
        booking_amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        date_to_travel: any;
        description: string;
        id?: number | null;
        invoice: number;
        mark_up: number;
        title: string;
        total_profit: number;
        user_account: number;
        user_id: number;
      }> | null;
    };
    total: {
      __typename?: 'EarnedTotalGroupObject';
      EUR: {
        __typename?: 'EarnedTotalObject';
        count_records?: number | null;
        total_almont_account?: number | null;
        total_booking_amount?: number | null;
        total_mark_up?: number | null;
        total_total_profit?: number | null;
        total_user_account?: number | null;
      };
      GBP: {
        __typename?: 'EarnedTotalObject';
        count_records?: number | null;
        total_almont_account?: number | null;
        total_booking_amount?: number | null;
        total_mark_up?: number | null;
        total_total_profit?: number | null;
        total_user_account?: number | null;
      };
      USD: {
        __typename?: 'EarnedTotalObject';
        count_records?: number | null;
        total_almont_account?: number | null;
        total_booking_amount?: number | null;
        total_mark_up?: number | null;
        total_total_profit?: number | null;
        total_user_account?: number | null;
      };
    };
  };
};

export type GetEarnedPaidDataByUserQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetEarnedPaidDataByUserQuery = {
  __typename?: 'Query';
  getEarnedPaidDataByUser: {
    __typename?: 'EarnedPaidDiffByCurrencyObject';
    EUR: number;
    GBP: number;
    USD: number;
  };
};

export type GetEducationByIdQueryVariables = Exact<{
  getEducationByIdId: Scalars['Int']['input'];
}>;

export type GetEducationByIdQuery = {
  __typename?: 'Query';
  getEducationById: {
    __typename?: 'EducationObject';
    id?: number | null;
    title?: string | null;
    comment?: string | null;
    type?: EducationTypeEnum | null;
    files?: Array<{
      __typename?: 'FileObject';
      path: string;
      mimetype: string;
      size: number;
      id: number;
    }> | null;
  };
};

export type GetEducationsQueryVariables = Exact<{
  input: FilterEducationInput;
}>;

export type GetEducationsQuery = {
  __typename?: 'Query';
  getEducations: {
    __typename?: 'EducationObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'EducationObject';
      title?: string | null;
      id?: number | null;
      comment?: string | null;
      type?: EducationTypeEnum | null;
      files?: Array<{
        __typename?: 'FileObject';
        id: number;
        mimetype: string;
        path: string;
        size: number;
      }> | null;
    }>;
  };
};

export type GetManyListDocumentObjectQueryVariables = Exact<{
  input: DocumentFiltersInput;
}>;

export type GetManyListDocumentObjectQuery = {
  __typename?: 'Query';
  getManyListDocumentObject: {
    __typename?: 'ListDocumentObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'ListDocumentObject';
      comment?: string | null;
      date_from: any;
      date_to?: any | null;
      id: number;
      owner: string;
      type: DocumentTypeEnum;
    }>;
  };
};

export type GetMembershipByIdQueryVariables = Exact<{
  getMembershipByIdId: Scalars['Int']['input'];
}>;

export type GetMembershipByIdQuery = {
  __typename?: 'Query';
  getMembershipById: {
    __typename?: 'MembershipObject';
    brand: string;
    comment?: string | null;
    exclusiveBenefits: string;
    howToUse: string;
    id: number;
    link: string;
    participating: string;
    title: string;
  };
};

export type GetMembershipsQueryVariables = Exact<{
  input: MembershipFiltersInput;
}>;

export type GetMembershipsQuery = {
  __typename?: 'Query';
  getMemberships: {
    __typename?: 'MembershipObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'MembershipObject';
      brand: string;
      exclusiveBenefits: string;
      id: number;
      link: string;
      participating: string;
      title: string;
    }>;
  };
};

export type GetNotificationHistoriesQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetNotificationHistoriesQuery = {
  __typename?: 'Query';
  getNotificationHistories: {
    __typename?: 'NotificationHistoryObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'NotificationHistoryObject';
      created_at: any;
      data?: any | null;
      id: number;
      notificationMessage: string;
      receiverIds: Array<number>;
      users?: Array<{ __typename?: 'UserObject'; full_name?: string | null }> | null;
      sender?: { __typename?: 'UserObject'; full_name?: string | null } | null;
    }>;
  };
};

export type GetOfferByIdQueryVariables = Exact<{
  getOfferByIdId: Scalars['Int']['input'];
}>;

export type GetOfferByIdQuery = {
  __typename?: 'Query';
  getOfferById: {
    __typename?: 'OfferObject';
    city?: string | null;
    order?: number | null;
    currency?: string | null;
    date_from?: any | null;
    date_to?: any | null;
    id?: number | null;
    information?: string | null;
    link?: string | null;
    price_from?: number | null;
    price_to?: number | null;
    title?: string | null;
    onlyForClient?: boolean | null;
    preview?: { __typename?: 'FileObject'; path: string } | null;
    additional_data?: {
      __typename?: 'AdditionalDataObject';
      address?: string | null;
      comment?: string | null;
      contact_person?: string | null;
      country?: string | null;
      id?: number | null;
      whats_app?: string | null;
      emails?: Array<{
        __typename?: 'EmailObject';
        email?: string | null;
        id?: number | null;
      }> | null;
      phones?: Array<{
        __typename?: 'PhoneObject';
        id?: number | null;
        phone?: string | null;
      }> | null;
    } | null;
    excludeUsers?: Array<{
      __typename?: 'UsersOffersEntity';
      offerId?: number | null;
      userId?: number | null;
    }> | null;
  };
};

export type GetOffersQueryVariables = Exact<{
  input: OfferFiltersInput;
}>;

export type GetOffersQuery = {
  __typename?: 'Query';
  getOffers: {
    __typename?: 'OfferObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'OfferObject';
      title?: string | null;
      id?: number | null;
      date_from?: any | null;
      date_to?: any | null;
      price_from?: number | null;
      price_to?: number | null;
      city?: string | null;
      currency?: string | null;
      information?: string | null;
      link?: string | null;
      additional_data?: {
        __typename?: 'AdditionalDataObject';
        country?: string | null;
        id?: number | null;
        comment?: string | null;
        contact_person?: string | null;
        created_at?: any | null;
        updated_at?: any | null;
        whats_app?: string | null;
        emails?: Array<{
          __typename?: 'EmailObject';
          additional_data_id?: number | null;
          created_at?: any | null;
          email?: string | null;
          id?: number | null;
          updated_at?: any | null;
        }> | null;
        phones?: Array<{
          __typename?: 'PhoneObject';
          additional_data_id?: number | null;
          created_at?: any | null;
          phone?: string | null;
          id?: number | null;
          updated_at?: any | null;
        }> | null;
      } | null;
    }>;
  };
};

export type GetPaidListQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetPaidListQuery = {
  __typename?: 'Query';
  getPaidList: {
    __typename?: 'PaidTotalWithListObject';
    group: {
      __typename?: 'PaidGroupObject';
      EUR?: Array<{
        __typename?: 'PaidObject';
        amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        description: string;
        id?: number | null;
        invoice: number;
        note?: string | null;
        user_id: number;
      }> | null;
      GBP?: Array<{
        __typename?: 'PaidObject';
        amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        description: string;
        id?: number | null;
        invoice: number;
        note?: string | null;
        user_id: number;
      }> | null;
      USD?: Array<{
        __typename?: 'PaidObject';
        amount: number;
        currency: CurrencyEnum;
        current_profit: number;
        description: string;
        id?: number | null;
        invoice: number;
        note?: string | null;
        user_id: number;
      }> | null;
    };
    total: {
      __typename?: 'TotalGroupObject';
      EUR: {
        __typename?: 'PaidTotalObject';
        count_records?: number | null;
        total_amount?: number | null;
      };
      GBP: {
        __typename?: 'PaidTotalObject';
        count_records?: number | null;
        total_amount?: number | null;
      };
      USD: {
        __typename?: 'PaidTotalObject';
        count_records?: number | null;
        total_amount?: number | null;
      };
    };
  };
};

export type GetSectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSectionsQuery = {
  __typename?: 'Query';
  getSections: Array<{ __typename?: 'SectionObject'; id?: number | null; name?: string | null }>;
};

export type GetSuccessManagerNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSuccessManagerNamesQuery = {
  __typename?: 'Query';
  getSuccessManagerNames: Array<{
    __typename?: 'UserShorInfoObject';
    id?: number | null;
    name?: string | null;
  }>;
};

export type GetUserByIdQueryVariables = Exact<{
  getUserByIdId: Scalars['Int']['input'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  getUserById: {
    __typename?: 'UserObject';
    birthday?: any | null;
    email?: string | null;
    full_name?: string | null;
    id?: number | null;
    phone_number?: string | null;
    profit?: number | null;
    is_success_manager?: boolean | null;
    comment?: string | null;
    avatar?: {
      __typename?: 'FileObject';
      id: number;
      path: string;
      mimetype: string;
      size: number;
    } | null;
    managers?: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
    }> | null;
    sections?: Array<{
      __typename?: 'SectionObject';
      id?: number | null;
      name?: string | null;
    }> | null;
  };
};

export type GetUsersQueryVariables = Exact<{
  input: UserFiltersInput;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UserObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
      email?: string | null;
      phone_number?: string | null;
      role: UserRoleEnum;
      avatar?: {
        __typename?: 'FileObject';
        id: number;
        path: string;
        mimetype: string;
        size: number;
      } | null;
    }>;
  };
};

export type GetUsersForAccountsQueryVariables = Exact<{
  input: UserFiltersInput;
}>;

export type GetUsersForAccountsQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UserObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
      role: UserRoleEnum;
      avatar?: {
        __typename?: 'FileObject';
        id: number;
        path: string;
        mimetype: string;
        size: number;
      } | null;
    }>;
  };
};

export type GetUsersForNotificationsQueryVariables = Exact<{
  input: UserFiltersInput;
}>;

export type GetUsersForNotificationsQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UserObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
      role: UserRoleEnum;
      avatar?: {
        __typename?: 'FileObject';
        id: number;
        path: string;
        mimetype: string;
        size: number;
      } | null;
    }>;
  };
};

export type GetUsersForOfferQueryVariables = Exact<{
  input: UserFiltersInput;
}>;

export type GetUsersForOfferQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UserObjectRowsAndCountType';
    count: number;
    rows: Array<{
      __typename?: 'UserObject';
      id?: number | null;
      full_name?: string | null;
      role: UserRoleEnum;
      avatar?: {
        __typename?: 'FileObject';
        id: number;
        path: string;
        mimetype: string;
        size: number;
      } | null;
    }>;
  };
};

export type SignInQueryVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type SignInQuery = { __typename?: 'Query'; signIn: string };

export const CreateAccountDocument = gql`
  mutation createAccount($input: CreateAccountInput!, $userAccounts: [Int!]!) {
    createAccount(input: $input, userAccounts: $userAccounts) {
      id
    }
  }
`;
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userAccounts: // value for 'userAccounts'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(
    CreateAccountDocument,
    options,
  );
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($input: CreateContactInput!, $sendNotification: Boolean) {
    createContact(input: $input, send_notification: $sendNotification) {
      id
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendNotification: // value for 'sendNotification'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(
    CreateContactDocument,
    options,
  );
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateEarnedDocument = gql`
  mutation createEarned($input: CreateEarnedInput!) {
    createEarned(input: $input) {
      almont_account
      booking_amount
      currency
      current_profit
      date_to_travel
      description
      id
      invoice
      mark_up
      title
      total_profit
      user_account
      user_id
    }
  }
`;
export type CreateEarnedMutationFn = Apollo.MutationFunction<
  CreateEarnedMutation,
  CreateEarnedMutationVariables
>;

/**
 * __useCreateEarnedMutation__
 *
 * To run a mutation, you first call `useCreateEarnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEarnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEarnedMutation, { data, loading, error }] = useCreateEarnedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEarnedMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEarnedMutation, CreateEarnedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEarnedMutation, CreateEarnedMutationVariables>(
    CreateEarnedDocument,
    options,
  );
}
export type CreateEarnedMutationHookResult = ReturnType<typeof useCreateEarnedMutation>;
export type CreateEarnedMutationResult = Apollo.MutationResult<CreateEarnedMutation>;
export type CreateEarnedMutationOptions = Apollo.BaseMutationOptions<
  CreateEarnedMutation,
  CreateEarnedMutationVariables
>;
export const CreateEducationDocument = gql`
  mutation createEducation($input: CreateEducationInput!) {
    createEducation(input: $input)
  }
`;
export type CreateEducationMutationFn = Apollo.MutationFunction<
  CreateEducationMutation,
  CreateEducationMutationVariables
>;

/**
 * __useCreateEducationMutation__
 *
 * To run a mutation, you first call `useCreateEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationMutation, { data, loading, error }] = useCreateEducationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEducationMutation,
    CreateEducationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEducationMutation, CreateEducationMutationVariables>(
    CreateEducationDocument,
    options,
  );
}
export type CreateEducationMutationHookResult = ReturnType<typeof useCreateEducationMutation>;
export type CreateEducationMutationResult = Apollo.MutationResult<CreateEducationMutation>;
export type CreateEducationMutationOptions = Apollo.BaseMutationOptions<
  CreateEducationMutation,
  CreateEducationMutationVariables
>;
export const CreateMembershipDocument = gql`
  mutation createMembership($input: CreateMembershipInput!) {
    createMembership(input: $input)
  }
`;
export type CreateMembershipMutationFn = Apollo.MutationFunction<
  CreateMembershipMutation,
  CreateMembershipMutationVariables
>;

/**
 * __useCreateMembershipMutation__
 *
 * To run a mutation, you first call `useCreateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipMutation, { data, loading, error }] = useCreateMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMembershipMutation,
    CreateMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMembershipMutation, CreateMembershipMutationVariables>(
    CreateMembershipDocument,
    options,
  );
}
export type CreateMembershipMutationHookResult = ReturnType<typeof useCreateMembershipMutation>;
export type CreateMembershipMutationResult = Apollo.MutationResult<CreateMembershipMutation>;
export type CreateMembershipMutationOptions = Apollo.BaseMutationOptions<
  CreateMembershipMutation,
  CreateMembershipMutationVariables
>;
export const CreateNotificationDocument = gql`
  mutation createNotification(
    $notification: CreateNotificationInput!
    $options: SendNotificationOptionsInput!
    $data: JSONObject
    $skipHistory: Boolean!
  ) {
    createNotification(
      notification: $notification
      options: $options
      data: $data
      skipHistory: $skipHistory
    )
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      options: // value for 'options'
 *      data: // value for 'data'
 *      skipHistory: // value for 'skipHistory'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(
    CreateNotificationDocument,
    options,
  );
}
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const CreateOfferDocument = gql`
  mutation createOffer(
    $input: CreateOfferInput!
    $send_notification: Boolean
    $excludeForUser: [Int!]
  ) {
    createOffer(
      input: $input
      send_notification: $send_notification
      excludeForUser: $excludeForUser
    ) {
      id
    }
  }
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      send_notification: // value for 'send_notification'
 *      excludeForUser: // value for 'excludeForUser'
 *   },
 * });
 */
export function useCreateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(
    CreateOfferDocument,
    options,
  );
}
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;
export const CreatePaidDocument = gql`
  mutation createPaid($input: CreatePaidInput!) {
    createPaid(input: $input) {
      amount
      currency
      current_profit
      description
      id
      invoice
      note
    }
  }
`;
export type CreatePaidMutationFn = Apollo.MutationFunction<
  CreatePaidMutation,
  CreatePaidMutationVariables
>;

/**
 * __useCreatePaidMutation__
 *
 * To run a mutation, you first call `useCreatePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaidMutation, { data, loading, error }] = useCreatePaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaidMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaidMutation, CreatePaidMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaidMutation, CreatePaidMutationVariables>(
    CreatePaidDocument,
    options,
  );
}
export type CreatePaidMutationHookResult = ReturnType<typeof useCreatePaidMutation>;
export type CreatePaidMutationResult = Apollo.MutationResult<CreatePaidMutation>;
export type CreatePaidMutationOptions = Apollo.BaseMutationOptions<
  CreatePaidMutation,
  CreatePaidMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($deleteAccountId: Int!) {
    deleteAccount(id: $deleteAccountId)
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      deleteAccountId: // value for 'deleteAccountId'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(
    DeleteAccountDocument,
    options,
  );
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation deleteContact($deleteContactId: Int!) {
    deleteContact(id: $deleteContactId)
  }
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      deleteContactId: // value for 'deleteContactId'
 *   },
 * });
 */
export function useDeleteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(
    DeleteContactDocument,
    options,
  );
}
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const DeleteEarnedDocument = gql`
  mutation deleteEarned($earnedId: Int!) {
    deleteEarned(earnedId: $earnedId) {
      id
    }
  }
`;
export type DeleteEarnedMutationFn = Apollo.MutationFunction<
  DeleteEarnedMutation,
  DeleteEarnedMutationVariables
>;

/**
 * __useDeleteEarnedMutation__
 *
 * To run a mutation, you first call `useDeleteEarnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEarnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEarnedMutation, { data, loading, error }] = useDeleteEarnedMutation({
 *   variables: {
 *      earnedId: // value for 'earnedId'
 *   },
 * });
 */
export function useDeleteEarnedMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEarnedMutation, DeleteEarnedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEarnedMutation, DeleteEarnedMutationVariables>(
    DeleteEarnedDocument,
    options,
  );
}
export type DeleteEarnedMutationHookResult = ReturnType<typeof useDeleteEarnedMutation>;
export type DeleteEarnedMutationResult = Apollo.MutationResult<DeleteEarnedMutation>;
export type DeleteEarnedMutationOptions = Apollo.BaseMutationOptions<
  DeleteEarnedMutation,
  DeleteEarnedMutationVariables
>;
export const DeleteEducationDocument = gql`
  mutation deleteEducation($deleteEducationId: Int!) {
    deleteEducation(id: $deleteEducationId)
  }
`;
export type DeleteEducationMutationFn = Apollo.MutationFunction<
  DeleteEducationMutation,
  DeleteEducationMutationVariables
>;

/**
 * __useDeleteEducationMutation__
 *
 * To run a mutation, you first call `useDeleteEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationMutation, { data, loading, error }] = useDeleteEducationMutation({
 *   variables: {
 *      deleteEducationId: // value for 'deleteEducationId'
 *   },
 * });
 */
export function useDeleteEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEducationMutation,
    DeleteEducationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEducationMutation, DeleteEducationMutationVariables>(
    DeleteEducationDocument,
    options,
  );
}
export type DeleteEducationMutationHookResult = ReturnType<typeof useDeleteEducationMutation>;
export type DeleteEducationMutationResult = Apollo.MutationResult<DeleteEducationMutation>;
export type DeleteEducationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEducationMutation,
  DeleteEducationMutationVariables
>;
export const DeleteMembershipDocument = gql`
  mutation deleteMembership($deleteMembershipId: Int!) {
    deleteMembership(id: $deleteMembershipId)
  }
`;
export type DeleteMembershipMutationFn = Apollo.MutationFunction<
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables
>;

/**
 * __useDeleteMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipMutation, { data, loading, error }] = useDeleteMembershipMutation({
 *   variables: {
 *      deleteMembershipId: // value for 'deleteMembershipId'
 *   },
 * });
 */
export function useDeleteMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMembershipMutation,
    DeleteMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMembershipMutation, DeleteMembershipMutationVariables>(
    DeleteMembershipDocument,
    options,
  );
}
export type DeleteMembershipMutationHookResult = ReturnType<typeof useDeleteMembershipMutation>;
export type DeleteMembershipMutationResult = Apollo.MutationResult<DeleteMembershipMutation>;
export type DeleteMembershipMutationOptions = Apollo.BaseMutationOptions<
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables
>;
export const DeleteOfferDocument = gql`
  mutation deleteOffer($deleteOfferId: Int!) {
    deleteOffer(id: $deleteOfferId)
  }
`;
export type DeleteOfferMutationFn = Apollo.MutationFunction<
  DeleteOfferMutation,
  DeleteOfferMutationVariables
>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      deleteOfferId: // value for 'deleteOfferId'
 *   },
 * });
 */
export function useDeleteOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(
    DeleteOfferDocument,
    options,
  );
}
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfferMutation,
  DeleteOfferMutationVariables
>;
export const DeletePaidDocument = gql`
  mutation deletePaid($earnedId: Int!) {
    deletePaid(earnedId: $earnedId) {
      id
    }
  }
`;
export type DeletePaidMutationFn = Apollo.MutationFunction<
  DeletePaidMutation,
  DeletePaidMutationVariables
>;

/**
 * __useDeletePaidMutation__
 *
 * To run a mutation, you first call `useDeletePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaidMutation, { data, loading, error }] = useDeletePaidMutation({
 *   variables: {
 *      earnedId: // value for 'earnedId'
 *   },
 * });
 */
export function useDeletePaidMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePaidMutation, DeletePaidMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaidMutation, DeletePaidMutationVariables>(
    DeletePaidDocument,
    options,
  );
}
export type DeletePaidMutationHookResult = ReturnType<typeof useDeletePaidMutation>;
export type DeletePaidMutationResult = Apollo.MutationResult<DeletePaidMutation>;
export type DeletePaidMutationOptions = Apollo.BaseMutationOptions<
  DeletePaidMutation,
  DeletePaidMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($deleteUserId: Int!) {
    deleteUser(id: $deleteUserId)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const SetNewPaswordDocument = gql`
  mutation setNewPasword($password: String!) {
    setNewPasword(password: $password)
  }
`;
export type SetNewPaswordMutationFn = Apollo.MutationFunction<
  SetNewPaswordMutation,
  SetNewPaswordMutationVariables
>;

/**
 * __useSetNewPaswordMutation__
 *
 * To run a mutation, you first call `useSetNewPaswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPaswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPaswordMutation, { data, loading, error }] = useSetNewPaswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetNewPaswordMutation(
  baseOptions?: Apollo.MutationHookOptions<SetNewPaswordMutation, SetNewPaswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetNewPaswordMutation, SetNewPaswordMutationVariables>(
    SetNewPaswordDocument,
    options,
  );
}
export type SetNewPaswordMutationHookResult = ReturnType<typeof useSetNewPaswordMutation>;
export type SetNewPaswordMutationResult = Apollo.MutationResult<SetNewPaswordMutation>;
export type SetNewPaswordMutationOptions = Apollo.BaseMutationOptions<
  SetNewPaswordMutation,
  SetNewPaswordMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation updateAccount(
    $updateAccountId: Int!
    $input: UpdateAccountInput!
    $userAccounts: [Int!]!
  ) {
    updateAccount(id: $updateAccountId, input: $input, userAccounts: $userAccounts) {
      id
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      updateAccountId: // value for 'updateAccountId'
 *      input: // value for 'input'
 *      userAccounts: // value for 'userAccounts'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UpdateAccountDocument,
    options,
  );
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation updateContact($input: UpdateContactFullInput!, $sendNotification: Boolean) {
    updateContact(input: $input, send_notification: $sendNotification)
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendNotification: // value for 'sendNotification'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(
    UpdateContactDocument,
    options,
  );
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpdateEarnedDocument = gql`
  mutation updateEarned($input: UpdateEarnedInput!, $earnedId: Int!) {
    updateEarned(input: $input, earnedId: $earnedId) {
      almont_account
      booking_amount
      currency
      current_profit
      date_to_travel
      description
      id
      invoice
      mark_up
      title
      total_profit
      user_account
      user_id
    }
  }
`;
export type UpdateEarnedMutationFn = Apollo.MutationFunction<
  UpdateEarnedMutation,
  UpdateEarnedMutationVariables
>;

/**
 * __useUpdateEarnedMutation__
 *
 * To run a mutation, you first call `useUpdateEarnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEarnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEarnedMutation, { data, loading, error }] = useUpdateEarnedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      earnedId: // value for 'earnedId'
 *   },
 * });
 */
export function useUpdateEarnedMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEarnedMutation, UpdateEarnedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEarnedMutation, UpdateEarnedMutationVariables>(
    UpdateEarnedDocument,
    options,
  );
}
export type UpdateEarnedMutationHookResult = ReturnType<typeof useUpdateEarnedMutation>;
export type UpdateEarnedMutationResult = Apollo.MutationResult<UpdateEarnedMutation>;
export type UpdateEarnedMutationOptions = Apollo.BaseMutationOptions<
  UpdateEarnedMutation,
  UpdateEarnedMutationVariables
>;
export const UpdateEducationDocument = gql`
  mutation updateEducation($updateEducationId: Int!, $input: UpdateEducationInput!) {
    updateEducation(id: $updateEducationId, input: $input)
  }
`;
export type UpdateEducationMutationFn = Apollo.MutationFunction<
  UpdateEducationMutation,
  UpdateEducationMutationVariables
>;

/**
 * __useUpdateEducationMutation__
 *
 * To run a mutation, you first call `useUpdateEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationMutation, { data, loading, error }] = useUpdateEducationMutation({
 *   variables: {
 *      updateEducationId: // value for 'updateEducationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEducationMutation,
    UpdateEducationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEducationMutation, UpdateEducationMutationVariables>(
    UpdateEducationDocument,
    options,
  );
}
export type UpdateEducationMutationHookResult = ReturnType<typeof useUpdateEducationMutation>;
export type UpdateEducationMutationResult = Apollo.MutationResult<UpdateEducationMutation>;
export type UpdateEducationMutationOptions = Apollo.BaseMutationOptions<
  UpdateEducationMutation,
  UpdateEducationMutationVariables
>;
export const UpdateMembershipDocument = gql`
  mutation updateMembership($updateMembershipId: Int!, $input: UpdateMembershipInput!) {
    updateMembership(id: $updateMembershipId, input: $input)
  }
`;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables
>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      updateMembershipId: // value for 'updateMembershipId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembershipMutation,
    UpdateMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(
    UpdateMembershipDocument,
    options,
  );
}
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables
>;
export const UpdateOfferDocument = gql`
  mutation updateOffer(
    $input: UpdateOfferFullInput!
    $send_notification: Boolean
    $excludeForUser: [Int!]
  ) {
    updateOffer(
      input: $input
      send_notification: $send_notification
      excludeForUser: $excludeForUser
    )
  }
`;
export type UpdateOfferMutationFn = Apollo.MutationFunction<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      send_notification: // value for 'send_notification'
 *      excludeForUser: // value for 'excludeForUser'
 *   },
 * });
 */
export function useUpdateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOfferMutation, UpdateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(
    UpdateOfferDocument,
    options,
  );
}
export type UpdateOfferMutationHookResult = ReturnType<typeof useUpdateOfferMutation>;
export type UpdateOfferMutationResult = Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;
export const UpdatePaidDocument = gql`
  mutation updatePaid($input: UpdatePaidInput!, $earnedId: Int!) {
    updatePaid(input: $input, earnedId: $earnedId) {
      amount
      currency
      current_profit
      description
      id
      invoice
      note
    }
  }
`;
export type UpdatePaidMutationFn = Apollo.MutationFunction<
  UpdatePaidMutation,
  UpdatePaidMutationVariables
>;

/**
 * __useUpdatePaidMutation__
 *
 * To run a mutation, you first call `useUpdatePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaidMutation, { data, loading, error }] = useUpdatePaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *      earnedId: // value for 'earnedId'
 *   },
 * });
 */
export function useUpdatePaidMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePaidMutation, UpdatePaidMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaidMutation, UpdatePaidMutationVariables>(
    UpdatePaidDocument,
    options,
  );
}
export type UpdatePaidMutationHookResult = ReturnType<typeof useUpdatePaidMutation>;
export type UpdatePaidMutationResult = Apollo.MutationResult<UpdatePaidMutation>;
export type UpdatePaidMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaidMutation,
  UpdatePaidMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RequestChangePasswordTokenDocument = gql`
  mutation requestChangePasswordToken($code: Float!) {
    requestChangePasswordToken(code: $code)
  }
`;
export type RequestChangePasswordTokenMutationFn = Apollo.MutationFunction<
  RequestChangePasswordTokenMutation,
  RequestChangePasswordTokenMutationVariables
>;

/**
 * __useRequestChangePasswordTokenMutation__
 *
 * To run a mutation, you first call `useRequestChangePasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangePasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangePasswordTokenMutation, { data, loading, error }] = useRequestChangePasswordTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRequestChangePasswordTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestChangePasswordTokenMutation,
    RequestChangePasswordTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestChangePasswordTokenMutation,
    RequestChangePasswordTokenMutationVariables
  >(RequestChangePasswordTokenDocument, options);
}
export type RequestChangePasswordTokenMutationHookResult = ReturnType<
  typeof useRequestChangePasswordTokenMutation
>;
export type RequestChangePasswordTokenMutationResult =
  Apollo.MutationResult<RequestChangePasswordTokenMutation>;
export type RequestChangePasswordTokenMutationOptions = Apollo.BaseMutationOptions<
  RequestChangePasswordTokenMutation,
  RequestChangePasswordTokenMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      id
      full_name
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export function useCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const SentForgotPasswordResetKeyDocument = gql`
  query sentForgotPasswordResetKey($email: String!) {
    sentForgotPasswordResetKey(email: $email)
  }
`;

/**
 * __useSentForgotPasswordResetKeyQuery__
 *
 * To run a query within a React component, call `useSentForgotPasswordResetKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentForgotPasswordResetKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentForgotPasswordResetKeyQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSentForgotPasswordResetKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    SentForgotPasswordResetKeyQuery,
    SentForgotPasswordResetKeyQueryVariables
  > &
    ({ variables: SentForgotPasswordResetKeyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SentForgotPasswordResetKeyQuery, SentForgotPasswordResetKeyQueryVariables>(
    SentForgotPasswordResetKeyDocument,
    options,
  );
}
export function useSentForgotPasswordResetKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SentForgotPasswordResetKeyQuery,
    SentForgotPasswordResetKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SentForgotPasswordResetKeyQuery,
    SentForgotPasswordResetKeyQueryVariables
  >(SentForgotPasswordResetKeyDocument, options);
}
export function useSentForgotPasswordResetKeySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SentForgotPasswordResetKeyQuery,
    SentForgotPasswordResetKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SentForgotPasswordResetKeyQuery,
    SentForgotPasswordResetKeyQueryVariables
  >(SentForgotPasswordResetKeyDocument, options);
}
export type SentForgotPasswordResetKeyQueryHookResult = ReturnType<
  typeof useSentForgotPasswordResetKeyQuery
>;
export type SentForgotPasswordResetKeyLazyQueryHookResult = ReturnType<
  typeof useSentForgotPasswordResetKeyLazyQuery
>;
export type SentForgotPasswordResetKeySuspenseQueryHookResult = ReturnType<
  typeof useSentForgotPasswordResetKeySuspenseQuery
>;
export type SentForgotPasswordResetKeyQueryResult = Apollo.QueryResult<
  SentForgotPasswordResetKeyQuery,
  SentForgotPasswordResetKeyQueryVariables
>;
export const GetAccountByIdDocument = gql`
  query getAccountById($getAccountByIdId: Int!) {
    getAccountById(id: $getAccountByIdId) {
      comment
      id
      link
      login
      password
      title
      users {
        id
        full_name
        avatar {
          id
          mimetype
          path
          size
        }
      }
    }
  }
`;

/**
 * __useGetAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByIdQuery({
 *   variables: {
 *      getAccountByIdId: // value for 'getAccountByIdId'
 *   },
 * });
 */
export function useGetAccountByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables> &
    ({ variables: GetAccountByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(
    GetAccountByIdDocument,
    options,
  );
}
export function useGetAccountByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(
    GetAccountByIdDocument,
    options,
  );
}
export function useGetAccountByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(
    GetAccountByIdDocument,
    options,
  );
}
export type GetAccountByIdQueryHookResult = ReturnType<typeof useGetAccountByIdQuery>;
export type GetAccountByIdLazyQueryHookResult = ReturnType<typeof useGetAccountByIdLazyQuery>;
export type GetAccountByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountByIdSuspenseQuery
>;
export type GetAccountByIdQueryResult = Apollo.QueryResult<
  GetAccountByIdQuery,
  GetAccountByIdQueryVariables
>;
export const GetAccountsDocument = gql`
  query getAccounts($input: PaginationInput!) {
    getAccounts(input: $input) {
      count
      rows {
        id
        link
        login
        password
        title
        comment
      }
    }
  }
`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables> &
    ({ variables: GetAccountsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export function useGetAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsSuspenseQueryHookResult = ReturnType<typeof useGetAccountsSuspenseQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<
  GetAccountsQuery,
  GetAccountsQueryVariables
>;
export const GetContactByIdDocument = gql`
  query getContactById($getContactByIdId: Int!) {
    getContactById(id: $getContactByIdId) {
      id
      additional_data {
        address
        comment
        contact_person
        country
        emails {
          id
          email
        }
        id
        phones {
          id
          phone
        }
        whats_app
      }
      city
      title
      company
      profit
      commission
    }
  }
`;

/**
 * __useGetContactByIdQuery__
 *
 * To run a query within a React component, call `useGetContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByIdQuery({
 *   variables: {
 *      getContactByIdId: // value for 'getContactByIdId'
 *   },
 * });
 */
export function useGetContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables> &
    ({ variables: GetContactByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(
    GetContactByIdDocument,
    options,
  );
}
export function useGetContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(
    GetContactByIdDocument,
    options,
  );
}
export function useGetContactByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(
    GetContactByIdDocument,
    options,
  );
}
export type GetContactByIdQueryHookResult = ReturnType<typeof useGetContactByIdQuery>;
export type GetContactByIdLazyQueryHookResult = ReturnType<typeof useGetContactByIdLazyQuery>;
export type GetContactByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetContactByIdSuspenseQuery
>;
export type GetContactByIdQueryResult = Apollo.QueryResult<
  GetContactByIdQuery,
  GetContactByIdQueryVariables
>;
export const GetContactsDocument = gql`
  query getContacts($input: ContactFiltersInput!) {
    getContacts(input: $input) {
      count
      rows {
        id
        type
        title
        city
        company
        commission
        additional_data {
          address
          country
          contact_person
          emails {
            additional_data_id
            created_at
            email
            id
            email
            updated_at
          }
          phones {
            additional_data_id
            created_at
            phone
            id
            updated_at
          }
        }
      }
    }
  }
`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables> &
    ({ variables: GetContactsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
}
export function useGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(
    GetContactsDocument,
    options,
  );
}
export function useGetContactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContactsQuery, GetContactsQueryVariables>(
    GetContactsDocument,
    options,
  );
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsSuspenseQueryHookResult = ReturnType<typeof useGetContactsSuspenseQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<
  GetContactsQuery,
  GetContactsQueryVariables
>;
export const GetOneDocumentByIdDocument = gql`
  query getOneDocumentById($getOneDocumentByIdId: Int!) {
    getOneDocumentById(id: $getOneDocumentByIdId) {
      ... on OwnDocumentObject {
        comment
        date
        id
        owner
        photos {
          id
          mimetype
          path
          size
        }
        title
        type
      }
      ... on PassportDocumentObject {
        birthDate
        comment
        country
        expiry
        id
        issued
        owner
        photos {
          id
          mimetype
          path
          size
        }
        serialNumber
        type
      }
      ... on VisaDocumentObject {
        comment
        country
        expiry
        id
        issued
        owner
        photos {
          id
          mimetype
          path
          size
        }
        serialNumber
        type
      }
    }
  }
`;

/**
 * __useGetOneDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetOneDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneDocumentByIdQuery({
 *   variables: {
 *      getOneDocumentByIdId: // value for 'getOneDocumentByIdId'
 *   },
 * });
 */
export function useGetOneDocumentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOneDocumentByIdQuery, GetOneDocumentByIdQueryVariables> &
    ({ variables: GetOneDocumentByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneDocumentByIdQuery, GetOneDocumentByIdQueryVariables>(
    GetOneDocumentByIdDocument,
    options,
  );
}
export function useGetOneDocumentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneDocumentByIdQuery,
    GetOneDocumentByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneDocumentByIdQuery, GetOneDocumentByIdQueryVariables>(
    GetOneDocumentByIdDocument,
    options,
  );
}
export function useGetOneDocumentByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOneDocumentByIdQuery,
    GetOneDocumentByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOneDocumentByIdQuery, GetOneDocumentByIdQueryVariables>(
    GetOneDocumentByIdDocument,
    options,
  );
}
export type GetOneDocumentByIdQueryHookResult = ReturnType<typeof useGetOneDocumentByIdQuery>;
export type GetOneDocumentByIdLazyQueryHookResult = ReturnType<
  typeof useGetOneDocumentByIdLazyQuery
>;
export type GetOneDocumentByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOneDocumentByIdSuspenseQuery
>;
export type GetOneDocumentByIdQueryResult = Apollo.QueryResult<
  GetOneDocumentByIdQuery,
  GetOneDocumentByIdQueryVariables
>;
export const GetEarnedListDocument = gql`
  query getEarnedList($userId: Int!) {
    getEarnedList(userId: $userId) {
      group {
        EUR {
          almont_account
          booking_amount
          currency
          current_profit
          date_to_travel
          description
          id
          invoice
          mark_up
          title
          total_profit
          user_account
          user_id
        }
        GBP {
          almont_account
          booking_amount
          currency
          current_profit
          date_to_travel
          description
          id
          invoice
          mark_up
          title
          total_profit
          user_account
          user_id
        }
        USD {
          almont_account
          booking_amount
          currency
          current_profit
          date_to_travel
          description
          id
          invoice
          mark_up
          title
          total_profit
          user_account
          user_id
        }
      }
      total {
        EUR {
          count_records
          total_almont_account
          total_booking_amount
          total_mark_up
          total_total_profit
          total_user_account
        }
        GBP {
          count_records
          total_almont_account
          total_booking_amount
          total_mark_up
          total_total_profit
          total_user_account
        }
        USD {
          count_records
          total_almont_account
          total_booking_amount
          total_mark_up
          total_total_profit
          total_user_account
        }
      }
    }
  }
`;

/**
 * __useGetEarnedListQuery__
 *
 * To run a query within a React component, call `useGetEarnedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarnedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarnedListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEarnedListQuery(
  baseOptions: Apollo.QueryHookOptions<GetEarnedListQuery, GetEarnedListQueryVariables> &
    ({ variables: GetEarnedListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEarnedListQuery, GetEarnedListQueryVariables>(
    GetEarnedListDocument,
    options,
  );
}
export function useGetEarnedListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEarnedListQuery, GetEarnedListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEarnedListQuery, GetEarnedListQueryVariables>(
    GetEarnedListDocument,
    options,
  );
}
export function useGetEarnedListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetEarnedListQuery, GetEarnedListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEarnedListQuery, GetEarnedListQueryVariables>(
    GetEarnedListDocument,
    options,
  );
}
export type GetEarnedListQueryHookResult = ReturnType<typeof useGetEarnedListQuery>;
export type GetEarnedListLazyQueryHookResult = ReturnType<typeof useGetEarnedListLazyQuery>;
export type GetEarnedListSuspenseQueryHookResult = ReturnType<typeof useGetEarnedListSuspenseQuery>;
export type GetEarnedListQueryResult = Apollo.QueryResult<
  GetEarnedListQuery,
  GetEarnedListQueryVariables
>;
export const GetEarnedPaidDataByUserDocument = gql`
  query getEarnedPaidDataByUser($userId: Int!) {
    getEarnedPaidDataByUser(userId: $userId) {
      EUR
      GBP
      USD
    }
  }
`;

/**
 * __useGetEarnedPaidDataByUserQuery__
 *
 * To run a query within a React component, call `useGetEarnedPaidDataByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarnedPaidDataByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarnedPaidDataByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEarnedPaidDataByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEarnedPaidDataByUserQuery,
    GetEarnedPaidDataByUserQueryVariables
  > &
    ({ variables: GetEarnedPaidDataByUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEarnedPaidDataByUserQuery, GetEarnedPaidDataByUserQueryVariables>(
    GetEarnedPaidDataByUserDocument,
    options,
  );
}
export function useGetEarnedPaidDataByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEarnedPaidDataByUserQuery,
    GetEarnedPaidDataByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEarnedPaidDataByUserQuery, GetEarnedPaidDataByUserQueryVariables>(
    GetEarnedPaidDataByUserDocument,
    options,
  );
}
export function useGetEarnedPaidDataByUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEarnedPaidDataByUserQuery,
    GetEarnedPaidDataByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEarnedPaidDataByUserQuery,
    GetEarnedPaidDataByUserQueryVariables
  >(GetEarnedPaidDataByUserDocument, options);
}
export type GetEarnedPaidDataByUserQueryHookResult = ReturnType<
  typeof useGetEarnedPaidDataByUserQuery
>;
export type GetEarnedPaidDataByUserLazyQueryHookResult = ReturnType<
  typeof useGetEarnedPaidDataByUserLazyQuery
>;
export type GetEarnedPaidDataByUserSuspenseQueryHookResult = ReturnType<
  typeof useGetEarnedPaidDataByUserSuspenseQuery
>;
export type GetEarnedPaidDataByUserQueryResult = Apollo.QueryResult<
  GetEarnedPaidDataByUserQuery,
  GetEarnedPaidDataByUserQueryVariables
>;
export const GetEducationByIdDocument = gql`
  query getEducationById($getEducationByIdId: Int!) {
    getEducationById(id: $getEducationByIdId) {
      id
      title
      files {
        path
        mimetype
        size
        id
      }
      comment
      type
    }
  }
`;

/**
 * __useGetEducationByIdQuery__
 *
 * To run a query within a React component, call `useGetEducationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationByIdQuery({
 *   variables: {
 *      getEducationByIdId: // value for 'getEducationByIdId'
 *   },
 * });
 */
export function useGetEducationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetEducationByIdQuery, GetEducationByIdQueryVariables> &
    ({ variables: GetEducationByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEducationByIdQuery, GetEducationByIdQueryVariables>(
    GetEducationByIdDocument,
    options,
  );
}
export function useGetEducationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEducationByIdQuery, GetEducationByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEducationByIdQuery, GetEducationByIdQueryVariables>(
    GetEducationByIdDocument,
    options,
  );
}
export function useGetEducationByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEducationByIdQuery,
    GetEducationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEducationByIdQuery, GetEducationByIdQueryVariables>(
    GetEducationByIdDocument,
    options,
  );
}
export type GetEducationByIdQueryHookResult = ReturnType<typeof useGetEducationByIdQuery>;
export type GetEducationByIdLazyQueryHookResult = ReturnType<typeof useGetEducationByIdLazyQuery>;
export type GetEducationByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetEducationByIdSuspenseQuery
>;
export type GetEducationByIdQueryResult = Apollo.QueryResult<
  GetEducationByIdQuery,
  GetEducationByIdQueryVariables
>;
export const GetEducationsDocument = gql`
  query getEducations($input: FilterEducationInput!) {
    getEducations(input: $input) {
      count
      rows {
        title
        id
        files {
          id
          mimetype
          path
          size
        }
        comment
        type
      }
    }
  }
`;

/**
 * __useGetEducationsQuery__
 *
 * To run a query within a React component, call `useGetEducationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEducationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEducationsQuery, GetEducationsQueryVariables> &
    ({ variables: GetEducationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEducationsQuery, GetEducationsQueryVariables>(
    GetEducationsDocument,
    options,
  );
}
export function useGetEducationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEducationsQuery, GetEducationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEducationsQuery, GetEducationsQueryVariables>(
    GetEducationsDocument,
    options,
  );
}
export function useGetEducationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetEducationsQuery, GetEducationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEducationsQuery, GetEducationsQueryVariables>(
    GetEducationsDocument,
    options,
  );
}
export type GetEducationsQueryHookResult = ReturnType<typeof useGetEducationsQuery>;
export type GetEducationsLazyQueryHookResult = ReturnType<typeof useGetEducationsLazyQuery>;
export type GetEducationsSuspenseQueryHookResult = ReturnType<typeof useGetEducationsSuspenseQuery>;
export type GetEducationsQueryResult = Apollo.QueryResult<
  GetEducationsQuery,
  GetEducationsQueryVariables
>;
export const GetManyListDocumentObjectDocument = gql`
  query getManyListDocumentObject($input: DocumentFiltersInput!) {
    getManyListDocumentObject(input: $input) {
      count
      rows {
        comment
        date_from
        date_to
        id
        owner
        type
      }
    }
  }
`;

/**
 * __useGetManyListDocumentObjectQuery__
 *
 * To run a query within a React component, call `useGetManyListDocumentObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyListDocumentObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyListDocumentObjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetManyListDocumentObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManyListDocumentObjectQuery,
    GetManyListDocumentObjectQueryVariables
  > &
    ({ variables: GetManyListDocumentObjectQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyListDocumentObjectQuery, GetManyListDocumentObjectQueryVariables>(
    GetManyListDocumentObjectDocument,
    options,
  );
}
export function useGetManyListDocumentObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyListDocumentObjectQuery,
    GetManyListDocumentObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyListDocumentObjectQuery,
    GetManyListDocumentObjectQueryVariables
  >(GetManyListDocumentObjectDocument, options);
}
export function useGetManyListDocumentObjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetManyListDocumentObjectQuery,
    GetManyListDocumentObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetManyListDocumentObjectQuery,
    GetManyListDocumentObjectQueryVariables
  >(GetManyListDocumentObjectDocument, options);
}
export type GetManyListDocumentObjectQueryHookResult = ReturnType<
  typeof useGetManyListDocumentObjectQuery
>;
export type GetManyListDocumentObjectLazyQueryHookResult = ReturnType<
  typeof useGetManyListDocumentObjectLazyQuery
>;
export type GetManyListDocumentObjectSuspenseQueryHookResult = ReturnType<
  typeof useGetManyListDocumentObjectSuspenseQuery
>;
export type GetManyListDocumentObjectQueryResult = Apollo.QueryResult<
  GetManyListDocumentObjectQuery,
  GetManyListDocumentObjectQueryVariables
>;
export const GetMembershipByIdDocument = gql`
  query getMembershipById($getMembershipByIdId: Int!) {
    getMembershipById(id: $getMembershipByIdId) {
      brand
      comment
      exclusiveBenefits
      howToUse
      id
      link
      participating
      title
    }
  }
`;

/**
 * __useGetMembershipByIdQuery__
 *
 * To run a query within a React component, call `useGetMembershipByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipByIdQuery({
 *   variables: {
 *      getMembershipByIdId: // value for 'getMembershipByIdId'
 *   },
 * });
 */
export function useGetMembershipByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMembershipByIdQuery, GetMembershipByIdQueryVariables> &
    ({ variables: GetMembershipByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembershipByIdQuery, GetMembershipByIdQueryVariables>(
    GetMembershipByIdDocument,
    options,
  );
}
export function useGetMembershipByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembershipByIdQuery,
    GetMembershipByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembershipByIdQuery, GetMembershipByIdQueryVariables>(
    GetMembershipByIdDocument,
    options,
  );
}
export function useGetMembershipByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMembershipByIdQuery,
    GetMembershipByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMembershipByIdQuery, GetMembershipByIdQueryVariables>(
    GetMembershipByIdDocument,
    options,
  );
}
export type GetMembershipByIdQueryHookResult = ReturnType<typeof useGetMembershipByIdQuery>;
export type GetMembershipByIdLazyQueryHookResult = ReturnType<typeof useGetMembershipByIdLazyQuery>;
export type GetMembershipByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetMembershipByIdSuspenseQuery
>;
export type GetMembershipByIdQueryResult = Apollo.QueryResult<
  GetMembershipByIdQuery,
  GetMembershipByIdQueryVariables
>;
export const GetMembershipsDocument = gql`
  query getMemberships($input: MembershipFiltersInput!) {
    getMemberships(input: $input) {
      count
      rows {
        brand
        exclusiveBenefits
        id
        link
        participating
        title
      }
    }
  }
`;

/**
 * __useGetMembershipsQuery__
 *
 * To run a query within a React component, call `useGetMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembershipsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables> &
    ({ variables: GetMembershipsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(
    GetMembershipsDocument,
    options,
  );
}
export function useGetMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(
    GetMembershipsDocument,
    options,
  );
}
export function useGetMembershipsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(
    GetMembershipsDocument,
    options,
  );
}
export type GetMembershipsQueryHookResult = ReturnType<typeof useGetMembershipsQuery>;
export type GetMembershipsLazyQueryHookResult = ReturnType<typeof useGetMembershipsLazyQuery>;
export type GetMembershipsSuspenseQueryHookResult = ReturnType<
  typeof useGetMembershipsSuspenseQuery
>;
export type GetMembershipsQueryResult = Apollo.QueryResult<
  GetMembershipsQuery,
  GetMembershipsQueryVariables
>;
export const GetNotificationHistoriesDocument = gql`
  query getNotificationHistories($input: PaginationInput!) {
    getNotificationHistories(input: $input) {
      count
      rows {
        created_at
        data
        id
        notificationMessage
        receiverIds
        users {
          full_name
        }
        sender {
          full_name
        }
      }
    }
  }
`;

/**
 * __useGetNotificationHistoriesQuery__
 *
 * To run a query within a React component, call `useGetNotificationHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotificationHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationHistoriesQuery,
    GetNotificationHistoriesQueryVariables
  > &
    ({ variables: GetNotificationHistoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationHistoriesQuery, GetNotificationHistoriesQueryVariables>(
    GetNotificationHistoriesDocument,
    options,
  );
}
export function useGetNotificationHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationHistoriesQuery,
    GetNotificationHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationHistoriesQuery, GetNotificationHistoriesQueryVariables>(
    GetNotificationHistoriesDocument,
    options,
  );
}
export function useGetNotificationHistoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNotificationHistoriesQuery,
    GetNotificationHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetNotificationHistoriesQuery,
    GetNotificationHistoriesQueryVariables
  >(GetNotificationHistoriesDocument, options);
}
export type GetNotificationHistoriesQueryHookResult = ReturnType<
  typeof useGetNotificationHistoriesQuery
>;
export type GetNotificationHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetNotificationHistoriesLazyQuery
>;
export type GetNotificationHistoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationHistoriesSuspenseQuery
>;
export type GetNotificationHistoriesQueryResult = Apollo.QueryResult<
  GetNotificationHistoriesQuery,
  GetNotificationHistoriesQueryVariables
>;
export const GetOfferByIdDocument = gql`
  query getOfferById($getOfferByIdId: Int!) {
    getOfferById(id: $getOfferByIdId) {
      city
      order
      currency
      date_from
      date_to
      id
      information
      link
      preview {
        path
      }
      price_from
      price_to
      title
      onlyForClient
      additional_data {
        address
        comment
        contact_person
        country
        emails {
          email
          id
        }
        id
        phones {
          id
          phone
        }
        whats_app
      }
      excludeUsers {
        offerId
        userId
      }
    }
  }
`;

/**
 * __useGetOfferByIdQuery__
 *
 * To run a query within a React component, call `useGetOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferByIdQuery({
 *   variables: {
 *      getOfferByIdId: // value for 'getOfferByIdId'
 *   },
 * });
 */
export function useGetOfferByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfferByIdQuery, GetOfferByIdQueryVariables> &
    ({ variables: GetOfferByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferByIdQuery, GetOfferByIdQueryVariables>(
    GetOfferByIdDocument,
    options,
  );
}
export function useGetOfferByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferByIdQuery, GetOfferByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferByIdQuery, GetOfferByIdQueryVariables>(
    GetOfferByIdDocument,
    options,
  );
}
export function useGetOfferByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOfferByIdQuery, GetOfferByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOfferByIdQuery, GetOfferByIdQueryVariables>(
    GetOfferByIdDocument,
    options,
  );
}
export type GetOfferByIdQueryHookResult = ReturnType<typeof useGetOfferByIdQuery>;
export type GetOfferByIdLazyQueryHookResult = ReturnType<typeof useGetOfferByIdLazyQuery>;
export type GetOfferByIdSuspenseQueryHookResult = ReturnType<typeof useGetOfferByIdSuspenseQuery>;
export type GetOfferByIdQueryResult = Apollo.QueryResult<
  GetOfferByIdQuery,
  GetOfferByIdQueryVariables
>;
export const GetOffersDocument = gql`
  query getOffers($input: OfferFiltersInput!) {
    getOffers(input: $input) {
      count
      rows {
        title
        id
        date_from
        date_to
        price_from
        price_to
        city
        currency
        information
        link
        additional_data {
          country
          id
          comment
          contact_person
          created_at
          emails {
            additional_data_id
            created_at
            email
            id
            email
            updated_at
          }
          phones {
            additional_data_id
            created_at
            phone
            id
            updated_at
          }
          updated_at
          whats_app
        }
      }
    }
  }
`;

/**
 * __useGetOffersQuery__
 *
 * To run a query within a React component, call `useGetOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOffersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOffersQuery, GetOffersQueryVariables> &
    ({ variables: GetOffersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
}
export function useGetOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOffersQuery, GetOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
}
export function useGetOffersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOffersQuery, GetOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOffersQuery, GetOffersQueryVariables>(
    GetOffersDocument,
    options,
  );
}
export type GetOffersQueryHookResult = ReturnType<typeof useGetOffersQuery>;
export type GetOffersLazyQueryHookResult = ReturnType<typeof useGetOffersLazyQuery>;
export type GetOffersSuspenseQueryHookResult = ReturnType<typeof useGetOffersSuspenseQuery>;
export type GetOffersQueryResult = Apollo.QueryResult<GetOffersQuery, GetOffersQueryVariables>;
export const GetPaidListDocument = gql`
  query getPaidList($userId: Int!) {
    getPaidList(userId: $userId) {
      group {
        EUR {
          amount
          currency
          current_profit
          description
          id
          invoice
          note
          user_id
        }
        GBP {
          amount
          currency
          current_profit
          description
          id
          invoice
          note
          user_id
        }
        USD {
          amount
          currency
          current_profit
          description
          id
          invoice
          note
          user_id
        }
      }
      total {
        EUR {
          count_records
          total_amount
        }
        GBP {
          count_records
          total_amount
        }
        USD {
          count_records
          total_amount
        }
      }
    }
  }
`;

/**
 * __useGetPaidListQuery__
 *
 * To run a query within a React component, call `useGetPaidListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPaidListQuery(
  baseOptions: Apollo.QueryHookOptions<GetPaidListQuery, GetPaidListQueryVariables> &
    ({ variables: GetPaidListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaidListQuery, GetPaidListQueryVariables>(GetPaidListDocument, options);
}
export function useGetPaidListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaidListQuery, GetPaidListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaidListQuery, GetPaidListQueryVariables>(
    GetPaidListDocument,
    options,
  );
}
export function useGetPaidListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaidListQuery, GetPaidListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPaidListQuery, GetPaidListQueryVariables>(
    GetPaidListDocument,
    options,
  );
}
export type GetPaidListQueryHookResult = ReturnType<typeof useGetPaidListQuery>;
export type GetPaidListLazyQueryHookResult = ReturnType<typeof useGetPaidListLazyQuery>;
export type GetPaidListSuspenseQueryHookResult = ReturnType<typeof useGetPaidListSuspenseQuery>;
export type GetPaidListQueryResult = Apollo.QueryResult<
  GetPaidListQuery,
  GetPaidListQueryVariables
>;
export const GetSectionsDocument = gql`
  query getSections {
    getSections {
      id
      name
    }
  }
`;

/**
 * __useGetSectionsQuery__
 *
 * To run a query within a React component, call `useGetSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSectionsQuery, GetSectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSectionsQuery, GetSectionsQueryVariables>(GetSectionsDocument, options);
}
export function useGetSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSectionsQuery, GetSectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    options,
  );
}
export function useGetSectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSectionsQuery, GetSectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    options,
  );
}
export type GetSectionsQueryHookResult = ReturnType<typeof useGetSectionsQuery>;
export type GetSectionsLazyQueryHookResult = ReturnType<typeof useGetSectionsLazyQuery>;
export type GetSectionsSuspenseQueryHookResult = ReturnType<typeof useGetSectionsSuspenseQuery>;
export type GetSectionsQueryResult = Apollo.QueryResult<
  GetSectionsQuery,
  GetSectionsQueryVariables
>;
export const GetSuccessManagerNamesDocument = gql`
  query getSuccessManagerNames {
    getSuccessManagerNames {
      id
      name
    }
  }
`;

/**
 * __useGetSuccessManagerNamesQuery__
 *
 * To run a query within a React component, call `useGetSuccessManagerNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuccessManagerNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuccessManagerNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuccessManagerNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSuccessManagerNamesQuery,
    GetSuccessManagerNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSuccessManagerNamesQuery, GetSuccessManagerNamesQueryVariables>(
    GetSuccessManagerNamesDocument,
    options,
  );
}
export function useGetSuccessManagerNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuccessManagerNamesQuery,
    GetSuccessManagerNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSuccessManagerNamesQuery, GetSuccessManagerNamesQueryVariables>(
    GetSuccessManagerNamesDocument,
    options,
  );
}
export function useGetSuccessManagerNamesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSuccessManagerNamesQuery,
    GetSuccessManagerNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSuccessManagerNamesQuery, GetSuccessManagerNamesQueryVariables>(
    GetSuccessManagerNamesDocument,
    options,
  );
}
export type GetSuccessManagerNamesQueryHookResult = ReturnType<
  typeof useGetSuccessManagerNamesQuery
>;
export type GetSuccessManagerNamesLazyQueryHookResult = ReturnType<
  typeof useGetSuccessManagerNamesLazyQuery
>;
export type GetSuccessManagerNamesSuspenseQueryHookResult = ReturnType<
  typeof useGetSuccessManagerNamesSuspenseQuery
>;
export type GetSuccessManagerNamesQueryResult = Apollo.QueryResult<
  GetSuccessManagerNamesQuery,
  GetSuccessManagerNamesQueryVariables
>;
export const GetUserByIdDocument = gql`
  query getUserById($getUserByIdId: Int!) {
    getUserById(id: $getUserByIdId) {
      birthday
      email
      full_name
      id
      phone_number
      avatar {
        id
        path
        mimetype
        size
      }
      profit
      is_success_manager
      managers {
        id
        full_name
      }
      comment
      sections {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      getUserByIdId: // value for 'getUserByIdId'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables> &
    ({ variables: GetUserByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export function useGetUserByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserByIdSuspenseQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        email
        phone_number
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables> &
    ({ variables: GetUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUsersForAccountsDocument = gql`
  query getUsersForAccounts($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;

/**
 * __useGetUsersForAccountsQuery__
 *
 * To run a query within a React component, call `useGetUsersForAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersForAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForAccountsQuery,
    GetUsersForAccountsQueryVariables
  > &
    ({ variables: GetUsersForAccountsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersForAccountsQuery, GetUsersForAccountsQueryVariables>(
    GetUsersForAccountsDocument,
    options,
  );
}
export function useGetUsersForAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForAccountsQuery,
    GetUsersForAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersForAccountsQuery, GetUsersForAccountsQueryVariables>(
    GetUsersForAccountsDocument,
    options,
  );
}
export function useGetUsersForAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersForAccountsQuery,
    GetUsersForAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersForAccountsQuery, GetUsersForAccountsQueryVariables>(
    GetUsersForAccountsDocument,
    options,
  );
}
export type GetUsersForAccountsQueryHookResult = ReturnType<typeof useGetUsersForAccountsQuery>;
export type GetUsersForAccountsLazyQueryHookResult = ReturnType<
  typeof useGetUsersForAccountsLazyQuery
>;
export type GetUsersForAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersForAccountsSuspenseQuery
>;
export type GetUsersForAccountsQueryResult = Apollo.QueryResult<
  GetUsersForAccountsQuery,
  GetUsersForAccountsQueryVariables
>;
export const GetUsersForNotificationsDocument = gql`
  query getUsersForNotifications($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;

/**
 * __useGetUsersForNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUsersForNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersForNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForNotificationsQuery,
    GetUsersForNotificationsQueryVariables
  > &
    ({ variables: GetUsersForNotificationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersForNotificationsQuery, GetUsersForNotificationsQueryVariables>(
    GetUsersForNotificationsDocument,
    options,
  );
}
export function useGetUsersForNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForNotificationsQuery,
    GetUsersForNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersForNotificationsQuery, GetUsersForNotificationsQueryVariables>(
    GetUsersForNotificationsDocument,
    options,
  );
}
export function useGetUsersForNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersForNotificationsQuery,
    GetUsersForNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUsersForNotificationsQuery,
    GetUsersForNotificationsQueryVariables
  >(GetUsersForNotificationsDocument, options);
}
export type GetUsersForNotificationsQueryHookResult = ReturnType<
  typeof useGetUsersForNotificationsQuery
>;
export type GetUsersForNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetUsersForNotificationsLazyQuery
>;
export type GetUsersForNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersForNotificationsSuspenseQuery
>;
export type GetUsersForNotificationsQueryResult = Apollo.QueryResult<
  GetUsersForNotificationsQuery,
  GetUsersForNotificationsQueryVariables
>;
export const GetUsersForOfferDocument = gql`
  query getUsersForOffer($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;

/**
 * __useGetUsersForOfferQuery__
 *
 * To run a query within a React component, call `useGetUsersForOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForOfferQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersForOfferQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersForOfferQuery, GetUsersForOfferQueryVariables> &
    ({ variables: GetUsersForOfferQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersForOfferQuery, GetUsersForOfferQueryVariables>(
    GetUsersForOfferDocument,
    options,
  );
}
export function useGetUsersForOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersForOfferQuery, GetUsersForOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersForOfferQuery, GetUsersForOfferQueryVariables>(
    GetUsersForOfferDocument,
    options,
  );
}
export function useGetUsersForOfferSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersForOfferQuery,
    GetUsersForOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersForOfferQuery, GetUsersForOfferQueryVariables>(
    GetUsersForOfferDocument,
    options,
  );
}
export type GetUsersForOfferQueryHookResult = ReturnType<typeof useGetUsersForOfferQuery>;
export type GetUsersForOfferLazyQueryHookResult = ReturnType<typeof useGetUsersForOfferLazyQuery>;
export type GetUsersForOfferSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersForOfferSuspenseQuery
>;
export type GetUsersForOfferQueryResult = Apollo.QueryResult<
  GetUsersForOfferQuery,
  GetUsersForOfferQueryVariables
>;
export const SignInDocument = gql`
  query signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

/**
 * __useSignInQuery__
 *
 * To run a query within a React component, call `useSignInQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInQuery(
  baseOptions: Apollo.QueryHookOptions<SignInQuery, SignInQueryVariables> &
    ({ variables: SignInQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignInQuery, SignInQueryVariables>(SignInDocument, options);
}
export function useSignInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignInQuery, SignInQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignInQuery, SignInQueryVariables>(SignInDocument, options);
}
export function useSignInSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SignInQuery, SignInQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SignInQuery, SignInQueryVariables>(SignInDocument, options);
}
export type SignInQueryHookResult = ReturnType<typeof useSignInQuery>;
export type SignInLazyQueryHookResult = ReturnType<typeof useSignInLazyQuery>;
export type SignInSuspenseQueryHookResult = ReturnType<typeof useSignInSuspenseQuery>;
export type SignInQueryResult = Apollo.QueryResult<SignInQuery, SignInQueryVariables>;
