import { Button, Dialog, styled } from '@mui/material';

import { StyledDialogType } from './types';

export const Wrapper = styled(Dialog)<StyledDialogType>(({ isconfirmmodal, theme }) => {
  const isConfirmModal = isconfirmmodal === 'true';

  return {
    backgroundColor: theme.palette.black60,
    '.MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '.MuiPaper-root': {
      margin: 0,
      minHeight: isConfirmModal ? 'initial' : '100vh',
      minWidth: isConfirmModal ? '500px' : '50vw',
      borderRadius: isConfirmModal ? '2px' : 0,
      padding: isConfirmModal ? '32px 32px 24px' : '40px',
      background: isConfirmModal ? theme.palette.primary.main : theme.palette.gray5,
    },
    '.MuiDialog-container': {
      justifyContent: isConfirmModal ? 'center' : 'flex-end',
    },
  };
});

export const CloseButton = styled(Button)(({ theme }) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.lightBlue,
    borderRadius: '32px',
    minWidth: '32px',
    height: '32px',
    position: 'absolute',
    top: '44px',
    right: '40px',

    '&:hover': {
      background: theme.palette.lightBlue,
    },

    span: {
      margin: 0,
    },
  };
});
