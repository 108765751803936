import { Avatar, Button, IconButton, Stack, Typography } from '@mui/material';
import { DeleteIcon, EditIcon, LinkIcon, UsersIcon } from 'components/Icons';
import { DatePickerRHF } from 'components/UI/DatePickerRHF';
import { TextFieldRHF } from 'components/UI/TextFieldRHF';
import { API_URL } from 'constants/index';
import dayjs from 'dayjs';
import { palette } from 'theme/foundations';

import { COLUMN_IDS } from '../../constants';
import { TableRowType } from '../../types';
import { TableCellContentType } from '../TableListRow/types';
import { TableRowActionMenu } from '../TableRowActionMenu';
import { TableRowActionMenuType } from '../TableRowActionMenu/types';

export const TableCellContent = ({ value, id }: TableCellContentType) => {
  const handleOpenLink = () => {
    window.open(value as string, '_blank');
  };

  switch (id) {
    case COLUMN_IDS.ACTION:
      return <TableRowActionMenu options={value as TableRowActionMenuType['options']} />;
    case COLUMN_IDS.LINK:
      return (
        <IconButton onClick={handleOpenLink}>
          <LinkIcon />
        </IconButton>
      );
    case COLUMN_IDS.FULL_NAME:
      return (
        <Stack alignItems={'center'} direction={'row'}>
          <Avatar
            sx={{ width: 24, height: 24 }}
            src={`${API_URL}/${(value as TableRowType['fullName'])?.preview}`}
          >
            <UsersIcon size={20} fill={palette.secondary.main} />
          </Avatar>
          <Typography variant='body1' ml='8px'>
            {String((value as TableRowType['fullName'])?.name)}
          </Typography>
        </Stack>
      );
    case COLUMN_IDS.EXCLUSIVE_BENEFITS:
      return (
        <Typography
          variant='body1'
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {String(value)}
        </Typography>
      );
    default:
      return (
        <Typography variant='body1' sx={{ wordWrap: 'break-word' }}>
          {String(value)}
        </Typography>
      );
  }
};

export const EditibleTableCellContent = ({
  isEdit,
  value,
  id,
  isLastRow,
  readonly,
  handleSetIsEditRow,
  handleClearRowValue,
  handleDeleteRow,
  textFieldType = 'text',
  onSubmit,
}: TableCellContentType) => {
  const handleOpenEditMode = () => {
    handleSetIsEditRow?.(true);
  };

  const handleCloseOpenEditMode = () => {
    handleSetIsEditRow?.(false);
    handleClearRowValue?.();
  };

  if (id === COLUMN_IDS.ACTION) {
    if (isLastRow) return null;
    return (
      <Stack direction={'row'}>
        {!isEdit ? (
          <>
            <IconButton sx={{ mr: '32px' }} onClick={handleOpenEditMode}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteRow}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              variant='text'
              sx={{ mr: '8px', color: palette.blue91, minWidth: '46px', justifyContent: 'start' }}
              onClick={onSubmit}
            >
              Save
            </Button>
            <Button
              variant='text'
              sx={{ color: palette.gray1, minWidth: '46px', justifyContent: 'start' }}
              onClick={handleCloseOpenEditMode}
            >
              Cancel
            </Button>
          </>
        )}
      </Stack>
    );
  }
  if (isEdit && !readonly) {
    // edit field
    if (textFieldType === 'date') return <DatePickerRHF name={`row.${id}`} sx={{ mt: '20px' }} />;
    return <TextFieldRHF sx={{ mt: '20px' }} name={`row.${id}`} type={textFieldType} />;
  } else {
    return (
      <Typography variant='body1' sx={{ wordWrap: 'break-word' }}>
        {textFieldType === 'date'
          ? value
            ? `${dayjs(value as string).format('DD.MM.YYYY')}`
            : ''
          : String(value)}
      </Typography>
    );
  }
};
