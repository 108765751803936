import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const step1Schema = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  link: yup.string().url(MESSAGES.INCORRECT_LINK).required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  login: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  comment: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
});
