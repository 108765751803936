import { Box, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export const MenuLink = styled(Link)(({ to, theme }) => {
  const location = useLocation();

  const routeToPrefix = (to as string).slice(1).split('/')[0];
  const currentPathPrefix = location.pathname.slice(1).split('/')[0];

  const match = routeToPrefix.includes(currentPathPrefix);

  return {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: 24,
    gap: 10,
    marginBottom: 8,
    textDecoration: 'none',
    color: match ? theme.palette.blue91 : theme.palette.gray1,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    svg: {
      path: {
        fill: match ? theme.palette.blue91 : theme.palette.gray1,
      },
    },
    span: {
      marginLeft: 'auto',
      width: 3,
      height: '100%',
      background: match ? theme.palette.black1 : 'transparent',
    },
    background: match ? theme.palette.lightBlue : 'transparent',
  };
});

export const MenuWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 245,
}));
