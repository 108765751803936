import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import { API_URL, CURRENCY_OPTIONS } from 'constants/index';
import dayjs from 'dayjs';
import { GetOfferByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesOffer = (
  data?: GetOfferByIdQuery,
  userOptions?: AutocompeteOptionType[],
) => {
  if (data && data.getOfferById.additional_data) {
    const {
      title,
      city,
      date_from,
      date_to,
      order,
      currency,
      price_from,
      price_to,
      link,
      information,
      id,
      additional_data,
      preview,
      onlyForClient,
      excludeUsers,
    } = data.getOfferById;

    const {
      country,
      address,
      contact_person,
      whats_app,
      phones,
      emails,
      comment,
      id: additionalDataId,
    } = additional_data;

    return {
      title: title || '',
      country: country || '',
      city: city || '',
      address: address || '',
      dateFrom: dayjs.utc(date_from) as unknown as string,
      dateTo: dayjs.utc(date_to) as unknown as string,
      index: order || null,
      currency: currency || CURRENCY_OPTIONS[0].value,
      priceFrom: price_from ?? null,
      priceTo: price_to ?? null,
      contactPerson: contact_person || '',
      whatsapp: whats_app || '',
      phones: phones?.length
        ? phones.map((item) => ({
            phone: String(item.phone),
            id: item.id,
          }))
        : [{ phone: '' }],
      emails: emails?.length
        ? emails.map((item) => ({
            email: String(item.email),
            id: item.id,
          }))
        : [{ email: '' }],
      link: link || '',
      information: information || '',
      comment: comment || '',
      image: preview?.path
        ? {
            preview: `${API_URL}/${preview?.path}`,
          }
        : null,
      isSendNotification: false,
      onlyForClient: onlyForClient,
      additionalDataId: Number(additionalDataId) || null,
      offerId: Number(id) || null,
      users:
        userOptions?.filter((user) =>
          excludeUsers?.some((exclude) => exclude.userId === user.id),
        ) || [],
    };
  }
  return {
    title: '',
    country: '',
    city: '',
    address: '',
    dateFrom: '',
    dateTo: '',
    index: null,
    currency: CURRENCY_OPTIONS[0].value,
    priceFrom: null,
    priceTo: null,
    contactPerson: '',
    whatsapp: '',
    phones: [{ phone: '' }],
    emails: [{ email: '' }],
    link: '',
    information: '',
    comment: '',
    image: null,
    isSendNotification: false,
    onlyForClient: false,
    additionalDataId: null,
    offerId: null,
    users: [],
  };
};
