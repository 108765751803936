import { AddEditEducationFormData } from 'components/Modal/EducationModals/AddEditEducationModal/types';

export const submitEducation = (data: AddEditEducationFormData, fileIds: number[]) => {
  const { category, type, comment, educationId } = data;

  const isEdit = !!educationId;

  return {
    input: {
      type: category,
      title: type,
      fileIds: fileIds,
      comment: comment,
    },
    ...(isEdit && { updateEducationId: Number(educationId) }),
  };
};
