import { AddEditOfferFormData } from 'components/Modal/OfferModals/AddEditOfferModal/types';
import dayjs from 'dayjs';

export const submitOffer = (data: AddEditOfferFormData, imageId: number | null) => {
  const {
    address,
    city,
    comment,
    contactPerson,
    country,
    index,
    currency,
    dateFrom,
    dateTo,
    emails,
    information,
    link,
    phones,
    priceFrom,
    priceTo,
    title,
    whatsapp,
    isSendNotification,
    onlyForClient,
    additionalDataId,
    offerId,
    users,
  } = data;

  const isEdit = !!offerId;

  return isEdit
    ? {
        input: {
          offer_id: Number(offerId),
          additional_data: {
            address: address,
            comment: comment || null,
            contact_person: contactPerson,
            country: country,
            whats_app: whatsapp,
          },
          offer_data: {
            city: city,
            order: index,
            currency: currency,
            date_from: dayjs.utc(dateFrom).startOf('day').format('DD MMMM YYYY, HH:mm'),
            date_to: dayjs.utc(dateTo).endOf('day').format('DD MMMM YYYY, HH:mm'),
            information: information,
            link: link || null,
            price_from: priceFrom ?? null,
            price_to: priceTo ?? null,
            title: title,
            onlyForClient: onlyForClient,
            ...(imageId && { preview_file_id: imageId }),
          },
          additional_data_id: Number(additionalDataId),
          emails: emails?.filter((el) => el.email !== ''),
          phones: phones?.filter((el) => el.phone !== ''),
        },
        send_notification: onlyForClient ? false : isSendNotification,
        excludeForUser: !onlyForClient ? users?.map((user) => user?.id) : [],
      }
    : {
        input: {
          address: address,
          city: city,
          comment: comment || null,
          contact_person: contactPerson,
          country: country,
          order: index,
          currency: currency,
          date_from: dayjs(dateFrom).startOf('day').format('DD MMMM YYYY, HH:mm'),
          date_to: dayjs(dateTo).endOf('day').format('DD MMMM YYYY, HH:mm'),
          emails: emails?.filter((el) => el.email !== '')?.map((item) => item.email),
          information: information,
          link: link || null,
          phones: phones?.filter((el) => el.phone !== '').map((item) => item.phone),
          preview_file_id: imageId,
          price_from: priceFrom ?? null,
          price_to: priceTo ?? null,
          title: title,
          whats_app: whatsapp,
          onlyForClient: onlyForClient,
        },
        send_notification: onlyForClient ? false : isSendNotification,
        excludeForUser: !onlyForClient ? users?.map((user) => user?.id) : [],
      };
};
