import { COLUMN_IDS } from 'components/UI/TableList/constants';

export const NOTIFICATIONS_HISTORY_TABLE_OPTIONS = [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.SENDER, label: 'Sender' },
  { id: COLUMN_IDS.DATE, label: 'Date' },
  { id: COLUMN_IDS.TITLE, label: 'Title' },
  { id: COLUMN_IDS.COMMENT, label: 'Text' },
  { id: COLUMN_IDS.USERS, label: 'Users' },
  { id: COLUMN_IDS.LINK, label: 'Link', minWidth: 100 },
];
