import { Stack, Typography } from '@mui/material';
import { DatePickerRHF, DocumentsInfo, RequestHandler, TextFieldRHF } from 'components/UI';
import { defaultValuesDocument } from 'connectors/Document';
import { useModalContext } from 'context';
import { DocumentTypeEnum, useGetOneDocumentByIdQuery } from 'graphql/generated/graphql';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getDocumentType } from 'utils';

import { ViewDocumentFormData } from './types';

export const ViewDocumentModal = () => {
  const { infoModal } = useModalContext();

  const documentId = infoModal?.content?.id;

  const { data: documentData, loading: documentLoading } = useGetOneDocumentByIdQuery({
    variables: {
      getOneDocumentByIdId: documentId,
    },
    skip: !documentId,
    fetchPolicy: 'network-only',
  });

  const methods = useForm<ViewDocumentFormData>({
    defaultValues: defaultValuesDocument(documentData),
  });

  const { reset, getValues } = methods;

  const documentType = getValues('type');
  const photos = getValues('photos');

  const documentTitle = getDocumentType(documentType as DocumentTypeEnum);

  const isPassportType = documentType === DocumentTypeEnum.Passport;
  const isOwnType = documentType === DocumentTypeEnum.Own;

  useEffect(() => {
    reset(defaultValuesDocument(documentData));
  }, [documentId, documentData]);

  return (
    <RequestHandler loading={documentLoading}>
      <FormProvider {...methods}>
        <form noValidate>
          <Typography variant='h3' mb={'24px'}>
            {'View document'}
          </Typography>
          <Typography variant='h3' fontSize={'24px'} mb={'32px'}>
            {documentTitle}
          </Typography>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='category' label='Type of document' readOnly />
            <TextFieldRHF name='owner' label='Owner' readOnly />
          </Stack>
          {isOwnType ? (
            <Stack direction={'row'} gap='24px'>
              <TextFieldRHF name='title' label='Title' readOnly />
              <DatePickerRHF name='date' label='Date ' readOnly />
            </Stack>
          ) : (
            <>
              <Stack direction={'row'} gap='24px'>
                <TextFieldRHF name='country' label='Country' readOnly />
                <TextFieldRHF name='number' label='Number' readOnly />
              </Stack>
              <Stack direction={'row'} gap='24px'>
                <DatePickerRHF name='dateIssued' label='Date issued' readOnly />
                <DatePickerRHF name='dateExpiry' label='Date expiry' readOnly />
              </Stack>
              {isPassportType && (
                <Stack width={'calc(50% - 12px)'}>
                  <DatePickerRHF name='dateOfBirth' label='Date of birth' readOnly />
                </Stack>
              )}
            </>
          )}
          <Stack mb={'12px'}>
            <TextFieldRHF
              name='comment'
              label='Comment'
              multiline
              minRows={3}
              maxRows={3}
              readOnly
            />
          </Stack>
          <DocumentsInfo photos={photos} />
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
