import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { LogoutIcon, UsersIcon } from 'components/Icons';
import { useAuthContext } from 'context';
import { useCurrentUserQuery } from 'graphql/generated/graphql';
import { palette } from 'theme/foundations';

import { Logo } from '../Logo';
import { NavigationSidebar } from '../NavigationSidebar';
import { RequestHandler } from '../RequestHandler';
import { LogoWrapper, Wrapper } from './styles';

export const Sidebar = () => {
  const { signOut } = useAuthContext();

  const { data: currentUserData, loading } = useCurrentUserQuery();

  const userFullname = currentUserData?.currentUser?.full_name;

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo width={145} />
      </LogoWrapper>
      <NavigationSidebar />

      <Stack
        sx={{
          alignItems: 'center',
          mt: 'auto',
        }}
      >
        <RequestHandler loading={loading}>
          <Avatar>
            <UsersIcon size={20} fill={palette.secondary.main} />
          </Avatar>
          <Typography mt={'8px'} mb={'20px'}>
            {userFullname}
          </Typography>
          <IconButton onClick={signOut}>
            <LogoutIcon />
          </IconButton>
        </RequestHandler>
      </Stack>
    </Wrapper>
  );
};
