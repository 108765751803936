import { ERROR_CODES } from 'constants/index';

export const getErrorByCode = (errorCode: string) => {
  switch (errorCode) {
    case ERROR_CODES.INVALID_CREDENTIAL:
      return 'Wrong login or password!';
    case ERROR_CODES.USER_NOT_FOUND:
      return 'User with this email not found!';
    case ERROR_CODES.RESET_CODE_EXPIRIED:
      return 'The code is incorrect or the code entry time has expired!';
    case ERROR_CODES.RESET_CODE_ALREADY_REQUEST:
      return 'Сode has already been sent!';
    case ERROR_CODES.INVALID_DATE_RANGE:
      return 'Invalid date range!';
    case ERROR_CODES.INVALID_PRICE_RANGE:
      return 'Invalid price range!';
    case ERROR_CODES.EMAIL_ALREADY_USED:
      return 'This email is already used!';
    case ERROR_CODES.INVOICE_EXIST:
      return 'Invoice is alredy exist';
    case ERROR_CODES.ONLY_ADMIN_ALLOWED:
      return 'Only admin allowed';
    case ERROR_CODES.SUCCESS_MANAGER_NOT_FOUND:
      return 'Success Manager not found!';
    default:
      return 'An error has occurred!';
  }
};
