import { Button, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'components/Icons';
import { palette } from 'theme/foundations';

import { Wrapper } from './styles';
import { PageHeaderPropsType } from './types';

export const PageHeader = ({
  title,
  subtitle,
  children,
  isBack,
  handleClickBack,
}: PageHeaderPropsType) => {
  return (
    <Wrapper isback={String(isBack)}>
      {isBack ? (
        <Button
          startIcon={<ArrowLeftIcon fill={palette.blue91} />}
          variant='text'
          sx={{ color: palette.blue91, fontSize: '16px', fontWeight: '400' }}
          onClick={handleClickBack}
        >
          {title}
        </Button>
      ) : (
        <>
          <Typography variant={'h4'} mr={'12px'}>
            {title}
          </Typography>
          <Typography variant='body1'>{subtitle}</Typography>
        </>
      )}

      {children}
    </Wrapper>
  );
};
