import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const createNotificationSchema = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  link: yup.string().url(MESSAGES.INCORRECT_LINK).required(MESSAGES.REQUIRE_MESSAGE),
  text: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  isSendNotificationAll: yup.boolean().notRequired(),
  users: yup.array().when('isSendNotificationAll', ([isSendNotificationAll]) => {
    return !isSendNotificationAll
      ? yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string().required(),
              id: yup.number().required(),
            }),
          )
          .min(1, MESSAGES.MIN_LENGTH('1'))
      : yup.array().nullable();
  }),
});
