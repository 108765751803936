import { DocumentTypeEnum } from 'graphql/generated/graphql';

export const getDocumentType = (type: DocumentTypeEnum) => {
  switch (type) {
    case DocumentTypeEnum.Passport:
      return 'Passport';
    case DocumentTypeEnum.Visa:
      return 'Visa';
    case DocumentTypeEnum.Own:
      return 'Own document';
    default:
      return '';
  }
};
