import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { CheckboxRHF, RequestHandler, TextFieldArray, TextFieldRHF } from 'components/UI';
import { defaultValuesContactDmcs, submitContactDmcs } from 'connectors';
import { useModalContext } from 'context';
import {
  CreateContactMutationVariables,
  UpdateContactMutationVariables,
  useCreateContactMutation,
  useGetContactByIdQuery,
  useUpdateContactMutation,
} from 'graphql/generated/graphql';
import { GET_CONTACTS } from 'graphql/queries/getContacts.gql';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createEditContactDcms } from 'validation';

import { AddEditContactDcmsFormData } from './types';

export const AddEditContactDmcsModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const contactId = infoModal?.content?.id;
  const isEdit = !!contactId;

  const { data: contactData, loading: contactLoading } = useGetContactByIdQuery({
    variables: {
      getContactByIdId: contactId,
    },
    skip: !contactId,
    fetchPolicy: 'network-only',
  });

  const [createContact, { loading: createContactLoading }] = useCreateContactMutation({
    onCompleted: () => {
      toast.success('Contact created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_CONTACTS],
  });

  const [updateContact, { loading: updateContactLoading }] = useUpdateContactMutation({
    onCompleted: () => {
      toast.success('Contact updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_CONTACTS],
  });

  const methods = useForm<AddEditContactDcmsFormData>({
    defaultValues: defaultValuesContactDmcs(contactData),
    resolver: yupResolver(createEditContactDcms),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (data: AddEditContactDcmsFormData) => {
    isEdit
      ? updateContact({
          variables: {
            ...(submitContactDmcs(data) as UpdateContactMutationVariables),
          },
        })
      : createContact({
          variables: {
            ...(submitContactDmcs(data) as CreateContactMutationVariables),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesContactDmcs(contactData));
  }, [contactId, contactData]);

  return (
    <RequestHandler loading={contactLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant='h3' mb={'24px'}>
            {!isEdit ? 'Add new contact' : 'Edit contact'}
          </Typography>
          <Typography variant='h3' fontSize={'24px'} mb={'32px'}>
            {"DMC's"}
          </Typography>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='title' label='Title' placeholder='Enter title' />
            <TextFieldRHF name='country' label='Country' placeholder='Enter country' />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='city' label='City' placeholder='Enter city' subLabel='Optional' />
            <TextFieldRHF
              name='address'
              label='Address'
              placeholder='Enter address'
              subLabel='Optional'
            />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='company' label='Company' placeholder='Enter company' />
            <TextFieldRHF
              name='profit'
              label='Profit'
              placeholder='Enter % profit'
              subLabel='Optional'
              type='number'
            />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF
              name='contactPerson'
              label='Contact person'
              placeholder='Enter contact person'
            />
            <TextFieldRHF
              name='whatsapp'
              label='WhatsApp'
              placeholder='Enter WhatsApp number'
              subLabel='Optional'
            />
          </Stack>
          <Stack direction={'row'} gap='24px' mb={'20px'}>
            <TextFieldArray
              parentName='phones'
              childrenName='phone'
              label='Phone number'
              placeholder='Enter mobile number'
              actionText='Add phone number'
            />
            <TextFieldArray
              parentName='emails'
              childrenName='email'
              label='Email'
              placeholder='Enter email'
              actionText='Add email'
            />
          </Stack>
          <Stack>
            <TextFieldRHF
              name='comment'
              label='Comment'
              subLabel='Optional'
              placeholder='Enter comment'
              multiline
              minRows={3}
              maxRows={3}
            />
            <CheckboxRHF name='isSendNotification' label='Send notification for all users' />
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createContactLoading || updateContactLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
