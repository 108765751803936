import { AddEditContactOtherFormData } from 'components/Modal/ContactModals/AddEditContactOtherModal/types';
import { ContactTypesEnum } from 'graphql/generated/graphql';

export const submitContactOther = (data: AddEditContactOtherFormData) => {
  const {
    title,
    country,
    city,
    address,
    company,
    profit,
    commission,
    contactPerson,
    whatsapp,
    phones,
    emails,
    comment,
    additionalDataId,
    contactId,
  } = data;

  const isEdit = !!contactId;

  return isEdit
    ? {
        input: {
          additional_data: {
            address: address,
            comment: comment,
            contact_person: contactPerson,
            country: country,
            whats_app: whatsapp,
          },
          additional_data_id: Number(additionalDataId),
          contact_data: {
            city: city,
            title: title,
            type: ContactTypesEnum.Other,
            company: company,
            commission: commission,
            profit: profit ? Number(profit) : null,
          },
          contact_id: Number(contactId),
          emails: emails,
          phones: phones,
        },
      }
    : {
        input: {
          title: title,
          country: country,
          city: city,
          address: address,
          contact_person: contactPerson,
          whats_app: whatsapp,
          phones: phones?.map((item) => item.phone),
          emails: emails?.map((item) => item.email),
          comment: comment,
          type: ContactTypesEnum.Other,
          company: company,
          commission: commission,
          profit: profit ? Number(profit) : null,
        },
      };
};
