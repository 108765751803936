import { COLUMN_IDS } from 'components/UI/TableList/constants';

export const ACCOUNTS_TABLE_OPTIONS = [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title' },
  { id: COLUMN_IDS.LOGIN, label: 'Login' },
  { id: COLUMN_IDS.PASSWORD, label: 'Password' },
  { id: COLUMN_IDS.COMMENT, label: 'Comment' },
  { id: COLUMN_IDS.LINK, label: 'Link', minWidth: 100 },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];
