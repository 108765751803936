import { gql } from '@apollo/client';

export const GET_SECTIONS = gql`
  query getSections {
    getSections {
      id
      name
    }
  }
`;
