import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_ID = gql`
  query getAccountById($getAccountByIdId: Int!) {
    getAccountById(id: $getAccountByIdId) {
      comment
      id
      link
      login
      password
      title
      users {
        id
        full_name
        avatar {
          id
          mimetype
          path
          size
        }
      }
    }
  }
`;
