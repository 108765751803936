import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const GBPIcon = ({ fill = palette.black50 }: PropsType) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5 20.5C18.5 20.5 8 20.5 6.5 20.5C6.72318 20.5 9.5 19.5 9.5 16.5C9.5 15.4272 9.5 14.3384 9.5 12.5M17.6177 7.5C17.1029 6.54191 15.917 4.5 13.417 4.5C10.917 4.5 9.5 6.5 9.5 9.5C9.5 10.0696 9.5 11.7679 9.5 12.5M9.5 12.5H6.5M9.5 12.5H14.5'
        stroke={fill}
        strokeWidth='2'
      />
    </svg>
  );
};
