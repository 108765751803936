import { gql } from '@apollo/client';

export const GET_SUCCESS_MANAGERS = gql`
  query getSuccessManagerNames {
    getSuccessManagerNames {
      id
      name
    }
  }
`;
