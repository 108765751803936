import { ListItemIcon, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DotsIcon } from 'components/Icons';
import { useState } from 'react';
import { palette } from 'theme/foundations';

import { TableRowActionMenuType } from './types';

export const TableRowActionMenu = ({ options }: TableRowActionMenuType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <DotsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          root: { sx: { '.MuiList-root': { padding: 0 } } },
          paper: {
            style: {
              width: 160,
              marginLeft: -28,
            },
          },
        }}
      >
        {options.map(({ Icon, label, action }) => (
          <MenuItem
            key={label}
            onClick={() => {
              action();
              handleClose();
            }}
            sx={{
              padding: '9px 16px',
              '&:hover': {
                background: palette.lightBlue,
              },
            }}
          >
            <Typography variant='body1' color={palette.blue91} mr={'auto'}>
              {label}
            </Typography>
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>{Icon}</ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
