import {
  GetOneDocumentByIdQuery,
  OwnDocumentObject,
  PassportDocumentObject,
  VisaDocumentObject,
} from 'graphql/generated/graphql';
import { getDocumentType } from 'utils';

export const defaultValuesDocument = (data?: GetOneDocumentByIdQuery) => {
  if (data && data.getOneDocumentById) {
    const {
      type,
      birthDate,
      comment,
      country,
      expiry,
      issued,
      owner,
      serialNumber,
      title,
      date,
      photos,
    } = data.getOneDocumentById as OwnDocumentObject & PassportDocumentObject & VisaDocumentObject;

    return {
      type: type,
      category: getDocumentType(type),
      owner: owner || '',
      country: country || '',
      number: serialNumber || '',
      dateIssued: issued || '',
      dateExpiry: expiry || '',
      dateOfBirth: birthDate || '',
      comment: comment || '',
      title: title || '',
      date: date || '',
      photos: photos || [],
    };
  }

  return {
    type: '',
    category: '',
    owner: '',
    country: '',
    number: '',
    dateIssued: '',
    dateExpiry: '',
    dateOfBirth: '',
    comment: '',
    title: '',
    date: '',
    photos: [],
  };
};
