import { Box, Stack, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0 40px',
  borderRight: `1px solid ${theme.palette.gray5}`,
  zIndex: 1000,
}));

export const LogoWrapper = styled(Stack)(() => ({
  justifyContent: 'center',
  flexDirection: 'row',
  marginBottom: 32,
}));
