import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useModalContext } from 'context';
import { useDeleteAccountMutation } from 'graphql/generated/graphql';
import { GET_ACCOUNTS } from 'graphql/queries';
import { toast } from 'react-toastify';

import { ActionsWrapper } from './styles';

export const ConfirmDeleteAccountModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const accountId = infoModal?.content?.id;
  const accountTitle = infoModal?.content?.title;

  const [deleteAccount, { loading: deleteAccountLoading }] = useDeleteAccountMutation({
    onCompleted: () => {
      toast.success('Account deleted successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_ACCOUNTS],
  });

  const onSubmit = () => {
    deleteAccount({
      variables: {
        deleteAccountId: accountId,
      },
    });
  };

  return (
    <Stack gap={'24px'}>
      <Typography variant='h4' lineHeight={'32px'}>
        Are you sure delete “{accountTitle}”?
      </Typography>
      <ActionsWrapper>
        <Button sx={{ maxWidth: '75px', padding: '4px 15px' }} onClick={onCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          variant='outlined'
          sx={{ maxWidth: '75px', padding: '4px 15px' }}
          loading={deleteAccountLoading}
          onClick={onSubmit}
        >
          Delete
        </LoadingButton>
      </ActionsWrapper>
    </Stack>
  );
};
