import { gql } from '@apollo/client';

export const EARNED_PAID_BY_USER = gql`
  query getEarnedPaidDataByUser($userId: Int!) {
    getEarnedPaidDataByUser(userId: $userId) {
      EUR
      GBP
      USD
    }
  }
`;
