import { Button, Pagination, Stack } from '@mui/material';
import {
  DeleteIcon,
  EditIcon,
  PageContentWrapper,
  PageHeader,
  PlusIcon,
  RequestHandler,
  TableList,
} from 'components';
import {
  COLUMN_IDS,
  TABLE_SORT_DIRECTIONS,
  TABLE_SORT_FIELDS,
} from 'components/UI/TableList/constants';
import { TableSortType } from 'components/UI/TableList/types';
import { MODAL_NAME, ROUTES, TABLE_PAGE_LIMIT } from 'constants/index';
import { useModalContext } from 'context';
import {
  useGetUsersQuery,
  UserObject,
  UserRoleEnum,
  UserSortVariantsEnum,
} from 'graphql/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItemDataForUsersTable, getSortDirectionValue, getUserRoleEnum } from 'utils';

import { AddButton } from './styles';

export const Users = () => {
  // Create data for table row
  const createData = (user: UserObject) => {
    const userData = {
      ...getItemDataForUsersTable(user),
      [COLUMN_IDS.ACTION]: [
        {
          label: 'Edit',
          Icon: <EditIcon />,
          action: () => {
            handleEditUser(Number(user.id));
          },
        },
        {
          label: 'Delete',
          Icon: <DeleteIcon />,
          action: () => {
            handleDeleteUser(Number(user.id), String(user.full_name));
          },
        },
      ].filter((action) => user?.role !== UserRoleEnum.Customer || action.label !== 'Edit'),
    };

    return userData;
  };

  const { onOpenModal } = useModalContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [sortInfo, setSortInfo] = useState<TableSortType | undefined>({
    direction: TABLE_SORT_DIRECTIONS.ASC,
    field: TABLE_SORT_FIELDS.FULL_NAME,
  });

  const { data, loading } = useGetUsersQuery({
    variables: {
      input: {
        limit: TABLE_PAGE_LIMIT,
        offset: (page - 1) * TABLE_PAGE_LIMIT,
        ...(sortInfo?.field
          ? {
              sort: getSortDirectionValue(sortInfo.direction),
              sortBy:
                sortInfo?.field === TABLE_SORT_FIELDS.FULL_NAME
                  ? UserSortVariantsEnum.FullName
                  : UserSortVariantsEnum.Role,
            }
          : {}),
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddUser = () => {
    onOpenModal(MODAL_NAME.ADD_EDIT_USER);
  };

  const handleEditUser = (id: number) => {
    onOpenModal(MODAL_NAME.ADD_EDIT_USER, { id });
  };

  const handleDeleteUser = (id: number, username?: string) => {
    onOpenModal(MODAL_NAME.DELETE_USER, {
      id,
      username,
    });
  };

  const handleSendNotification = () => {
    onOpenModal(MODAL_NAME.ADD_NOTIFICATIONS);
  };

  const handleChangeSort = (field: TABLE_SORT_FIELDS) => {
    setSortInfo((prev) => ({
      field: field,
      direction:
        prev?.field === field
          ? prev.direction === TABLE_SORT_DIRECTIONS.ASC
            ? TABLE_SORT_DIRECTIONS.DESC
            : TABLE_SORT_DIRECTIONS.ASC
          : TABLE_SORT_DIRECTIONS.ASC,
    }));
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleRow = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: Maybe<number>,
    role?: string,
  ) => {
    const target = event.target as HTMLElement;

    if (
      target.classList.contains('MuiBackdrop-root') || // action list overlay
      target.closest('.MuiList-root') || // action list
      target.closest('.MuiButtonBase-root') || // open action list button
      getUserRoleEnum(role) === UserRoleEnum.Customer // user role is Client
    ) {
      return;
    }
    navigate(`${ROUTES.users}/${id}`);
  };

  const USERS_TABLE_OPTIONS = [
    { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
    { id: COLUMN_IDS.FULL_NAME, label: 'Full name', handleChangeSort },
    { id: COLUMN_IDS.EMAIL, label: 'Email for login in app' },
    { id: COLUMN_IDS.ROLE, label: 'Role', handleChangeSort },
    { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
    { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
  ];

  const users = data?.getUsers?.rows;
  const allUsersCount = data?.getUsers.count;

  const rows = users?.map((user) => createData(user));
  const pageCount = allUsersCount ? Math.ceil(allUsersCount / TABLE_PAGE_LIMIT) : 0;

  useEffect(() => {
    if (page > 1 && rows && !rows?.length) {
      setPage((prev) => prev - 1);
    }
  }, [rows]);

  return (
    <RequestHandler loading={loading}>
      <PageHeader title='List of users' subtitle={`${allUsersCount} users`}>
        <Button
          variant='outlined'
          onClick={handleSendNotification}
          sx={{ width: '200px', marginLeft: 'auto' }}
        >
          Send notification
        </Button>
      </PageHeader>
      <PageContentWrapper>
        <AddButton startIcon={<PlusIcon />} onClick={handleAddUser} sx={{ mb: '16px' }}>
          Add new user
        </AddButton>

        <TableList
          headCells={USERS_TABLE_OPTIONS}
          handleRow={handleRow}
          isLoading={loading}
          rows={rows}
          sortInfo={sortInfo}
        />

        <Stack mt={'16px'} direction={'row'} justifyContent={'flex-end'}>
          <Pagination count={pageCount} page={page} onChange={handlePaginationChange} />
        </Stack>
      </PageContentWrapper>
    </RequestHandler>
  );
};
