import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowUpIcon, PlusIcon } from 'components/Icons';
import { CurrencyEnum } from 'graphql/generated/graphql';
import { useState } from 'react';
import { palette } from 'theme/foundations';

import { TableList } from '../TableList';
import { COLUMN_IDS, TABLE_CONTENT_TYPE } from '../TableList/constants';
import { TableRowType } from '../TableList/types';
import {
  getDefaultEarnedRow,
  getDefaultPaidRow,
  getLastEarnedRow,
  getLastPaidRow,
} from '../UserPayment/constants';
import { ACCORDION_CHILDREN_VARIANTS } from './constants';
import { AccordionListType, CustomAccordionType } from './types';

export const AccordionList = ({ options }: AccordionListType) => {
  return (
    <>
      {options.map((accordion) => (
        <CustomAccordion accordion={accordion} key={accordion.title} />
      ))}
    </>
  );
};

export const CustomAccordion = ({ accordion }: CustomAccordionType) => {
  const [rowsState, setRowsState] = useState(accordion.children?.rows as Array<TableRowType>);
  const isEarnedContentType = accordion.children?.contentType === TABLE_CONTENT_TYPE.EARNED;
  const lastRow = (isEarnedContentType ? getLastEarnedRow : getLastPaidRow)({ rows: rowsState });

  const handleAddNewRow = () => {
    setRowsState((prev) => [
      ...prev,
      {
        ...(isEarnedContentType ? getDefaultEarnedRow : getDefaultPaidRow)({
          currency: accordion?.children?.currency as CurrencyEnum,
          userId: accordion?.children?.userId,
          userProfit: accordion?.children?.userProfit,
        }),
      },
    ]);
  };

  const accardionTitle = accordion?.children?.currency
    ? `${accordion?.children?.currency} ${
        lastRow?.[isEarnedContentType ? COLUMN_IDS.USER_PROFIT_VALUE : COLUMN_IDS.AMOUNT]
      }`
    : accordion.title;

  // add table logic
  const isChildrenTypeAccordion =
    accordion?.children?.type === ACCORDION_CHILDREN_VARIANTS.ACCORDION;

  return (
    <Accordion key={accordion.title}>
      <AccordionSummary
        style={{
          backgroundColor: isChildrenTypeAccordion ? palette.lightBlue : palette.blue60,
        }}
        expandIcon={
          <IconButton sx={{ transform: 'rotate(180deg)' }}>
            <ArrowUpIcon fill={isChildrenTypeAccordion ? palette.black85 : palette.blue91} />
          </IconButton>
        }
      >
        <Typography
          fontSize={isChildrenTypeAccordion ? 16 : 14}
          fontWeight={500}
          color={isChildrenTypeAccordion ? palette.black85 : palette.blue91}
        >
          {accardionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {accordion.children.type === ACCORDION_CHILDREN_VARIANTS.ACCORDION ? (
          <AccordionList options={accordion.children.options} />
        ) : (
          <>
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <IconButton sx={{ mr: '10px', mb: '10px' }} onClick={handleAddNewRow}>
                <PlusIcon fill={palette.blue91} size={20} />
              </IconButton>
            </Stack>

            <Box sx={{ maxWidth: 'calc(100vw - 379px)' }}>
              <TableList
                tableContainerSx={{ height: 'auto' }}
                tableSx={{ width: 'calc(100vw - 379px)' }}
                isLoading={false}
                isEditable
                contentType={accordion.children.contentType}
                headCells={accordion.children.headCells}
                rows={[...rowsState, lastRow]}
                setTableRows={setRowsState}
              />
            </Box>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
