import { Pagination, Stack } from '@mui/material';
import { DeleteIcon, EditIcon, PlusIcon } from 'components/Icons';
import { PageContentWrapper, PageHeader, RequestHandler, TableList } from 'components/UI';
import {
  COLUMN_IDS,
  TABLE_SORT_DIRECTIONS,
  TABLE_SORT_FIELDS,
} from 'components/UI/TableList/constants';
import { TableSortType } from 'components/UI/TableList/types';
import { MODAL_NAME, TABLE_PAGE_LIMIT } from 'constants/index';
import { useModalContext } from 'context';
import { FieldsNames, OfferObject, useGetOffersQuery } from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import { getItemDataForOffersTable, getSortDirectionValue, getSortFieldValue } from 'utils';

import { AddButton } from './styles';

export const SpecialOffers = () => {
  // Create data for table row
  const createData = (offer: OfferObject) => {
    const offerData = {
      ...getItemDataForOffersTable(offer),
      [COLUMN_IDS.ACTION]: [
        { label: 'Edit', Icon: <EditIcon />, action: () => handleEditOffer(Number(offer.id)) },
        {
          label: 'Delete',
          Icon: <DeleteIcon />,
          action: () => handleDeleteOffer(Number(offer.id), String(offer.title)),
        },
      ],
    };

    return offerData;
  };

  const { onOpenModal } = useModalContext();

  const [page, setPage] = useState(1);
  const [sortInfo, setSortInfo] = useState<TableSortType | undefined>({
    direction: TABLE_SORT_DIRECTIONS.ASC,
    field: null,
  });

  const { data, loading } = useGetOffersQuery({
    variables: {
      input: {
        limit: TABLE_PAGE_LIMIT,
        offset: (page - 1) * TABLE_PAGE_LIMIT,
        ...(sortInfo?.field
          ? {
              field: getSortFieldValue(sortInfo.field) as FieldsNames,
              sort: getSortDirectionValue(sortInfo.direction),
            }
          : {}),
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddOffer = () => {
    onOpenModal(MODAL_NAME.ADD_EDIT_OFFER);
  };

  const handleEditOffer = (id: number) => {
    onOpenModal(MODAL_NAME.ADD_EDIT_OFFER, { id });
  };

  const handleDeleteOffer = (id: number, title: string) => {
    onOpenModal(MODAL_NAME.DELETE_OFFER, { id, title });
  };

  const handleChangeSort = (field: TABLE_SORT_FIELDS) => {
    setSortInfo((prev) => ({
      field: field,
      direction:
        prev?.field === field
          ? prev.direction === TABLE_SORT_DIRECTIONS.ASC
            ? TABLE_SORT_DIRECTIONS.DESC
            : TABLE_SORT_DIRECTIONS.ASC
          : TABLE_SORT_DIRECTIONS.ASC,
    }));
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const OFFERS_TABLE_OPTIONS = [
    { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
    { id: COLUMN_IDS.TITLE, label: 'Title' },
    { id: COLUMN_IDS.LOCATION, label: 'Location' },
    { id: COLUMN_IDS.DATE, label: 'Date', handleChangeSort },
    { id: COLUMN_IDS.PRICE, label: 'Price' },
    { id: COLUMN_IDS.CONTACT_PERSON, label: 'Contact person' },
    { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
    { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
  ];

  const offers = data?.getOffers?.rows;
  const allOffersCount = data?.getOffers.count;

  const rows = offers?.map((offer) => createData(offer));
  const pageCount = allOffersCount ? Math.ceil(allOffersCount / TABLE_PAGE_LIMIT) : 0;

  useEffect(() => {
    if (page > 1 && rows && !rows?.length) {
      setPage((prev) => prev - 1);
    }
  }, [rows]);

  return (
    <RequestHandler loading={loading}>
      <PageHeader title='Special offers' subtitle={`${allOffersCount} numbers`} />
      <PageContentWrapper>
        <AddButton startIcon={<PlusIcon />} onClick={handleAddOffer} sx={{ mb: '16px' }}>
          Add new special offer
        </AddButton>

        <TableList
          headCells={OFFERS_TABLE_OPTIONS}
          isLoading={loading}
          rows={rows}
          sortInfo={sortInfo}
        />

        <Stack mt={'16px'} direction={'row'} justifyContent={'flex-end'}>
          <Pagination count={pageCount} page={page} onChange={handlePaginationChange} />
        </Stack>
      </PageContentWrapper>
    </RequestHandler>
  );
};
