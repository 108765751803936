import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { TextFieldRHF } from 'components';
import { LS, ROUTES } from 'constants/index';
import { useAuthContext } from 'context';
import { useSignInLazyQuery } from 'graphql/generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { loginSchema } from 'validation';

import { LoginFormData } from './types';

export const Login = () => {
  const navigate = useNavigate();
  const { signIn } = useAuthContext();

  const [signInQuery, { loading }] = useSignInLazyQuery();

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (data: LoginFormData) => {
    const { email, password } = data;

    signInQuery({
      variables: {
        email,
        password,
      },
      onCompleted: (data) => {
        signIn();
        localStorage.removeItem(LS.TEMP_TOKEN);
        localStorage.setItem(LS.TOKEN, data.signIn);
      },
    });

    reset();
  };

  const handleGoForgotPassword = () => {
    navigate(ROUTES.forgotPassword);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h2' mb={'32px'} textAlign='center'>
          Login
        </Typography>
        <TextFieldRHF name='email' placeholder='Enter your email' label='Email' />
        <TextFieldRHF
          name='password'
          placeholder='Enter your password'
          label='Password'
          type='password'
        />
        <Button variant='text' sx={{ marginBottom: '32px' }} onClick={handleGoForgotPassword}>
          Forgot password?
        </Button>
        <LoadingButton type='submit' loading={loading} variant='contained'>
          Login
        </LoadingButton>
      </form>
    </FormProvider>
  );
};
