import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthContextProvider, ModalProvider } from 'context';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { client } from 'graphql/client';
import { ToastContainer } from 'react-toastify';
import { RouterWrapper } from 'router';
import { theme } from 'theme/theme';

dayjs.extend(utc);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <ModalProvider>
            <RouterWrapper />
            <ToastContainer newestOnTop />
          </ModalProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
