import { AddEditMembershipFormData } from 'components/Modal/MembershipModals/AddEditMembershipModal/types';

export const submitMembership = (data: AddEditMembershipFormData) => {
  const { title, brand, participating, benefits, usage, link, comment, membershipId } = data;

  const isEdit = !!membershipId;

  return {
    input: {
      brand: brand,
      comment: comment,
      exclusiveBenefits: benefits,
      howToUse: usage,
      link: link,
      participating: participating,
      title: title,
    },
    ...(isEdit && { updateMembershipId: membershipId }),
  };
};
