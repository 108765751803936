import { Box, BoxProps, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

interface WrapperProps extends BoxProps {
  height?: string;
}

export const Wrapper = styled(Box)<WrapperProps>(({ height }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: height,
    width: 'auto',
    flex: 1,
  };
});

export const ErrorMessageWrapper = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});
