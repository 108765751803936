import { UserRoleEnum } from 'graphql/generated/graphql';

export const getUserRole = (role: UserRoleEnum) => {
  switch (role) {
    case UserRoleEnum.Admin:
      return 'Admin';
    case UserRoleEnum.Manager:
      return 'Manager';
    case UserRoleEnum.Customer:
      return 'Client';
    default:
      return '';
  }
};

export const getUserRoleEnum = (role?: string) => {
  switch (role) {
    case 'Admin':
      return UserRoleEnum.Admin;
    case 'Manager':
      return UserRoleEnum.Manager;
    case 'Client':
      return UserRoleEnum.Customer;
    default:
      return '';
  }
};
