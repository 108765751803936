import {
  AccountsIcon,
  ContactsIcon,
  EducationIcon,
  HistoryIcon,
  MembershipIcon,
  SpecialOffersIcon,
  UsersIcon,
} from 'components/Icons';
import { ROUTES } from 'constants/index';

export const SIDEBAR_NAVIGATION_OPTIONS = [
  {
    id: '1',
    route: ROUTES.specialOffers,
    label: 'Special offers',
    Icon: SpecialOffersIcon,
  },
  {
    id: '2',
    route: ROUTES.contacts,
    label: 'Contacts',
    Icon: ContactsIcon,
  },
  {
    id: '3',
    route: ROUTES.membership,
    label: 'Membership',
    Icon: MembershipIcon,
  },
  {
    id: '4',
    route: ROUTES.accounts,
    label: 'Accounts',
    Icon: AccountsIcon,
  },
  {
    id: '5',
    route: ROUTES.users,
    label: 'List of users',
    Icon: UsersIcon,
  },
  {
    id: '6',
    route: ROUTES.education,
    label: 'Education',
    Icon: EducationIcon,
  },
  {
    id: '7',
    route: ROUTES.notificationsHistory,
    label: 'Notifications history',
    Icon: HistoryIcon,
  },
];
