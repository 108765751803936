import { Stack, styled } from '@mui/material';
import { palette } from 'theme/foundations';

export const UserItem = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '8px',
  background: palette.primary.main,
  borderRadius: '2px',
  alignItems: 'center',
  padding: '8px 12px',
  width: 'calc(50% - 12px)',
}));
