import { Box } from '@mui/material';
import { PageHeader } from 'components/UI';

export const Home = () => {
  return (
    <Box>
      <PageHeader title='Title' subtitle='subtitle' />
    </Box>
  );
};
