import { Box, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { getFieldName } from 'utils';

import { DatePickerRHFPropsType } from './types';

export const DatePickerRHF = ({ name, label, mb, sx, ...rest }: DatePickerRHFPropsType) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  const errorMessage = hasError?.message;

  const handleClear = () => {
    setValue(name, null);
  };

  return (
    <Stack mb={mb} sx={sx} width={'100%'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                {label && (
                  <Box mb='8px' display={'flex'}>
                    <Typography variant='body1'>{label}</Typography>
                  </Box>
                )}
                <DatePicker
                  value={value ? dayjs(value) : null}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      error: !!hasError,
                    },
                    field: {
                      clearable: true,
                      onClear: handleClear,
                    },
                  }}
                  format='DD.MM.YYYY'
                  {...rest}
                />
                <Box height={20}>
                  {errorMessage && <Typography variant='error'>{String(errorMessage)}</Typography>}
                </Box>
              </>
            );
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
};
