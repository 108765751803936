import { Box, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Controller, useFormContext } from 'react-hook-form';

export const OTPInput = ({ name }: { name: string }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorText = errors[name]?.message as string;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box>
            <MuiOtpInput
              sx={{
                gap: '16px',
              }}
              {...field}
              TextFieldsProps={() => ({ placeholder: '-', error: !!errorText, type: 'number' })}
              length={4}
            />
            <Box height={20}>
              {fieldState.invalid && <Typography variant='error'>{errorText}</Typography>}
            </Box>
          </Box>
        );
      }}
    />
  );
};
