import { Step1, Step2 } from 'components/Modal/AccountModals/AddEditAccountModal/Steps';
import { Step1FormData } from 'components/Modal/AccountModals/AddEditAccountModal/types';
import { GetAccountByIdQuery } from 'graphql/generated/graphql';

export type CurrentAccountStepType = {
  step: number;
  handleNextStep: (data: Step1FormData) => void;
  stepValue: Step1FormData;
  accountData?: GetAccountByIdQuery;
};

export const getCurrentAccountStep = ({
  step,
  handleNextStep,
  stepValue,
  accountData,
}: CurrentAccountStepType) => {
  switch (step) {
    case 1:
      return <Step1 handleNextStep={handleNextStep} accountData={accountData} />;
    case 2:
      return <Step2 stepValue={stepValue} accountData={accountData} />;
    default:
      return null;
  }
};
