import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { TextFieldRHF } from 'components';
import { ROUTES } from 'constants/index';
import { useSentForgotPasswordResetKeyLazyQuery } from 'graphql/generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { resetPasswordSchema } from 'validation';

import { ForgotPasswordFormData } from './types';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const { handleSubmit, reset } = methods;

  const [forgotPassword, { loading }] = useSentForgotPasswordResetKeyLazyQuery();

  const onSubmit = (data: ForgotPasswordFormData) => {
    const { email } = data;

    forgotPassword({
      variables: {
        email,
      },
      onCompleted: () => {
        navigate(ROUTES.verifyEmail, {
          state: {
            email,
          },
        });
      },
      fetchPolicy: 'network-only',
    });

    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h2' mb={'12px'} textAlign='center'>
          Forgot password
        </Typography>
        <Typography variant='body2' mb={'32px'}>
          Enter the email address associated with your account
        </Typography>
        <TextFieldRHF name='email' placeholder='Enter your email' label='Email' mb='12px' />
        <LoadingButton type='submit' variant='contained' loading={loading}>
          Reset password
        </LoadingButton>
      </form>
    </FormProvider>
  );
};
