import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { OTPInput } from 'components';
import { LS, ROUTES } from 'constants/index';
import {
  useRequestChangePasswordTokenMutation,
  useSentForgotPasswordResetKeyLazyQuery,
} from 'graphql/generated/graphql';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { palette } from 'theme/foundations';
import { verifyEmailSchema } from 'validation/schema/verifyEmail.schema';

import { VerifyEmailFormData } from './types';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const email = location?.state?.email;

  const methods = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(verifyEmailSchema),
  });

  const { handleSubmit, reset } = methods;

  const [verifyEmail, { loading }] = useRequestChangePasswordTokenMutation();
  const [forgotPassword] = useSentForgotPasswordResetKeyLazyQuery();

  const onSubmit = (data: VerifyEmailFormData) => {
    const { code } = data;
    verifyEmail({
      variables: {
        code: +code,
      },
      onCompleted: (data) => {
        localStorage.setItem(LS.TEMP_TOKEN, data.requestChangePasswordToken);
        navigate(ROUTES.newPassword);
      },
    });

    reset();
  };

  const getNewCode = (email: string) => {
    forgotPassword({
      variables: {
        email,
      },
      onCompleted: () => {
        toast.success('Сode sent successfully', {
          position: 'bottom-right',
        });
      },
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.forgotPassword);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h2' mb={'12px'} textAlign='center'>
          Verify your email
        </Typography>
        <Typography variant='body2' mb={'32px'}>
          Please enter the 4 digit code sent to your email
        </Typography>
        <OTPInput name='code' />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='text'
            sx={{ marginBottom: '44px', color: palette.black85 }}
            onClick={() => getNewCode(email)}
          >
            Resend code
          </Button>
        </Box>
        <LoadingButton type='submit' loading={loading} variant='contained'>
          Reset password
        </LoadingButton>
      </form>
    </FormProvider>
  );
};
