import { Button, Stack } from '@mui/material';
import { DeleteIcon, PlusIcon } from 'components/Icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { palette } from 'theme/foundations';

import { TextFieldRHF } from '../TextFieldRHF';
import { RemoveButton } from './styles';
import { TextFieldArrayType } from './types';

export const TextFieldArray = ({
  parentName,
  childrenName,
  label,
  placeholder,
  actionText,
  subLabel,
}: TextFieldArrayType) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: parentName,
  });

  const handleAppend = () => {
    append({ [childrenName]: '' });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Stack width={'100%'}>
      {fields.map((field, index) => {
        const isFirstElement = !index;
        return (
          <Stack direction={'row'} gap='8px' alignItems={'center'} key={field.id}>
            <TextFieldRHF
              name={`${parentName}.${index}.${childrenName}`}
              label={label}
              placeholder={placeholder}
              subLabel={subLabel}
            />
            {!isFirstElement && (
              <RemoveButton
                issublabel={subLabel ? 1 : 0}
                variant='text'
                onClick={() => handleRemove(index)}
                startIcon={<DeleteIcon fill={palette.blue90} />}
              />
            )}
          </Stack>
        );
      })}
      <Button variant='text' startIcon={<PlusIcon fill={palette.blue90} />} onClick={handleAppend}>
        {actionText}
      </Button>
    </Stack>
  );
};
