import { GetMembershipByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesMembership = (data?: GetMembershipByIdQuery) => {
  if (data && data.getMembershipById) {
    const { title, brand, participating, exclusiveBenefits, howToUse, link, comment, id } =
      data.getMembershipById;

    return {
      title: title || '',
      brand: brand || '',
      participating: participating || '',
      benefits: exclusiveBenefits || '',
      usage: howToUse || '',
      link: link || '',
      comment: comment || '',
      membershipId: Number(id) || null,
    };
  }

  return {
    title: '',
    brand: '',
    participating: '',
    benefits: '',
    usage: '',
    link: '',
    comment: '',
  };
};
