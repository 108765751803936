import { Button, Stack, Typography } from '@mui/material';
import { SuccessIcon } from 'components/Icons';
import { useModalContext } from 'context';

import { ActionsWrapper } from './styles';

export const NotificationSentSuccessModal = () => {
  const { onCloseModal } = useModalContext();

  return (
    <Stack gap={'24px'}>
      <Stack direction={'row'} gap='16px' maxWidth={'352px'}>
        <SuccessIcon />
        <Typography variant='h5'>Notification sent to users</Typography>
      </Stack>
      <ActionsWrapper>
        <Button sx={{ maxWidth: '64px', padding: '4px 15px' }} onClick={onCloseModal}>
          Okey
        </Button>
      </ActionsWrapper>
    </Stack>
  );
};
