import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const USDIcon = ({ fill = palette.black50 }: PropsType) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 16.5C8.5 17.5 10.5 18.5 12.5 18.5M12.5 18.5C15 18.5 17.5 18 17.5 15.5C17.5 13 15.0495 12.5 12.5 12.5M12.5 18.5V12.5M12.5 18.5V21.5M12.5 12.5C10 12.5 7.5 12 7.5 9.5C7.5 7 10 6.5 12.5 6.5M12.5 12.5V6.5M12.5 6.5C14.5 6.5 16.5 7.5 17.5 8.5M12.5 6.5V3.5'
        stroke={fill}
        strokeWidth='2'
      />
    </svg>
  );
};
