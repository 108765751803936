import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const HistoryIcon = ({ fill = palette.gray60 }: PropsType) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12H0l4 4 4-4H4c0-4.42 3.58-8 8-8s8 3.58 8 8-3.58 8-8 8c-1.77 0-3.42-.57-4.76-1.52l-1.42 1.42C7.42 21.51 9.66 22 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2zm-1 5v6l5.25 3.15.75-1.23-4.5-2.67V7H11z'
        fill={fill}
      />
    </svg>
  );
};
