import axios from 'axios';
import { API_URL, BREACKPOINTS } from 'constants/index';

import { FilesType } from './types';

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => Promise.reject(error),
);

export const uploadFiles = (data: FormData): Promise<{ files: FilesType[] }> => {
  return instance.post(BREACKPOINTS.uploadFiles, data);
};
