import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { AutocompeteRHF, RequestHandler, UserItemAutocomplete } from 'components/UI';
import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import { defaultValuesStep2, getUsersOptions, submitAccount } from 'connectors';
import { useModalContext } from 'context';
import {
  GetAccountByIdQuery,
  UpdateAccountMutationVariables,
  useCreateAccountMutation,
  useGetUsersForAccountsQuery,
  useUpdateAccountMutation,
} from 'graphql/generated/graphql';
import { GET_ACCOUNTS } from 'graphql/queries';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { step2Schema } from 'validation';

import { ConfirmDeleteUserModal } from '../../ConfirmDeleteUserModal';
import { Step1FormData, Step2FormData } from '../types';

export const Step2 = ({
  stepValue,
  accountData,
}: {
  stepValue: Step1FormData;
  accountData?: GetAccountByIdQuery;
}) => {
  const { onCloseModal, infoModal } = useModalContext();

  const [infoModalUser, setInfoModalUser] = useState({
    open: false,
    content: {},
  });

  const onOpenUserModal = (content: Record<any, any> = {}) => {
    setInfoModalUser({ content, open: true });
  };

  const onCloseUserModal = () => {
    setInfoModalUser({ content: {}, open: false });
  };

  const accountId = infoModal?.content?.id;
  const isEdit = !!accountId;

  const [createAccount, { loading: createAccountLoading }] = useCreateAccountMutation({
    onCompleted: () => {
      toast.success('Account created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_ACCOUNTS],
  });

  const [updateAccount, { loading: updateAccountLoading }] = useUpdateAccountMutation({
    onCompleted: () => {
      toast.success('Account updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_ACCOUNTS],
  });

  const { data: usersData, loading: usersLoading } = useGetUsersForAccountsQuery({
    variables: {
      input: {}, // for get all users
    },
    fetchPolicy: 'network-only',
  });

  const userOptions = getUsersOptions(usersData);

  const methods = useForm<Step2FormData>({
    defaultValues: defaultValuesStep2(accountData),
    resolver: yupResolver(step2Schema),
  });

  const { handleSubmit, watch, setValue, reset } = methods;

  const pickedUsers = watch('users');

  const usersIds = accountData?.getAccountById?.users?.map((user) => user.id);

  const removeUser = (id?: number) => {
    setValue(
      'users',
      pickedUsers?.filter((user) => user.id !== id),
    );
  };

  const handleRemoveUser = (id?: number, name?: string) => {
    usersIds?.includes(id)
      ? onOpenUserModal({ name: name, removeUser: () => removeUser(id) })
      : removeUser(id);
  };

  const onSubmit = (data: Step2FormData) => {
    const generalData = { ...stepValue, ...data };

    isEdit
      ? updateAccount({
          variables: {
            ...(submitAccount(generalData) as UpdateAccountMutationVariables),
          },
        })
      : createAccount({
          variables: {
            ...submitAccount(generalData),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesStep2(accountData));
  }, [accountId, accountData]);

  return (
    <RequestHandler loading={usersLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!!pickedUsers?.length && (
            <Stack mb={'24px'} direction={'row'} flexWrap={'wrap'} gap={'24px'}>
              {pickedUsers?.map((user) => {
                return (
                  <UserItemAutocomplete
                    user={user}
                    handleClick={isEdit ? handleRemoveUser : removeUser}
                    key={user.id}
                  />
                );
              })}
            </Stack>
          )}
          <AutocompeteRHF
            options={userOptions as AutocompeteOptionType[]}
            name='users'
            label={'Choose users who get notification'}
          />
          <Stack>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createAccountLoading || updateAccountLoading}
            >
              Save
            </LoadingButton>
          </Stack>
          <ConfirmDeleteUserModal infoModal={infoModalUser} onCloseModal={onCloseUserModal} />
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
