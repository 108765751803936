import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

import { YupNumberOrNullOrUndefinedFieldType, YupStringOrNullOrUndefinedFieldType } from './types';

export const createEditEarnedSchema = yup.object().shape({
  row: yup.object().shape({
    invoice: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,
    title: yup
      .string()
      .max(255, MESSAGES.MAX_LENGTH('255'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupStringOrNullOrUndefinedFieldType,
    date: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    description: yup
      .string()
      .max(255, MESSAGES.MAX_LENGTH('255'))
      .required(MESSAGES.REQUIRE_MESSAGE),

    bookingAmount: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,
    markUp: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(100, MESSAGES.MAX_NUMBER_VALUE('100'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,
    userProfit: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(100, MESSAGES.MAX_NUMBER_VALUE('100'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,
    userProfitValue: yup.number().notRequired(),
    almontProfitValue: yup.number().notRequired(),
    totalProfit: yup.number().notRequired(),
    userId: yup.number().notRequired(),
    isInitEdit: yup.boolean().notRequired(),
    tempId: yup.string().notRequired(),
    id: yup.number().notRequired(),

    currency: yup.string().notRequired(),
  }),
});
