import { GetContactByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesContactHotel = (data?: GetContactByIdQuery) => {
  if (data && data.getContactById.additional_data) {
    const { title, city, id, additional_data, profit } = data.getContactById;

    const {
      country,
      address,
      contact_person,
      whats_app,
      phones,
      emails,
      comment,
      id: additionalDataId,
    } = additional_data;

    return {
      title: title || '',
      country: country || '',
      city: city || '',
      address: address || '',
      profit: profit || null,
      contactPerson: contact_person || '',
      whatsapp: whats_app || '',
      phones: phones?.length
        ? phones.map((item) => ({
            phone: String(item.phone),
            id: item.id,
          }))
        : [{ phone: '' }],
      emails: emails?.length
        ? emails.map((item) => ({
            email: String(item.email),
            id: item.id,
          }))
        : [{ email: '' }],
      comment: comment || '',
      isSendNotification: false,
      additionalDataId: Number(additionalDataId) || null,
      contactId: Number(id) || null,
    };
  }

  return {
    title: '',
    country: '',
    city: '',
    address: '',
    profit: null,
    contactPerson: '',
    whatsapp: '',
    phones: [{ phone: '' }],
    emails: [{ email: '' }],
    comment: '',
    isSendNotification: false,
  };
};
