import { COLUMN_IDS, TABLE_SORT_FIELDS } from 'components/UI/TableList/constants';
import { TableRowType } from 'components/UI/TableList/types';
import { AddEditEarnedFormData } from 'connectors/Earned/types';

export const getEditibleTableSellValue = ({
  row,
  id,
}: {
  row: TableRowType | AddEditEarnedFormData;
  id: COLUMN_IDS | TABLE_SORT_FIELDS;
}) => {
  const totalProfit = (Number(row?.bookingAmount) / 100) * Number(row?.markUp);
  const userProfitValue = (totalProfit / 100) * Number(row?.userProfit);
  const almontProfitValue = totalProfit - userProfitValue;
  switch (id) {
    case COLUMN_IDS.TOTAL_PROFIT:
      return totalProfit?.toFixed(2);
    case COLUMN_IDS.USER_PROFIT_VALUE:
      return userProfitValue?.toFixed(2);
    case COLUMN_IDS.ALMONT_PROFIT_VALUE:
      return almontProfitValue?.toFixed(2);
    default:
      return (row as TableRowType)[id];
  }
};
