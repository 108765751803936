import { gql } from '@apollo/client';

export const GET_EDUCATION_BY_ID = gql`
  query getEducationById($getEducationByIdId: Int!) {
    getEducationById(id: $getEducationByIdId) {
      id
      title
      files {
        path
        mimetype
        size
        id
      }
      comment
      type
    }
  }
`;
