import {
  CurrencyEnum,
  useGetEarnedListQuery,
  useGetPaidListQuery,
} from 'graphql/generated/graphql';
import {
  getItemDataForEarnedTable,
  getItemDataForPaidTable,
  getOptionsForEarnedTable,
  getOptionsForPaidTable,
} from 'utils';

import { AccordionList } from '../AccordionList';
import { ACCORDION_CHILDREN_VARIANTS } from '../AccordionList/constants';
import { RequestHandler } from '../RequestHandler';
import { TABLE_CONTENT_TYPE } from '../TableList/constants';
import { IUserPaymentProps } from './types';

export const UserPayment = ({ userId, userProfit, userName }: IUserPaymentProps) => {
  const { data: earnedData, loading } = useGetEarnedListQuery({
    variables: {
      userId,
    },
  });

  const { data: paidData, loading: paidLoading } = useGetPaidListQuery({
    variables: {
      userId,
    },
  });

  const earnedGroup = earnedData?.getEarnedList.group;

  const earnedUSDRows =
    earnedGroup?.USD?.map((earnItem) => getItemDataForEarnedTable(earnItem)) || [];
  const earnedEURRows =
    earnedGroup?.EUR?.map((earnItem) => getItemDataForEarnedTable(earnItem)) || [];
  const earnedGBPRows =
    earnedGroup?.GBP?.map((earnItem) => getItemDataForEarnedTable(earnItem)) || [];

  const paidGroup = paidData?.getPaidList.group;

  const paidUSDRows = paidGroup?.USD?.map((paidItem) => getItemDataForPaidTable(paidItem)) || [];
  const paidEURRows = paidGroup?.EUR?.map((paidItem) => getItemDataForPaidTable(paidItem)) || [];
  const paidGBPRows = paidGroup?.GBP?.map((paidItem) => getItemDataForPaidTable(paidItem)) || [];

  const options = [
    // Earned
    {
      title: 'Earned',
      children: {
        type: ACCORDION_CHILDREN_VARIANTS.ACCORDION,
        options: [
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Usd,
              contentType: TABLE_CONTENT_TYPE.EARNED,
              headCells: getOptionsForEarnedTable(CurrencyEnum.Usd, userName),
              rows: earnedUSDRows,
              userId,
              userProfit,
            },
          },
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Eur,
              contentType: TABLE_CONTENT_TYPE.EARNED,
              headCells: getOptionsForEarnedTable(CurrencyEnum.Eur, userName),
              rows: earnedEURRows,
              userId,
              userProfit,
            },
          },
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Gbp,
              contentType: TABLE_CONTENT_TYPE.EARNED,
              headCells: getOptionsForEarnedTable(CurrencyEnum.Gbp, userName),
              rows: earnedGBPRows,
              userId,
              userProfit,
            },
          },
        ],
      },
    },

    // Paid
    {
      title: 'Paid',
      children: {
        type: ACCORDION_CHILDREN_VARIANTS.ACCORDION,
        options: [
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Usd,
              contentType: TABLE_CONTENT_TYPE.PAID,
              headCells: getOptionsForPaidTable(CurrencyEnum.Usd),
              rows: paidUSDRows,
              userId,
              userProfit,
            },
          },
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Eur,
              contentType: TABLE_CONTENT_TYPE.PAID,
              headCells: getOptionsForPaidTable(CurrencyEnum.Eur),
              rows: paidEURRows,
              userId,
              userProfit,
            },
          },
          {
            title: ``,
            children: {
              type: ACCORDION_CHILDREN_VARIANTS.TABLE,
              options: [],
              currency: CurrencyEnum.Gbp,
              contentType: TABLE_CONTENT_TYPE.PAID,
              headCells: getOptionsForPaidTable(CurrencyEnum.Gbp),
              rows: paidGBPRows,
              userId,
              userProfit,
            },
          },
        ],
      },
    },
  ];

  return (
    <RequestHandler loading={loading || paidLoading}>
      <AccordionList options={options} />
    </RequestHandler>
  );
};
