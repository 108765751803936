export const EDUCATION_TAB_OPTIONS = [
  {
    label: 'Training',
    index: 0,
  },
  {
    label: 'Contracts',
    index: 1,
  },
  {
    label: 'Gostelow report',
    index: 2,
  },
];
