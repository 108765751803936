import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query getMemberships($input: MembershipFiltersInput!) {
    getMemberships(input: $input) {
      count
      rows {
        brand
        exclusiveBenefits
        id
        link
        participating
        title
      }
    }
  }
`;
