import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import { GetUserByIdQuery, SectionObject } from 'graphql/generated/graphql';

import { getEditSectionsOptions } from './getSectionsOptions';

export const defaultValuesUser = (
  data?: GetUserByIdQuery,
  sectionsOptions?: AutocompeteOptionType[],
) => {
  if (data && data.getUserById) {
    const {
      birthday,
      email,
      full_name,
      id,
      phone_number,
      profit,
      comment,
      managers,
      is_success_manager,
      sections,
    } = data.getUserById;

    return {
      fullName: full_name || '',
      email: email || '',
      profit: profit || null,
      phone: phone_number || '',
      dateOfBirth: birthday || '',
      userId: Number(id) || null,
      successManager: managers?.length
        ? managers?.map(({ id, full_name }) => {
            return { id: id as number, label: full_name as string, photo: '' };
          })[0]
        : null,
      isSuccessManager: is_success_manager,
      comment: comment,
      sections: getEditSectionsOptions(sections as SectionObject[]) || [],
    };
  }

  return {
    fullName: '',
    email: '',
    profit: null,
    phone: '',
    dateOfBirth: '',
    successManager: null,
    isSuccessManager: false,
    comment: '',
    sections: sectionsOptions || [],
  };
};
