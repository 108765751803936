import { Box, Stack, styled } from '@mui/material';

import { ImagePlaceholderBlockProps } from './types';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
});

export const DropzoneWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ImageBlock = styled(Box)(({ theme }) => {
  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    width: '94px',
    height: '94px',
    overflow: 'hidden',
    background: theme.palette.primary.main,
  };
});

export const ImagePlaceholderBlock = styled(ImageBlock)<ImagePlaceholderBlockProps>(({
  haserror,
  theme,
}) => {
  const hasError = haserror === 'true';
  return {
    border: hasError
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.gray50}`,
  };
});

export const ActionWrapper = styled(Stack)({
  position: 'absolute',
  top: '4px',
  left: '4px',
});
