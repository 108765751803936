export const MESSAGES = {
  REQUIRE_MESSAGE: 'Required field',
  INCORRECT_EMAIL: 'Invalid email format',
  INCORRECT_LINK: 'Invalid link format',
  INCORRECT_CONFIRM_PASSWORD: 'The entered values do not match',
  MAX_NUMBER_VALUE: (val: string) => `Maximum value ${val}`,
  MIN_NUMBER_VALUE: (val: string) => `Minimum value ${val}`,
  MAX_LENGTH: (val: string) => `Maximum field length ${val}`,
  MIN_LENGTH: (val: string) => `Minimum field length ${val}`,
  NUMBER_MESSAGE: 'Please enter the correct number',
  INVALID_VALUE: 'Invalid value',
};
