import { Box } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { MODAL_NAME } from 'constants/index';
import { useModalContext } from 'context';
import { palette } from 'theme/foundations';

import { AddEditAccountsModal } from '../AccountModals/AddEditAccountModal';
import { ConfirmDeleteAccountModal } from '../AccountModals/ConfirmDeleteAccountModal';
import {
  AddEditContactCruisesModal,
  AddEditContactDmcsModal,
  AddEditContactHotelModal,
  AddEditContactOtherModal,
  ConfirmDeleteContactModal,
} from '../ContactModals';
import { ViewDocumentModal } from '../DocumentModals';
import { ConfirmDeleteEarnedModal, CreatePaymentNotificationModal } from '../EarnedModals';
import { AddEditEducationModal, ConfirmDeleteEducationModal } from '../EducationModals';
import { AddEditMembershipModal, ConfirmDeleteMembershipModal } from '../MembershipModals';
import { AddEditOfferModal, ConfirmDeleteOfferModal } from '../OfferModals';
import { ConfirmDeletePaidModal } from '../PaidModals';
import {
  AddEditUserModal,
  AddNewEarnedUserModal,
  AddNotificationsModal,
  ConfirmDeleteUserModal,
  NotificationSentSuccessModal,
} from '../UserModals';
import { CloseButton, Wrapper } from './styles';

export const ModalContent = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const isConfirmModal = [
    MODAL_NAME.DELETE_OFFER,
    MODAL_NAME.DELETE_EARNED,
    MODAL_NAME.DELETE_PAID,
    MODAL_NAME.DELETE_CONTACT,
    MODAL_NAME.DELETE_USER,
    MODAL_NAME.ADD_NEW_EARNED_USER,
    MODAL_NAME.NOTIFICATIONS_SENT,
    MODAL_NAME.ADD_PAYMENT_NOTIFICATION,
    MODAL_NAME.DELETE_MEMBERSHIP,
    MODAL_NAME.DELETE_ACCOUNTS,
    MODAL_NAME.DELETE_EDUCATION,
  ].includes(infoModal.name);

  if (!infoModal.name) return null;
  return (
    <Wrapper
      open={infoModal.open}
      onClose={onCloseModal}
      isconfirmmodal={isConfirmModal.toString()}
    >
      {!isConfirmModal && (
        <CloseButton
          startIcon={<CloseIcon fill={palette.blue91} />}
          variant='text'
          onClick={onCloseModal}
        />
      )}
      <ActiveModal />
    </Wrapper>
  );
};

const ActiveModal = () => {
  const { infoModal } = useModalContext();

  switch (infoModal.name) {
    case MODAL_NAME.ADD_EDIT_OFFER:
      return <AddEditOfferModal />;
    case MODAL_NAME.DELETE_OFFER:
      return <ConfirmDeleteOfferModal />;
    case MODAL_NAME.ADD_EDIT_CONTACT_HOTEL:
      return <AddEditContactHotelModal />;
    case MODAL_NAME.ADD_EDIT_CONTACT_CRUISERS:
      return <AddEditContactCruisesModal />;
    case MODAL_NAME.ADD_EDIT_CONTACT_DMCS:
      return <AddEditContactDmcsModal />;
    case MODAL_NAME.ADD_EDIT_CONTACT_OTHER:
      return <AddEditContactOtherModal />;
    case MODAL_NAME.DELETE_CONTACT:
      return <ConfirmDeleteContactModal />;
    case MODAL_NAME.ADD_EDIT_USER:
      return <AddEditUserModal />;
    case MODAL_NAME.DELETE_USER:
      return <ConfirmDeleteUserModal />;
    case MODAL_NAME.ADD_NEW_EARNED_USER:
      return <AddNewEarnedUserModal />;
    case MODAL_NAME.ADD_NOTIFICATIONS:
      return <AddNotificationsModal />;
    case MODAL_NAME.NOTIFICATIONS_SENT:
      return <NotificationSentSuccessModal />;
    case MODAL_NAME.DELETE_EARNED:
      return <ConfirmDeleteEarnedModal />;
    case MODAL_NAME.DELETE_PAID:
      return <ConfirmDeletePaidModal />;
    case MODAL_NAME.ADD_PAYMENT_NOTIFICATION:
      return <CreatePaymentNotificationModal />;
    case MODAL_NAME.VIEW_DOCUMENT:
      return <ViewDocumentModal />;
    case MODAL_NAME.ADD_EDIT_MEMBERSHIP:
      return <AddEditMembershipModal />;
    case MODAL_NAME.DELETE_MEMBERSHIP:
      return <ConfirmDeleteMembershipModal />;
    case MODAL_NAME.ADD_EDIT_ACCOUNTS:
      return <AddEditAccountsModal />;
    case MODAL_NAME.DELETE_ACCOUNTS:
      return <ConfirmDeleteAccountModal />;
    case MODAL_NAME.ADD_EDIT_EDUCATION:
      return <AddEditEducationModal />;
    case MODAL_NAME.DELETE_EDUCATION:
      return <ConfirmDeleteEducationModal />;
    default:
      return <Box />;
  }
};
