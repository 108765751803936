import { Box, styled } from '@mui/material';

import { ImagePlaceholderBlockProps } from './types';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
});

export const DropzoneWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ImageBlock = styled(Box)({
  display: 'flex',
  borderRadius: '4px',
  width: '148px',
  height: '100px',
  overflow: 'hidden',

  img: {
    width: '148px',
    height: '100px',
  },
});

export const ImagePlaceholderBlock = styled(ImageBlock)<ImagePlaceholderBlockProps>(({
  haserror,
  theme,
}) => {
  const hasError = haserror === 'true';
  return {
    border: hasError ? `1px solid ${theme.palette.error.main}` : 'none',
  };
});
