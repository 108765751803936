export const DocumentIcon = () => {
  return (
    <svg width='38' height='50' viewBox='0 0 38 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.5356 10.506L36.8845 9.84138L28.3598 1.13879L27.7088 0.474138C27.414 0.173276 27.0053 0 26.5873 0H2.07987C1.07836 0 0 0.789655 0 2.52241V33.6207V47.8767V48.2759C0 48.9974 0.711022 49.7009 1.55209 49.9224C1.59431 49.9336 1.63484 49.95 1.67876 49.9586C1.81049 49.9853 1.94476 50 2.07987 50H35.9201C36.0552 50 36.1895 49.9853 36.3212 49.9586C36.3652 49.95 36.4057 49.9336 36.4479 49.9224C37.289 49.7009 38 48.9974 38 48.2759V47.8767V33.6207V12.05C38 11.3888 37.9223 10.9009 37.5356 10.506ZM34.9896 10.3448H27.8667V3.07328L34.9896 10.3448ZM2.07987 48.2759C2.01991 48.2759 1.96587 48.2534 1.91267 48.2336C1.78093 48.1698 1.68889 48.0353 1.68889 47.8767V35.3448H36.3111V47.8767C36.3111 48.0353 36.2191 48.169 36.0873 48.2336C36.0341 48.2534 35.9801 48.2759 35.9201 48.2759H2.07987ZM1.68889 33.6207V2.52241C1.68889 2.33534 1.71676 1.72414 2.07987 1.72414H26.2268C26.1989 1.83276 26.1778 1.94483 26.1778 2.06121V12.069H35.9809C36.0949 12.069 36.2039 12.0474 36.3103 12.019C36.3103 12.0319 36.3111 12.0371 36.3111 12.05V33.6207H1.68889Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
      <path
        d='M10.292 25.9221L14.3885 21.7491C14.8743 21.2542 15.662 21.2542 16.1478 21.7491L19.4814 25.1449C19.9192 25.5909 20.6122 25.641 21.1075 25.2626L22.2587 24.3831C22.5584 24.1541 22.9435 24.0749 23.307 24.1675L27.7087 25.2885M22.7325 19.5857C22.7325 20.2856 22.1755 20.853 21.4884 20.853C20.8013 20.853 20.2444 20.2856 20.2444 19.5857C20.2444 18.8858 20.8013 18.3184 21.4884 18.3184C22.1755 18.3184 22.7325 18.8858 22.7325 19.5857ZM11.292 29.724H26.7087C27.2609 29.724 27.7087 29.2763 27.7087 28.724V15.5166C27.7087 14.9643 27.2609 14.5166 26.7087 14.5166H11.292C10.7397 14.5166 10.292 14.9643 10.292 15.5166V28.724C10.292 29.2763 10.7397 29.724 11.292 29.724Z'
        stroke='#121923'
        strokeWidth='1.4'
      />
    </svg>
  );
};
