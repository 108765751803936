import { Box } from '@mui/material';

import { SIDEBAR_NAVIGATION_OPTIONS } from './constants';
import { MenuLink, MenuWrapper } from './styles';

export const NavigationSidebar = () => {
  return (
    <MenuWrapper>
      {SIDEBAR_NAVIGATION_OPTIONS.map(({ id, label, route, Icon }) => (
        <MenuLink key={id} to={route}>
          <Icon />
          {label}
          <Box component={'span'} />
        </MenuLink>
      ))}
    </MenuWrapper>
  );
};
