import { DOCIcon, PDFIcon, PNGIcon, TXTIcon, XLSIcon } from 'components';
import { EducationTypeEnum } from 'graphql/generated/graphql';

export const API_URL = process.env.REACT_APP_API_HOST;

export const BREACKPOINTS = {
  uploadFiles: '/v1/api/file/upload',
};

export const ROUTES = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  verifyEmail: '/verify-email',
  newPassword: '/new-password',
  specialOffers: '/special-offers',
  contacts: '/contacts',
  membership: '/membership',
  accounts: '/accounts',
  documents: '/documents',
  users: '/users',
  user: '/users/:id',
  education: '/education',
  notificationsHistory: '/notifications-history',
};

export const LS = {
  TOKEN: 'token',
  TEMP_TOKEN: 'temp-token',
};
export const MODAL_NAME = {
  ADD_EDIT_OFFER: 'AddEditOffer',
  DELETE_OFFER: 'DeleteOffer',
  ADD_EDIT_CONTACT_HOTEL: 'AddEditContactHotel',
  ADD_EDIT_CONTACT_CRUISERS: 'AddEditContactCruisers',
  ADD_EDIT_CONTACT_DMCS: 'AddEditContactDMCS',
  ADD_EDIT_CONTACT_OTHER: 'AddEditContactOther',
  DELETE_CONTACT: 'DeleteContact',
  ADD_EDIT_USER: 'AddEditUser',
  DELETE_USER: 'DeleteUser',
  ADD_NEW_EARNED_USER: 'AddNewEarnedUser',
  ADD_NOTIFICATIONS: 'AddNotifications',
  NOTIFICATIONS_SENT: 'NotificationsSent',
  DELETE_EARNED: 'DeleteEarned',
  DELETE_PAID: 'DeletePaid',
  ADD_PAYMENT_NOTIFICATION: 'AddPaymentNotification',
  VIEW_DOCUMENT: 'ViewDocument',
  ADD_EDIT_MEMBERSHIP: 'AddEditMembership',
  DELETE_MEMBERSHIP: 'DeleteMembership',
  ADD_EDIT_ACCOUNTS: 'AddEditAccounts',
  DELETE_ACCOUNTS: 'DeleteAccounts',
  ADD_EDIT_EDUCATION: 'AddEditEducation',
  DELETE_EDUCATION: 'DeleteEducation',
};

export enum CURRENCIES {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export const CURRENCY_OPTIONS = [
  { value: CURRENCIES.USD, label: '$' },
  { value: CURRENCIES.EUR, label: '€' },
  { value: CURRENCIES.GBP, label: '£' },
];

export const EDUCATION_TYPE_OPTIONS = [
  { value: EducationTypeEnum.Training, label: 'Training' },
  { value: EducationTypeEnum.Contract, label: 'Contract' },
  { value: EducationTypeEnum.Report, label: 'Gostelow report' },
];

export enum ERROR_CODES {
  INVALID_CREDENTIAL = 'InvalidCredential',
  USER_NOT_FOUND = 'UserNotFound',
  RESET_CODE_EXPIRIED = 'ResetCodeExpired',
  RESET_CODE_ALREADY_REQUEST = 'ResetCodeAlreadyRequested',
  INVALID_DATE_RANGE = 'InvalidDateRange',
  INVALID_PRICE_RANGE = 'InvalidPriceRange',
  EMAIL_ALREADY_USED = 'EmailAlreadyUsed',
  INVOICE_EXIST = 'InvoiceAlredyExist',
  ONLY_ADMIN_ALLOWED = 'OnlyAdminAllowed',
  SUCCESS_MANAGER_NOT_FOUND = 'SuccessManagerNotFound',
}

export const TABLE_PAGE_LIMIT = 10;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum DOC_TYPES {
  PDF = 'Pdf',
  TXT = 'Txt',
  DOC = 'Doc',
  XLS = 'Xls',
  PNG = 'Png',
}

export const DOC_TYPES_LOGO = {
  [DOC_TYPES.PDF]: PDFIcon,
  [DOC_TYPES.TXT]: TXTIcon,
  [DOC_TYPES.DOC]: DOCIcon,
  [DOC_TYPES.XLS]: XLSIcon,
  [DOC_TYPES.PNG]: PNGIcon,
};

export enum FILE_MIMETYPES {
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  TXT = 'text/plain',
}
