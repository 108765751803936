import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { AutocompeteRHFProps } from './types';

export const AutocompeteRHF = ({
  name,
  label,
  multiple = true,
  subLabel,
  options,
}: AutocompeteRHFProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {label && (
              <Box mb='8px' display={'flex'}>
                <Typography variant='body1'>{label}</Typography>
              </Box>
            )}
            <Autocomplete
              options={options}
              renderInput={(params) => (
                <TextField
                  error={!!errors[name]}
                  {...params}
                  placeholder={!value?.length ? 'Please select' : ''}
                />
              )}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onChange={(_event, values) => onChange(values)}
              value={value}
              multiple={multiple}
              filterSelectedOptions
              renderTags={(tagValue, getTagProps) => {
                return tagValue?.map((option, index, arr) => {
                  if (index === arr?.length - 1) {
                    return <Chip label={option?.label} {...getTagProps({ index })} />;
                  }
                });
              }}
            />
            {subLabel && <Typography variant='body2'>{subLabel}</Typography>}
            <Box height={20}>
              {errorMessage && <Typography variant='error'>{String(errorMessage)}</Typography>}
            </Box>
          </>
        );
      }}
    />
  );
};
