import { API_URL, EDUCATION_TYPE_OPTIONS } from 'constants/index';
import { GetEducationByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesEducation = (data?: GetEducationByIdQuery) => {
  if (data && data?.getEducationById) {
    const { id, title, files, comment, type } = data.getEducationById;

    const pdfFile = files?.find((file) => file.mimetype === 'application/pdf');
    const txtFile = files?.find((file) => file.mimetype === 'text/plain');

    return {
      category: type || EDUCATION_TYPE_OPTIONS[0].value,
      type: title || '',
      pdf: pdfFile?.path
        ? {
            ...pdfFile,
            preview: `${API_URL}/${pdfFile?.path}`,
          }
        : null,
      txt: txtFile?.path
        ? {
            ...txtFile,
            preview: `${API_URL}/${txtFile?.path}`,
          }
        : null,
      comment: comment || '',
      educationId: Number(id) || null,
    };
  }

  return {
    category: EDUCATION_TYPE_OPTIONS[0].value,
    type: '',
    pdf: null,
    txt: null,
    comment: '',
  };
};
