import { gql } from '@apollo/client';

export const GET_OFFER_BY_ID = gql`
  query getOfferById($getOfferByIdId: Int!) {
    getOfferById(id: $getOfferByIdId) {
      city
      order
      currency
      date_from
      date_to
      id
      information
      link
      preview {
        path
      }
      price_from
      price_to
      title
      onlyForClient
      additional_data {
        address
        comment
        contact_person
        country
        emails {
          email
          id
        }
        id
        phones {
          id
          phone
        }
        whats_app
      }
      excludeUsers {
        offerId
        userId
      }
    }
  }
`;
