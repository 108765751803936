import { COLUMN_IDS, TABLE_SORT_FIELDS } from 'components/UI/TableList/constants';

export const HOTEL_CONTACTS_TABLE_OPTIONS = (
  handleChangeSort: (field: TABLE_SORT_FIELDS) => void,
) => [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title', handleChangeSort },
  { id: COLUMN_IDS.LOCATION, label: 'Location' },
  { id: COLUMN_IDS.CONTACT_PERSON, label: 'Contact person' },
  { id: COLUMN_IDS.EMAIL, label: 'Email' },
  { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];

export const CRUISES_CONTACTS_TABLE_OPTIONS = (
  handleChangeSort: (field: TABLE_SORT_FIELDS) => void,
) => [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title', handleChangeSort },
  { id: COLUMN_IDS.LOCATION, label: 'Location' },
  { id: COLUMN_IDS.COUNTRY, label: 'Country' },
  { id: COLUMN_IDS.COMPANY, label: 'Company' },
  { id: COLUMN_IDS.CONTACT_PERSON, label: 'Contact person' },
  { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];

export const DMC_CONTACTS_TABLE_OPTIONS = (
  handleChangeSort: (field: TABLE_SORT_FIELDS) => void,
) => [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title', handleChangeSort },
  { id: COLUMN_IDS.COUNTRY, label: 'Country' },
  { id: COLUMN_IDS.COMPANY, label: 'Company' },
  { id: COLUMN_IDS.CONTACT_PERSON, label: 'Contact person' },
  { id: COLUMN_IDS.EMAIL, label: 'Email' },
  { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];

export const OTHER_CONTACTS_TABLE_OPTIONS = (
  handleChangeSort: (field: TABLE_SORT_FIELDS) => void,
) => [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title', handleChangeSort },
  { id: COLUMN_IDS.LOCATION, label: 'Location' },
  { id: COLUMN_IDS.COUNTRY, label: 'Country' },
  { id: COLUMN_IDS.COMPANY, label: 'Company' },
  { id: COLUMN_IDS.CONTACT_PERSON, label: 'Contact person' },
  { id: COLUMN_IDS.EMAIL, label: 'Email' },
  { id: COLUMN_IDS.COMISSION, label: 'Commission' },
  { id: COLUMN_IDS.PHONE_NUMBER, label: 'Phone number' },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];

export const TAB_OPTIONS = [
  {
    label: 'Hotels',
    index: 0,
  },
  {
    label: 'Cruises',
    index: 1,
  },
  {
    label: 'DMC’s',
    index: 2,
  },
  {
    label: 'Other',
    index: 3,
  },
];
