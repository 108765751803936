import { loginImg } from 'assets/images';
import { Logo } from 'components/UI';
import { ROUTES } from 'constants/index';
import { Link } from 'react-router-dom';

import { FormInner, FormWrapper, LeftBlock, RightBlock, ShadowBox, Wrapper } from './styles';
import { AuthLayoutPropsType } from './types';

export const AuthLayout = ({ children }: AuthLayoutPropsType) => {
  return (
    <Wrapper>
      <LeftBlock>
        <Link to={ROUTES.login}>
          <Logo />
        </Link>
        <FormWrapper>
          <FormInner>{children}</FormInner>
        </FormWrapper>
      </LeftBlock>
      <RightBlock backgroundimg={loginImg}>
        <ShadowBox />
      </RightBlock>
    </Wrapper>
  );
};
