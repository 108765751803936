import { TABLE_SORT_DIRECTIONS, TABLE_SORT_FIELDS } from 'components/UI/TableList/constants';
import { DocumentSortFieldsEnum, FieldsNames, SortTypeEnum } from 'graphql/generated/graphql';

export const getSortFieldValue = (field: TABLE_SORT_FIELDS) => {
  switch (field) {
    case TABLE_SORT_FIELDS.PRICE:
      return FieldsNames.PriceFrom;
    case TABLE_SORT_FIELDS.DATE:
      return FieldsNames.DateFrom;
    case TABLE_SORT_FIELDS.OWNER:
      return DocumentSortFieldsEnum.Owner;
    case TABLE_SORT_FIELDS.TYPE:
      return DocumentSortFieldsEnum.Type;
    default:
      return;
  }
};

export const getSortDirectionValue = (direction: TABLE_SORT_DIRECTIONS) => {
  switch (direction) {
    case TABLE_SORT_DIRECTIONS.ASC:
      return SortTypeEnum.Asc;
    case TABLE_SORT_DIRECTIONS.DESC:
      return SortTypeEnum.Desc;
    default:
      return;
  }
};
