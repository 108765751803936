import { ContactTypesEnum, EducationTypeEnum } from 'graphql/generated/graphql';

export const getTypeValueForContacts = (index: number): ContactTypesEnum => {
  switch (index) {
    case 0:
      return ContactTypesEnum.Hotel;
    case 1:
      return ContactTypesEnum.Cruises;
    case 2:
      return ContactTypesEnum.Dmc;
    case 3:
      return ContactTypesEnum.Other;
    default:
      return ContactTypesEnum.Hotel;
  }
};

export const getTypeValueForEducations = (index: number): EducationTypeEnum => {
  switch (index) {
    case 0:
      return EducationTypeEnum.Training;
    case 1:
      return EducationTypeEnum.Contract;
    case 2:
      return EducationTypeEnum.Report;
    default:
      return EducationTypeEnum.Training;
  }
};
