export const LogoutIcon = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Iconly/Curved/Logout'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='Logout' transform='translate(2.000000, 2.000000)' stroke='#000000' strokeWidth='1.5'>
          <line x1='19.791' y1='10.1207' x2='7.75' y2='10.1207' id='Stroke-1'></line>
          <polyline
            id='Stroke-3'
            points='16.8643 7.2047 19.7923 10.1207 16.8643 13.0367'
          ></polyline>
          <path
            d='M0.2588,5.6299 C0.5888,2.0499 1.9288,0.7499 7.2588,0.7499 C14.3598,0.7499 14.3598,3.0599 14.3598,9.9999 C14.3598,16.9399 14.3598,19.2499 7.2588,19.2499 C1.9288,19.2499 0.5888,17.9499 0.2588,14.3699'
            id='Stroke-4'
            transform='translate(7.309300, 9.999900) scale(-1, 1) translate(-7.309300, -9.999900) '
          ></path>
        </g>
      </g>
    </svg>
  );
};
