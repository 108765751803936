import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { TextFieldRHF } from 'components';
import { LS, ROUTES } from 'constants/index';
import { useSetNewPaswordMutation } from 'graphql/generated/graphql';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { newPasswordSchema } from 'validation';

import { NewPasswordFormData } from './types';

export const NewPassword = () => {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(newPasswordSchema),
  });

  const {
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitted },
  } = methods;

  const [newPassword, { loading }] = useSetNewPaswordMutation();

  const onSubmit = (data: NewPasswordFormData) => {
    const { password } = data;

    newPassword({
      variables: {
        password,
      },
      onCompleted: () => {
        localStorage.removeItem(LS.TEMP_TOKEN);
        toast.success('Password changed successfully', {
          position: 'bottom-right',
        });
        navigate(ROUTES.login);
      },
      onError: () => {
        localStorage.removeItem(LS.TEMP_TOKEN);
      },
    });
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (isSubmitted) {
      trigger('confirmPassword');
      trigger('password');
    }
  }, [password, trigger, confirmPassword]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h2' mb={'32px'} textAlign='center'>
          New password
        </Typography>
        <TextFieldRHF
          name='password'
          placeholder='Enter here'
          label='Enter new password'
          type='password'
        />
        <TextFieldRHF
          name='confirmPassword'
          placeholder='Enter here'
          label='Repeat new password'
          mb='12px'
          type='password'
        />
        <LoadingButton type='submit' loading={loading} variant='contained'>
          Confirm
        </LoadingButton>
      </form>
    </FormProvider>
  );
};
