import { Button, Stack, styled } from '@mui/material';

export const AddButton = styled(Button)(() => ({
  width: 'fit-content',
  marginLeft: 'auto',
}));

export const TabsWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: 16,
  borderBottom: `1px solid ${theme.palette.black6}`,
}));
