import {
  Step1FormData,
  Step2FormData,
} from 'components/Modal/AccountModals/AddEditAccountModal/types';

export const submitAccount = (data: Step1FormData & Step2FormData) => {
  const { accountId, title, link, password, login, comment, users } = data;

  const isEdit = !!accountId;

  return {
    ...(isEdit && { updateAccountId: Number(accountId) }),
    input: {
      comment: comment,
      link: link,
      login: login,
      password: password,
      title: title,
    },
    userAccounts: users?.map((item) => item.id) as number[],
  };
};
