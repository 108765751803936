import { gql } from '@apollo/client';

export const GET_MEMBERSHIP_BY_ID = gql`
  query getMembershipById($getMembershipByIdId: Int!) {
    getMembershipById(id: $getMembershipByIdId) {
      brand
      comment
      exclusiveBenefits
      howToUse
      id
      link
      participating
      title
    }
  }
`;
