import { MODAL_NAME } from 'constants/index';
import { useModalContext } from 'context';
import {
  DocumentSortFieldsEnum,
  ListDocumentObject,
  useGetManyListDocumentObjectQuery,
} from 'graphql/generated/graphql';
import { useState } from 'react';
import { getItemDataForDocumentsTable, getSortDirectionValue, getSortFieldValue } from 'utils';
import { Maybe } from 'yup';

import { RequestHandler } from '../RequestHandler';
import { TableList } from '../TableList';
import { COLUMN_IDS, TABLE_SORT_DIRECTIONS, TABLE_SORT_FIELDS } from '../TableList/constants';
import { TableSortType } from '../TableList/types';
import { IUserDocumentsProps } from './types';

export const UserDocuments = ({ userId }: IUserDocumentsProps) => {
  // Create data for table row
  const createData = (document: ListDocumentObject) => {
    const documentData = {
      ...getItemDataForDocumentsTable(document),
      // [COLUMN_IDS.ACTION]: [
      //   {
      //     label: 'Delete',
      //     Icon: <DeleteIcon />,
      //     action: () => {},
      //   },
      // ],
    };

    return documentData;
  };
  const { onOpenModal } = useModalContext();

  const [sortInfo, setSortInfo] = useState<TableSortType | undefined>({
    direction: TABLE_SORT_DIRECTIONS.ASC,
    field: null,
  });

  const { data, loading } = useGetManyListDocumentObjectQuery({
    variables: {
      input: {
        ...(sortInfo?.field
          ? {
              field: getSortFieldValue(sortInfo.field) as DocumentSortFieldsEnum,
              sort: getSortDirectionValue(sortInfo.direction),
            }
          : {}),
        ownerId: userId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleViewDocument = (id: number) => {
    onOpenModal(MODAL_NAME.VIEW_DOCUMENT, { id });
  };

  const handleChangeSort = (field: TABLE_SORT_FIELDS) => {
    setSortInfo((prev) => ({
      field: field,
      direction:
        prev?.field === field
          ? prev.direction === TABLE_SORT_DIRECTIONS.ASC
            ? TABLE_SORT_DIRECTIONS.DESC
            : TABLE_SORT_DIRECTIONS.ASC
          : TABLE_SORT_DIRECTIONS.ASC,
    }));
  };

  const handleRow = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: Maybe<number>,
  ) => {
    // const target = event.target as HTMLElement;

    // if (
    //   target.classList.contains('MuiBackdrop-root') || // action list overlay
    //   target.closest('.MuiList-root') || // action list
    //   target.closest('.MuiButtonBase-root') // open action list button
    // ) {
    //   return;
    // }
    handleViewDocument(Number(id));
  };

  const DOCUMENTS_TABLE_OPTIONS = [
    { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
    { id: COLUMN_IDS.TYPE, label: 'Type of document', handleChangeSort },
    { id: COLUMN_IDS.OWNER, label: 'Owner', handleChangeSort },
    { id: COLUMN_IDS.DATE, label: 'Date', handleChangeSort },
    { id: COLUMN_IDS.COMMENT, label: 'Comment' },
    // { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
  ];

  const documents = data?.getManyListDocumentObject?.rows;
  const rows = documents?.map((document) => createData(document)) || [];

  return (
    <RequestHandler loading={loading}>
      <TableList
        tableContainerSx={{ height: 'calc(100vh - 397px)' }}
        headCells={DOCUMENTS_TABLE_OPTIONS}
        handleRow={handleRow}
        isLoading={loading}
        rows={rows}
        sortInfo={sortInfo}
      />
    </RequestHandler>
  );
};
