import { GetSectionsQuery, SectionObject } from 'graphql/generated/graphql';

export const getSectionsOptions = (sectionsData?: GetSectionsQuery) => {
  return sectionsData?.getSections?.map((section) => {
    return { id: section?.id, label: section?.name };
  });
};

export const getEditSectionsOptions = (sectionsData?: SectionObject[]) => {
  return sectionsData?.map((section) => {
    return { id: section?.id as number, label: section?.name as string };
  });
};
