import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxRHFType } from './types';

export const CheckboxRHF = ({ name, label }: CheckboxRHFType) => {
  const { register, watch } = useFormContext();

  const value = watch(name);

  return (
    <FormControlLabel label={label} control={<Checkbox checked={value} />} {...register(name)} />
  );
};
