import '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material';

import * as foundations from './foundations/index';

export const theme = createTheme({
  ...foundations,
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: foundations.palette.black85,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '12px',
              paddingInlineEnd: '12px',
              minHeight: '40px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& [placeholder]::placeholder': {
            color: foundations.palette.black25,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            opacity: 1,
          },
          '.MuiInputBase-root': {
            borderRadius: '2px',
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.gray50}`,
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.gray50}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.blue91}`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.error.main}`,
            },
            '& .MuiInputAdornment-root': {
              marginRight: 0,
            },
          },

          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
          },
          input: {
            MozAppearance: 'textfield',
          },
          background: foundations.palette.primary.main,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          width: '100%',
          textTransform: 'initial',
        },
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: foundations.palette.blue91,
            padding: '8px 16px',
            borderRadius: '2px',
            color: foundations.palette.primary.main,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            '&:hover': {
              background: foundations.palette.blue91,
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            width: 'max-content',
            height: 'max-content',
            background: 'transparent',
            border: 'none',
            color: foundations.palette.blue90,
            padding: 0,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            borderRadius: 0,
            '&:hover': {
              background: 'transparent',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: 'transparent',
            border: `1px solid ${foundations.palette.gray50}`,
            padding: '8px 16px',
            borderRadius: '2px',
            color: foundations.palette.secondary.main,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            '&:hover': {
              background: 'transparent',
              border: `1px solid ${foundations.palette.gray50}`,
            },
          },
        },
      ],
    },
    MuiLoadingButton: {
      defaultProps: {
        size: 'medium',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          width: '100%',
          textTransform: 'initial',
        },
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: foundations.palette.blue90,
            padding: '8px 16px',
            borderRadius: '2px',
            color: foundations.palette.primary.main,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            '&:hover': {
              background: foundations.palette.blue90,
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            width: 'max-content',
            height: 'max-content',
            background: 'transparent',
            border: 'none',
            color: foundations.palette.blue90,
            padding: 0,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            borderRadius: 0,
            '&:hover': {
              background: 'transparent',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: 'transparent',
            border: `1px solid ${foundations.palette.gray50}`,
            padding: '8px 16px',
            borderRadius: '2px',
            color: foundations.palette.secondary.main,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            '&:hover': {
              background: 'transparent',
              border: `1px solid ${foundations.palette.gray50}`,
            },
          },
        },
      ],
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0 9px',
          svg: {
            fill: foundations.palette.black85,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: () => ({
            width: '100%',
            height: '40px',
            borderRadius: '2px',
            background: foundations.palette.primary.main,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.gray50}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${foundations.palette.blue91}`,
            },
            fieldset: {
              borderColor: foundations.palette.gray50,
            },
            '.MuiSelect-select': {
              padding: '8px 12px',
            },
          }),
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            padding: '0 8px',
          },
          '.MuiChip-root': {
            borderRadius: '2px',
            border: `1px solid ${foundations.palette.gray80}`,
            background: foundations.palette.gray70,
          },
          '.MuiSvgIcon-root': {
            display: 'none',
          },
          '.MuiAutocomplete-endAdornment': {
            '.MuiAutocomplete-popupIndicator': {
              '.MuiSvgIcon-root': {
                display: 'inline-flex',
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: foundations.palette.blue91,
          },
          '& .MuiTab-root': {
            color: foundations.palette.gray1,
          },
          '& .MuiTab-root.Mui-selected': {
            color: foundations.palette.blue91,
          },
        },
      },
    },
  },
});
