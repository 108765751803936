import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

import { YupMixedDocFileType } from './types';

export const createEditEducationSchema = yup.object().shape(
  {
    category: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    type: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
    pdf: yup.mixed().when('txt', ([txt]) => {
      return txt ? yup.mixed().notRequired() : yup.mixed().required(MESSAGES.REQUIRE_MESSAGE);
    }) as YupMixedDocFileType,
    txt: yup.mixed().when('pdf', ([pdf]) => {
      return pdf ? yup.mixed().notRequired() : yup.mixed().required(MESSAGES.REQUIRE_MESSAGE);
    }) as YupMixedDocFileType,
    comment: yup.string().notRequired(),
  },
  [
    ['pdf', 'txt'],
    ['txt', 'pdf'],
  ],
);
