import { EMAIL_REGEX } from 'constants/index';
import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, MESSAGES.INCORRECT_EMAIL)
    .required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
