import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { uploadFiles } from 'api';
import {
  AutocompeteRHF,
  CheckboxRHF,
  DatePickerRHF,
  DropzoneRHF,
  RequestHandler,
  TextFieldArray,
  TextFieldRHF,
  UserItemAutocomplete,
} from 'components/UI';
import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import { SelectRHF } from 'components/UI/SelectRHF';
import { defaultValuesOffer, getUsersOptions, submitOffer } from 'connectors';
import { CURRENCY_OPTIONS } from 'constants/index';
import { useModalContext } from 'context';
import {
  CreateOfferMutationVariables,
  UpdateOfferMutationVariables,
  useCreateOfferMutation,
  useGetOfferByIdQuery,
  useGetUsersForOfferQuery,
  useUpdateOfferMutation,
} from 'graphql/generated/graphql';
import { GET_OFFERS } from 'graphql/queries';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createEditOfferSchema } from 'validation';

import { AddEditOfferFormData } from './types';

export const AddEditOfferModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const offerId = infoModal?.content?.id;
  const isEdit = !!offerId;

  const { data: offerData, loading: offerLoading } = useGetOfferByIdQuery({
    variables: {
      getOfferByIdId: offerId,
    },
    skip: !offerId,
    fetchPolicy: 'network-only',
  });

  const [createOffer, { loading: createOfferLoading }] = useCreateOfferMutation({
    onCompleted: () => {
      toast.success('Offer created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_OFFERS],
  });

  const [updateOffer, { loading: updateOfferLoading }] = useUpdateOfferMutation({
    onCompleted: () => {
      toast.success('Offer updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_OFFERS],
  });

  const { data: usersData, loading: usersLoading } = useGetUsersForOfferQuery({
    variables: {
      input: {}, // for get all users
    },
    fetchPolicy: 'network-only',
  });

  const userOptions = getUsersOptions(usersData);

  const methods = useForm<AddEditOfferFormData>({
    defaultValues: defaultValuesOffer(offerData, userOptions as AutocompeteOptionType[]),
    resolver: yupResolver(createEditOfferSchema),
  });

  const { handleSubmit, reset, watch, setValue } = methods;

  const onlyForClient = watch('onlyForClient');
  const pickedUsers = watch('users');

  const removeUser = (id?: number) => {
    setValue(
      'users',
      pickedUsers?.filter((user) => user.id !== id),
    );
  };

  const onSubmit = async (data: AddEditOfferFormData) => {
    const isFile = Object.hasOwn(data?.image as File, 'path');
    let imageId = null;
    if (isFile) {
      const formDataImage = new FormData();
      formDataImage.append('files', data.image as File);
      const imageResponse = await uploadFiles(formDataImage);
      imageId = Number(imageResponse?.files[0]?.id);
    }
    isEdit
      ? updateOffer({
          variables: {
            ...(submitOffer(data, imageId) as UpdateOfferMutationVariables),
          },
        })
      : createOffer({
          variables: {
            ...(submitOffer(data, imageId) as CreateOfferMutationVariables),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesOffer(offerData, userOptions as AutocompeteOptionType[]));
  }, [offerId, offerData, usersData]);

  return (
    <RequestHandler loading={offerLoading || usersLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant='h3' mb={'32px'}>
            {!isEdit ? 'Add new special offer' : 'Edit special offer'}
          </Typography>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='title' label='Title' placeholder='Enter title' />
            <TextFieldRHF name='country' label='Country' placeholder='Enter country' />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='city' label='City' placeholder='Enter city' subLabel='Optional' />
            <TextFieldRHF
              name='address'
              label='Address'
              placeholder='Enter address'
              subLabel='Optional'
            />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <DatePickerRHF name='dateFrom' label='Date from' />
            <DatePickerRHF name='dateTo' label='Date to' />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='index' label='Index' placeholder='Enter index' type='number' />
            <SelectRHF label='Currency' name='currency' options={CURRENCY_OPTIONS} />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF
              name='priceFrom'
              label='Price from'
              placeholder='Enter price from'
              type='number'
              subLabel='Optional'
            />
            <TextFieldRHF
              name='priceTo'
              label='Price to'
              placeholder='Enter price to'
              type='number'
              subLabel='Optional'
            />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF
              name='contactPerson'
              label='Contact person'
              placeholder='Enter contact person'
            />
            <TextFieldRHF
              name='whatsapp'
              label='WhatsApp'
              placeholder='Enter WhatsApp number'
              subLabel='Optional'
            />
          </Stack>
          <Stack direction={'row'} gap='24px' mb={'20px'}>
            <TextFieldArray
              parentName='phones'
              childrenName='phone'
              label='Phone number'
              placeholder='Enter mobile number'
              actionText='Add phone number'
              subLabel='Optional'
            />
            <TextFieldArray
              parentName='emails'
              childrenName='email'
              label='Email'
              placeholder='Enter email'
              actionText='Add email'
              subLabel='Optional'
            />
          </Stack>
          <TextFieldRHF name='link' label='Link' placeholder='Enter link' subLabel='Optional' />
          <Stack>
            <TextFieldRHF
              name='information'
              label='Information'
              placeholder='Enter information'
              multiline
              minRows={3}
              maxRows={3}
            />
            <TextFieldRHF
              name='comment'
              label='Comment'
              subLabel='Optional'
              placeholder='Enter comment'
              multiline
              minRows={3}
              maxRows={3}
            />
            {!!pickedUsers?.length && !onlyForClient && (
              <Stack mb={'24px'} gap={'8px'}>
                <Typography>Users who will not see the offer</Typography>
                <Stack direction={'row'} flexWrap={'wrap'} gap={'24px'}>
                  {pickedUsers?.map((user) => {
                    return (
                      <UserItemAutocomplete user={user} handleClick={removeUser} key={user.id} />
                    );
                  })}
                </Stack>
              </Stack>
            )}
            {!onlyForClient && (
              <AutocompeteRHF
                options={userOptions as AutocompeteOptionType[]}
                name='users'
                label={'Choose users who will not see this offer'}
              />
            )}
            <DropzoneRHF name='image' subLabel='Choose image 640x360' mb='24px' />
            {!onlyForClient && (
              <CheckboxRHF name='isSendNotification' label='Send notification for all users' />
            )}
            {
              <Stack mt={!onlyForClient ? '12px' : 0}>
                <CheckboxRHF name='onlyForClient' label='Only for clients' />
              </Stack>
            }
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createOfferLoading || updateOfferLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
