export enum TABLE_SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TABLE_SORT_FIELDS {
  PRICE = 'price',
  DATE = 'date',
  FULL_NAME = 'fullName',
  OWNER = 'owner',
  TYPE = 'type',
  TITLE = 'title',
}
export enum COLUMN_IDS {
  ID = 'id',
  TITLE = 'title',
  LOCATION = 'location',
  COUNTRY = 'country',
  DATE = 'date',
  PRICE = 'price',
  COMPANY = 'company',
  CONTACT_PERSON = 'contactPerson',
  PHONE_NUMBER = 'phoneNumber',
  COMISSION = 'comission',
  EMAIL = 'email',
  ACTION = 'action',
  LINK = 'link',
  FULL_NAME = 'fullName',
  // Earned
  INVOICE = 'invoice',
  DESCRIPTION = 'description',
  BOOKING_AMOUNT = 'bookingAmount',
  MARK_UP = 'markUp',
  USER_PROFIT = 'userProfit',
  TOTAL_PROFIT = 'totalProfit',
  USER_PROFIT_VALUE = 'userProfitValue',
  ALMONT_PROFIT_VALUE = 'almontProfitValue',
  //Paid
  AMOUNT = 'amount',
  NOTE = 'note',
  //Memberships
  BRAND = 'brand',
  EXCLUSIVE_BENEFITS = 'exclusiveBenefits',
  PARTICIPATING = 'participating',
  // Documents
  TYPE = 'type',
  OWNER = 'owner',
  COMMENT = 'comment',
  // Accounts
  LOGIN = 'login',
  PASSWORD = 'password',
  //Users
  ROLE = 'role',
  //Notifications
  USERS = 'users',
  SENDER = 'sender',
}

export enum TABLE_CONTENT_TYPE {
  EARNED = 'earned',
  PAID = 'paid',
}
