import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { ErrorMessageWrapper, Wrapper } from './styles';
import { RequestHandlerPropsType } from './types';

export const RequestHandler = ({
  loading,
  error,
  children,
  height = '100%',
  sxLoading,
}: RequestHandlerPropsType) => {
  if (loading) {
    return (
      <Wrapper height={height} sx={sxLoading}>
        <CircularProgress color='info' />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <ErrorMessageWrapper>
        <Typography variant={'h4'} fontWeight='500'>
          {error.message}
        </Typography>
      </ErrorMessageWrapper>
    );
  }
  return <>{children}</>;
};
