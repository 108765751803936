import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_HISTORY = gql`
  query getNotificationHistories($input: PaginationInput!) {
    getNotificationHistories(input: $input) {
      count
      rows {
        created_at
        data
        id
        notificationMessage
        receiverIds
        users {
          full_name
        }
        sender {
          full_name
        }
      }
    }
  }
`;
