import { Stack, styled } from '@mui/material';

export type WrapperProps = {
  isback?: string;
};

export const Wrapper = styled(Stack)<WrapperProps>(({ isback }) => {
  const isBack = isback === 'true';

  return {
    height: isBack ? 72 : 88,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 32,
  };
});
