export const palette = {
  primary: {
    main: '#fff',
  },
  secondary: {
    main: '#000',
  },
  error: { main: '#FF3B30' },

  skyBlue: '#F4F8FF',
  lightBlue: 'rgba(234, 243, 255, 1)',
  black1: 'rgba(47, 47, 50, 1)',
  black6: 'rgba(0, 0, 0, 0.06)',
  black8: 'rgba(18, 18, 18, 0.08)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(9, 9, 9, 0.2)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black50: '#2F2F32',
  black60: 'rgba(85, 85, 85, 0.6)',
  black85: 'rgba(0, 0, 0, 0.85)',
  gray5: '#F1F1F1',
  gray1: 'rgba(110, 110, 110, 1)',
  gray10: '#F9F9F9',
  gray20: '#EAEAE9',
  gray30: '#FAFAFA',
  gray35: '#C6C6C6',
  gray40: '#A4A4A4',
  gray41: 'rgba(164, 164, 164, 1)',
  gray50: '#D9D9D9',
  gray60: '#6E6E6E',
  gray70: '#F5F5F5',
  gray80: '#F0F0F0',
  blue30: 'rgba(234, 243, 255, 0.3)',
  blue60: 'rgba(234, 243, 255, 0.6)',
  blue90: '#2D3A5F',
  blue91: 'rgba(45, 58, 95, 1)',
  green50: '#52C41A',
  green100: '#029947',
  red: '#FF1E1E',
  yellow10: 'rgba(255, 255, 246, 1)',
  yellow11: 'rgba(255, 255, 231, 1)',
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    skyBlue: string;
    lightBlue: string;
    black1: string;
    black6: string;
    black8: string;
    black10: string;
    black15: string;
    black20: string;
    black25: string;
    black45: string;
    black50: string;
    black60: string;
    black85: string;
    gray1: string;
    gray5: string;
    gray10: string;
    gray20: string;
    gray30: string;
    gray35: string;
    gray40: string;
    gray41: string;
    gray50: string;
    gray60: string;
    gray70: string;
    gray80: string;
    blue30: string;
    blue60: string;
    blue90: string;
    blue91: string;
    green50: string;
    green100: string;
    red: string;
    yellow10: string;
    yellow11: string;
  }

  interface PaletteOptions {
    skyBlue: string;
    lightBlue: string;
    black1: string;
    black6: string;
    black8: string;
    black10: string;
    black15: string;
    black20: string;
    black25: string;
    black45: string;
    black50: string;
    black60: string;
    black85: string;
    gray1: string;
    gray5: string;
    gray10: string;
    gray20: string;
    gray30: string;
    gray35: string;
    gray40: string;
    gray41: string;
    gray50: string;
    gray60: string;
    gray70: string;
    gray80: string;
    blue30: string;
    blue60: string;
    blue90: string;
    blue91: string;
    green50: string;
    green100: string;
    red: string;
    yellow10: string;
    yellow11: string;
  }
}
