import { EURIcon, GBPIcon, USDIcon } from 'components';
import { API_URL } from 'constants/index';
import dayjs from 'dayjs';
import { GetEarnedPaidDataByUserQuery, GetUserByIdQuery } from 'graphql/generated/graphql';

export type UserInfoDataType = {
  userData?: GetUserByIdQuery['getUserById'];
  earnedData?: GetEarnedPaidDataByUserQuery['getEarnedPaidDataByUser'];
};

export const getUserInfoData = ({ userData, earnedData }: UserInfoDataType) => {
  if (userData && earnedData) {
    const { id, full_name, email, birthday, phone_number, profit, avatar } = userData;
    const { EUR, GBP, USD } = earnedData;

    return {
      id: id,
      username: full_name,
      role: 'Manager',
      profit: profit,
      photo: `${API_URL}/${avatar?.path}`,
      detailInfo: [
        {
          label: 'Email',
          value: email,
        },
        {
          label: 'Birth day',
          value: dayjs(birthday).format('DD.MM.YYYY'),
        },
        {
          label: 'Phone number',
          value: phone_number,
        },
      ],
      earnedInfo: [
        {
          Icon: EURIcon,
          value: EUR,
        },
        {
          Icon: USDIcon,
          value: USD,
        },
        {
          Icon: GBPIcon,
          value: GBP,
        },
      ],
    };
  }
};
