import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const step2Schema = yup.object().shape({
  users: yup
    .array()
    .of(
      yup.object().shape({
        photo: yup.string().required(),
        label: yup.string().required(),
        id: yup.number().required(),
      }),
    )
    .min(1, MESSAGES.MIN_LENGTH('1')),
});
