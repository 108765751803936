import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { RequestHandler, TextFieldRHF } from 'components/UI';
import { defaultValuesMembership, submitMembership } from 'connectors/Membership';
import { useModalContext } from 'context';
import {
  CreateMembershipMutationVariables,
  UpdateMembershipMutationVariables,
  useCreateMembershipMutation,
  useGetMembershipByIdQuery,
  useUpdateMembershipMutation,
} from 'graphql/generated/graphql';
import { GET_MEMBERSHIPS } from 'graphql/queries';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createEditMembershipSchema } from 'validation';

import { AddEditMembershipFormData } from './types';

export const AddEditMembershipModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const membershipId = infoModal?.content?.id;
  const isEdit = !!membershipId;

  const { data: membershipData, loading: membershipLoading } = useGetMembershipByIdQuery({
    variables: {
      getMembershipByIdId: membershipId,
    },
    skip: !membershipId,
    fetchPolicy: 'network-only',
  });

  const [createMembership, { loading: createMembershipLoading }] = useCreateMembershipMutation({
    onCompleted: () => {
      toast.success('Membership created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_MEMBERSHIPS],
  });

  const [updateMembership, { loading: updateMembershipLoading }] = useUpdateMembershipMutation({
    onCompleted: () => {
      toast.success('Membership updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_MEMBERSHIPS],
  });

  const methods = useForm<AddEditMembershipFormData>({
    defaultValues: defaultValuesMembership(membershipData),
    resolver: yupResolver(createEditMembershipSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (data: AddEditMembershipFormData) => {
    isEdit
      ? updateMembership({
          variables: {
            ...(submitMembership(data) as UpdateMembershipMutationVariables),
          },
        })
      : createMembership({
          variables: {
            ...(submitMembership(data) as CreateMembershipMutationVariables),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesMembership(membershipData));
  }, [membershipId, membershipData]);

  return (
    <RequestHandler loading={membershipLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant='h3' mb={'32px'}>
            {!isEdit ? 'Add new membership' : 'Edit membership'}
          </Typography>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='title' label='Title' placeholder='Enter title' />
            <TextFieldRHF name='brand' label='Brand' placeholder='Enter brand' />
          </Stack>
          <Stack>
            <TextFieldRHF
              name='participating'
              label='Participating'
              placeholder='Enter participating'
            />
            <TextFieldRHF
              name='benefits'
              label='Exclusive Benefits'
              placeholder='Enter exclusive benefits'
              multiline
              minRows={3}
              maxRows={3}
            />
            <TextFieldRHF
              name='usage'
              label='How to use'
              placeholder='Enter how to use'
              multiline
              minRows={3}
              maxRows={3}
            />
            <TextFieldRHF name='link' label='Link' placeholder='Enter link' />
            <TextFieldRHF
              name='comment'
              label='Comment'
              subLabel='Optional'
              placeholder='Enter comment'
              multiline
              minRows={3}
              maxRows={3}
            />
          </Stack>
          <Stack>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createMembershipLoading || updateMembershipLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
