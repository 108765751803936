import { styled, TableHead } from '@mui/material';

export type TableHeadCSSPropsType = {
  iseditable?: string;
};

export const TableHeadCSS = styled(TableHead)<TableHeadCSSPropsType>(({ theme, iseditable }) => {
  const isEditable = iseditable === 'true';
  return {
    flex: '0 0 auto',
    width: '100%',
    background: isEditable ? theme.palette.blue30 : theme.palette.lightBlue,
  };
});
