import { palette } from './palette';

export const typography = {
  fontFamily: 'Roboto, sans-serif',
  allVariants: {
    WebkitTapHighlightColor: `${palette.secondary.main}`,
  },
  h2: {
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '48px',
    color: `${palette.secondary.main}`,
  },
  h3: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '40px',
    color: `${palette.black85}`,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    color: `${palette.black85}`,
  },
  h5: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: `${palette.black85}`,
  },
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: `${palette.black85}`,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: `${palette.gray60}`,
  },
  error: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: palette.error.main,
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    error: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}
