import { RequestHandler } from 'components';
import { LS } from 'constants/index';
import { useCurrentUserLazyQuery } from 'graphql/generated/graphql';
import React, { useContext, useEffect, useState } from 'react';

type AuthContextType = {
  isAuth: boolean;
  signIn: () => void;
  signOut: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  isAuth: false,
  signIn: () => {},
  signOut: () => {},
});

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

type AuthContextProviderType = {
  children: React.ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProviderType) {
  const [isAuth, setIsAuth] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const signIn = () => {
    setIsInit(true);
    setIsAuth(true);
  };

  const signOut = () => {
    setIsInit(true);
    setIsAuth(false);
    localStorage.removeItem(LS.TOKEN);
  };

  const [currentUserQuery, { loading, error: userError }] = useCurrentUserLazyQuery({
    onCompleted: () => {
      signIn();
    },
    onError: () => {
      signOut();
    },
  });

  useEffect(() => {
    const token = localStorage.getItem(LS.TOKEN);

    if (token) {
      currentUserQuery();
    } else {
      setIsInit(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        signIn: signIn,
        signOut: signOut,
      }}
    >
      <RequestHandler loading={loading || !isInit} error={userError} height='100vh'>
        {children}
      </RequestHandler>
    </AuthContext.Provider>
  );
}
