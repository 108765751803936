import { logo } from 'assets/images';

import { Wrapper } from './styles';

type PropsType = {
  width?: number;
};

export const Logo = ({ width = 200 }: PropsType) => {
  return (
    <Wrapper>
      <img src={logo} alt='almont logo' width={width} />
    </Wrapper>
  );
};
