import { Stack, Typography } from '@mui/material';
import { RequestHandler } from 'components/UI';
import { defaultValuesStep1 } from 'connectors';
import { useModalContext } from 'context';
import { useGetAccountByIdQuery } from 'graphql/generated/graphql';
import { useState } from 'react';
import { palette } from 'theme/foundations';
import { getCurrentAccountStep } from 'utils';

import { Step1FormData } from './types';

const activeColor = (condition: boolean) => {
  return condition ? palette.black85 : palette.black45;
};

export const AddEditAccountsModal = () => {
  const { infoModal } = useModalContext();
  const accountId = infoModal?.content?.id;
  const isEdit = !!accountId;

  const { data: accountData, loading: accountLoading } = useGetAccountByIdQuery({
    variables: {
      getAccountByIdId: accountId,
    },
    skip: !accountId,
    fetchPolicy: 'network-only',
  });

  const [step, setStep] = useState(1);
  const [stepValue, setStepValue] = useState<Step1FormData>(defaultValuesStep1(accountData));

  const handleNextStep = (data: Step1FormData) => {
    setStep((prev) => prev + 1);
    setStepValue((prev) => ({ ...prev, ...data }));
  };

  return (
    <RequestHandler loading={accountLoading}>
      <Typography variant='h3' mb={'24px'}>
        {!isEdit ? 'Add new account' : 'Edit account'}
      </Typography>
      <Stack direction={'row'} gap={'8px'} mb={'32px'}>
        <Typography variant='body1' color={activeColor(step === 1)}>
          Step 1
        </Typography>
        <Typography variant='body1' color={palette.black45}>
          /
        </Typography>
        <Typography variant='body1' color={activeColor(step === 2)}>
          Step 2
        </Typography>
      </Stack>
      {getCurrentAccountStep({ step, handleNextStep, stepValue, accountData })}
    </RequestHandler>
  );
};
