import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { SuccessIcon } from 'components/Icons';
import { useModalContext } from 'context';
import { useCreateNotificationMutation } from 'graphql/generated/graphql';
import { toast } from 'react-toastify';

import { ActionsWrapper } from './styles';

export const CreatePaymentNotificationModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const userId = infoModal?.content?.userId;
  const isUpdate = infoModal?.content?.isUpdate;
  const isEarned = infoModal?.content?.isEarned;
  const description = infoModal?.content?.description;
  const userProfitValue = infoModal?.content?.userProfitValue;
  const currencyLabel = infoModal?.content?.currencyLabel;

  const notificationTitle = isEarned ? 'Earned' : 'Paid';

  const notificationText = isEarned
    ? `You earned ${currencyLabel}${userProfitValue} ${description}`
    : `You get new payment ${currencyLabel}${userProfitValue} ${description}`;

  const JSONData = JSON.stringify({
    type: isEarned ? 'EARN' : 'PAID',
    description,
    userProfitValue,
    currencyLabel,
  });

  const title = `${!isUpdate ? 'New ' : ''}"${notificationTitle}" ${!isUpdate ? 'added' : 'updated'}`;

  const [createNotification, { loading }] = useCreateNotificationMutation({
    onCompleted: () => {
      toast.success('Notification created successfully', {
        position: 'bottom-right',
      });

      onCloseModal();
    },
  });

  const onSubmit = () => {
    createNotification({
      variables: {
        notification: {
          title: isEarned ? 'New earned' : 'New paid',
          text: notificationText,
        },
        options: {
          userIds: [userId],
        },
        data: JSONData,
        skipHistory: true,
      },
    });
  };

  return (
    <Stack>
      <Stack direction={'row'} mb='24px'>
        <SuccessIcon />
        <Box ml='16px'>
          <Typography variant='h4' lineHeight={'24px'} mb='8px'>
            {title}
          </Typography>
          <Typography variant='h5' fontWeight={'400'}>
            Send notification for user?
          </Typography>
        </Box>
      </Stack>

      <ActionsWrapper>
        <LoadingButton
          sx={{
            width: 'fit-content',
            padding: '4px 15px',
          }}
          onClick={onSubmit}
          loading={loading}
        >
          Send
        </LoadingButton>
        <Button
          variant='outlined'
          sx={{ width: 'fit-content', padding: '4px 15px' }}
          onClick={onCloseModal}
        >
          Don’t send
        </Button>
      </ActionsWrapper>
    </Stack>
  );
};
