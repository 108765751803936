import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Avatar, Stack, Typography } from '@mui/material';
import { UsersIcon } from 'components/Icons';
import {
  AutocompeteRHF,
  CheckboxRHF,
  DatePickerRHF,
  RequestHandler,
  SectionItemAutocomplete,
  TextFieldRHF,
} from 'components/UI';
import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import {
  defaultValuesUser,
  getSectionsOptions,
  getSuccessManagersOptions,
  submitUser,
} from 'connectors';
import { API_URL } from 'constants/index';
import { useModalContext } from 'context';
import {
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  useCreateUserMutation,
  useGetSectionsQuery,
  useGetSuccessManagerNamesQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from 'graphql/generated/graphql';
import { GET_SUCCESS_MANAGERS, GET_USER_BY_ID, GET_USERS } from 'graphql/queries';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { palette } from 'theme/foundations';
import { createEditUserSchema } from 'validation';

import { AddEditUserFormData } from './types';

export const AddEditUserModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const userId = infoModal?.content?.id;
  const isEdit = !!userId;

  const { data: userData, loading: userLoading } = useGetUserByIdQuery({
    variables: {
      getUserByIdId: userId,
    },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  const { data: successManagersData } = useGetSuccessManagerNamesQuery({
    fetchPolicy: 'network-only',
  });

  const { data: sectionsData, loading: sectionsLoading } = useGetSectionsQuery({
    fetchPolicy: 'network-only',
  });

  const sectionsOptions = getSectionsOptions(sectionsData);

  const successManagersAll = getSuccessManagersOptions(successManagersData);

  const successManagers = successManagersAll?.filter((manager) => manager?.id !== userId);

  const avatarPath = userData?.getUserById?.avatar?.path;
  const photo = avatarPath ? `${API_URL}/${avatarPath}` : '';

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation({
    onCompleted: () => {
      toast.success('User created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_USERS],
  });

  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation({
    onCompleted: () => {
      toast.success('User updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_USERS, GET_USER_BY_ID, GET_SUCCESS_MANAGERS],
  });

  const methods = useForm<AddEditUserFormData>({
    defaultValues: defaultValuesUser(userData, sectionsOptions as AutocompeteOptionType[]),
    resolver: yupResolver(createEditUserSchema),
  });

  const { handleSubmit, reset, watch, setValue } = methods;

  const pickedSections = watch('sections');

  const removeSection = (id?: number) => {
    setValue(
      'sections',
      pickedSections?.filter((section) => section.id !== id),
    );
  };

  const onSubmit = (data: AddEditUserFormData) => {
    isEdit
      ? updateUser({
          variables: {
            ...(submitUser(data) as UpdateUserMutationVariables),
          },
        })
      : createUser({
          variables: {
            ...(submitUser(data) as CreateUserMutationVariables),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesUser(userData, sectionsOptions as AutocompeteOptionType[]));
  }, [userId, userData, sectionsData]);

  return (
    <RequestHandler loading={userLoading || sectionsLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant='h3' mb={'32px'}>
            {!isEdit ? 'Add new user' : 'Edit user'}
          </Typography>
          {isEdit && (
            <Stack direction={'row'} mb='32px' justifyContent={'center'}>
              <Avatar sx={{ width: 128, height: 128 }} src={photo}>
                <UsersIcon size={64} fill={palette.secondary.main} />
              </Avatar>
            </Stack>
          )}
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='fullName' label='Full name' placeholder='Enter full name' />
            <TextFieldRHF name='email' label='Email for login in app' placeholder='Enter email' />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='profit' label='Profit' placeholder='Enter % profit' type='number' />
            <TextFieldRHF name='phone' label='Mobile phone' placeholder='Enter mobile phone' />
          </Stack>
          <Stack direction={'row'} gap='24px'>
            <DatePickerRHF name='dateOfBirth' label='Date of birth' disableFuture />
            <Stack sx={{ width: '100%' }}>
              <AutocompeteRHF
                name='successManager'
                label='Set your success manager'
                subLabel='Optional'
                multiple={false}
                options={(successManagers || []) as AutocompeteOptionType[]}
              />
            </Stack>
          </Stack>

          {!!pickedSections?.length && (
            <Stack mb={'24px'} gap={'8px'}>
              <Typography>Available user sections</Typography>
              <Stack direction={'row'} flexWrap={'wrap'} gap={'24px'}>
                {pickedSections?.map((section) => {
                  return (
                    <SectionItemAutocomplete
                      section={section}
                      handleClick={removeSection}
                      key={section.id}
                    />
                  );
                })}
              </Stack>
            </Stack>
          )}
          <AutocompeteRHF
            options={sectionsOptions as AutocompeteOptionType[]}
            name='sections'
            label={'Choose available user sections'}
          />

          <CheckboxRHF name='isSuccessManager' label='Set as Success manager' />
          <Stack mt={'20px'}>
            <TextFieldRHF
              name='comment'
              label='Comment'
              subLabel='Optional'
              placeholder='Enter comment'
              multiline
              minRows={3}
              maxRows={3}
            />
          </Stack>
          <Stack>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createUserLoading || updateUserLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
