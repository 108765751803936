import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const ContactsIcon = ({ fill = palette.gray60 }: PropsType) => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1189_10780)'>
        <path
          d='M9.70818 12.2503C10.7362 12.2503 11.4118 11.9707 12.0051 11.2925C12.052 11.2449 12.0991 11.1913 12.1402 11.1438C12.4927 10.7451 12.6572 10.3525 12.6572 9.97768C12.6572 9.54936 12.4104 9.15073 11.8876 8.78185L10.1781 7.58008C9.65529 7.21125 9.03259 7.16962 8.53912 7.66339L8.08681 8.12152C7.95171 8.25833 7.83421 8.26427 7.70498 8.18096C7.38777 7.97871 6.74742 7.41351 6.31271 6.97325C5.85451 6.51518 5.41393 6.00354 5.17895 5.62872C5.10258 5.49189 5.10845 5.37885 5.24356 5.24201L5.69003 4.78392C6.18347 4.28417 6.14236 3.65354 5.77814 3.12405L4.5915 1.39279C4.22728 0.863302 3.83369 0.619379 3.41073 0.61343C3.04064 0.607481 2.65292 0.780012 2.25934 1.13697C2.20647 1.18456 2.15947 1.22621 2.11248 1.26786C1.44279 1.87469 1.16669 2.55886 1.16669 3.5881C1.16669 5.28961 2.20059 7.36 4.09805 9.28161C5.98375 11.1913 8.03393 12.2503 9.70818 12.2503ZM9.71404 11.3341C8.21604 11.3639 6.29511 10.1978 4.77361 8.6629C3.24037 7.11606 2.03611 5.10518 2.06548 3.5881C2.07723 2.93367 2.30633 2.36848 2.76454 1.96393C2.80566 1.92823 2.83503 1.89848 2.87616 1.86874C3.05239 1.71406 3.24037 1.63077 3.41073 1.63077C3.58109 1.63077 3.73383 1.69621 3.84544 1.87469L4.98509 3.60594C5.10845 3.79038 5.1202 3.9986 4.93809 4.18303L4.42114 4.70657C4.0158 5.11708 4.04518 5.61682 4.3389 6.01542C4.67374 6.47355 5.25531 7.13987 5.70765 7.59201C6.15411 8.05009 6.86492 8.69265 7.3231 9.03767C7.71671 9.33517 8.21018 9.36492 8.61552 8.95441L9.13245 8.43084C9.31457 8.24645 9.51428 8.25833 9.70226 8.37733L11.4118 9.53149C11.5821 9.65049 11.6526 9.79918 11.6526 9.97768C11.6526 10.1502 11.5704 10.3406 11.4176 10.5191C11.3824 10.5607 11.3589 10.5904 11.3236 10.6321C10.9241 11.0961 10.3661 11.3222 9.71404 11.3341Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_1189_10780'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
