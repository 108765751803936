import { AddNotificationFormData } from 'components/Modal/UserModals/AddNotificationsModal/types';

export const submitNotification = (data: AddNotificationFormData) => {
  const { title, link, text, users, isSendNotificationAll } = data;

  return {
    notification: {
      text: text,
      title: title,
    },
    options: {
      ...(isSendNotificationAll && { allUsers: isSendNotificationAll }),
      ...(!isSendNotificationAll && { userIds: users?.map((user) => user.id) }),
    },
    data: JSON.stringify({ link, title }),
    skipHistory: false,
  };
};
