import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

export const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '100vh',
  overflow: 'hidden',
}));
