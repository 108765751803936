import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useModalContext } from 'context';
import { FileObject, useDeleteEducationMutation } from 'graphql/generated/graphql';
import { GET_EDUCATIONS } from 'graphql/queries';
import { toast } from 'react-toastify';
import { getDocumentFilesTypes } from 'utils';

import { ActionsWrapper } from './styles';

export const ConfirmDeleteEducationModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const educationId = infoModal?.content?.id;
  const educationTitle = infoModal?.content?.title;
  const files = infoModal?.content?.files as FileObject[];
  const type = getDocumentFilesTypes(files);

  const [deleteEducation, { loading: deleteEducationLoading }] = useDeleteEducationMutation({
    onCompleted: () => {
      toast.success('Membership deleted successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_EDUCATIONS],
  });

  const onSubmit = () => {
    deleteEducation({
      variables: {
        deleteEducationId: educationId,
      },
    });
  };

  return (
    <Stack gap={'24px'}>
      <Typography variant='h4' lineHeight={'32px'}>
        Are you sure you want to delete {type} “{educationTitle}”?
      </Typography>
      <ActionsWrapper>
        <Button sx={{ maxWidth: '75px', padding: '4px 15px' }} onClick={onCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          variant='outlined'
          sx={{ maxWidth: '75px', padding: '4px 15px' }}
          loading={deleteEducationLoading}
          onClick={onSubmit}
        >
          Delete
        </LoadingButton>
      </ActionsWrapper>
    </Stack>
  );
};
