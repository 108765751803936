import { CurrencyEnum } from 'graphql/generated/graphql';

import { AddEditEarnedFormData } from './types';

export const submitEarned = (data: AddEditEarnedFormData) => {
  const { invoice, title, date, description, bookingAmount, markUp, userProfit, userId, currency } =
    data;

  const totalProfit = (Number(bookingAmount) / 100) * Number(markUp);
  const userProfitValue = (totalProfit / 100) * Number(userProfit);
  const almontProfitValue = totalProfit - userProfitValue;

  return {
    input: {
      almont_account: Number(almontProfitValue.toFixed(2)),
      booking_amount: Number(bookingAmount),
      currency: currency as CurrencyEnum,
      date_to_travel: String(new Date(date).getTime()),
      description,
      invoice: Number(invoice),
      mark_up: Number(markUp),
      title,
      total_profit: Number(totalProfit.toFixed(2)),
      user_account: Number(userProfitValue.toFixed(2)),
      user_id: Number(userId),
      current_profit: Number(userProfit),
    },
  };
};
