import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { SuccessIcon } from 'components/Icons';
import { useModalContext } from 'context';

import { ActionsWrapper } from './styles';

export const AddNewEarnedUserModal = () => {
  const { onCloseModal } = useModalContext();

  const onSubmit = () => {};

  return (
    <Stack gap={'24px'}>
      <Stack direction={'row'} gap='16px' maxWidth={'352px'}>
        <SuccessIcon />
        <Stack gap='8px'>
          <Typography variant='h5'>New “Earned” added</Typography>
          <Typography variant='body1' fontSize={'16px'}>
            Send notification for user?
          </Typography>
        </Stack>
      </Stack>
      <ActionsWrapper>
        <LoadingButton
          variant='contained'
          sx={{ maxWidth: '64px', padding: '4px 15px' }}
          // loading={loading}
          onClick={onSubmit}
        >
          Send
        </LoadingButton>
        <Button
          variant='outlined'
          sx={{ maxWidth: '98px', padding: '4px 15px', textWrap: 'nowrap' }}
          onClick={onCloseModal}
        >
          Don't send
        </Button>
      </ActionsWrapper>
    </Stack>
  );
};
