import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectRHFProps } from './types';

export const SelectRHF = ({ name, label, options, mb, sx }: SelectRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <Stack width={'100%'} mb={mb} sx={sx}>
            {label && (
              <Box mb='8px' display={'flex'}>
                <Typography variant='body1'>{label}</Typography>
              </Box>
            )}
            <FormControl>
              <Select value={value} onChange={onChange}>
                {options.map(({ value, label }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box height={20} />
          </Stack>
        </>
      )}
    />
  );
};
