import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const createAccountSchema = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  link: yup.string().url(MESSAGES.INCORRECT_LINK).required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  login: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  comment: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  users: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        id: yup.number().required(),
      }),
    )
    .min(1, MESSAGES.MIN_LENGTH('1')),
});
