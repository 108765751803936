import { Box, Stack, Typography } from '@mui/material';
import { DeleteIcon, PDFIcon, PlusIcon, TXTIcon } from 'components/Icons';
import { FILE_MIMETYPES } from 'constants/index';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { palette } from 'theme/foundations';

import { ActionWrapper, ImagePlaceholderBlock, Wrapper } from './styles';
import { DropzoneDocsRHFPropsType, DropzonePropsType } from './types';

export const DropzoneDocsRHF = ({ type, name, sx, mb }: DropzoneDocsRHFPropsType) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        const handleChange = (file: File[]) => {
          // fix for Firefox download file from FileDialog
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
          }, 0);
        };
        return (
          <Dropzone
            hasError={!!errors[name]}
            value={getValues(name)}
            onChange={handleChange}
            sx={sx}
            mb={mb}
            type={type}
          />
        );
      }}
    />
  );
};

const Dropzone = ({ value, onChange, sx, mb, hasError, type }: DropzonePropsType) => {
  const onDrop = (acceptedFiles: File[]) => {
    onChange(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const { getRootProps } = useDropzone({
    ...{
      multiple: false,
      accept: {
        ...(type === FILE_MIMETYPES.PDF && { [FILE_MIMETYPES.PDF]: [] }),
        ...(type === FILE_MIMETYPES.TXT && { [FILE_MIMETYPES.TXT]: [] }),
      },
    },
    onDrop,
  });

  const onDelete = () => {
    onChange(null);
  };

  const convertBytesToMegabytes = () => {
    if (value?.size) {
      const megabytes = value?.size / (1024 * 1024);

      return megabytes.toFixed(megabytes < 0.1 ? 3 : 1) + ' mb';
    }
    return '';
  };

  return (
    <Wrapper sx={sx} mb={mb}>
      <Stack>
        <ImagePlaceholderBlock haserror={String(hasError)}>
          <ActionWrapper sx={{ cursor: 'pointer' }}>
            {value ? (
              <Stack onClick={onDelete}>
                <DeleteIcon fill={palette.black85} />
              </Stack>
            ) : (
              <Stack {...getRootProps()}>
                <PlusIcon size={20} fill={palette.black85} />
              </Stack>
            )}
          </ActionWrapper>
          <a href={value?.preview} target='_blank'>
            {type === FILE_MIMETYPES.PDF ? <PDFIcon /> : <TXTIcon />}
          </a>
        </ImagePlaceholderBlock>
      </Stack>
      <Box>
        <Typography
          variant={hasError ? 'error' : 'body2'}
          fontSize={'14px'}
          lineHeight={'22px'}
          mt={'5px'}
          component='p'
        >
          {`${type === FILE_MIMETYPES.PDF ? 'Pdf' : 'Txt'} ${convertBytesToMegabytes()}`}
        </Typography>
      </Box>
    </Wrapper>
  );
};
