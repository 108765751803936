import { Stack, Typography } from '@mui/material';
import { DocumentIcon } from 'components/Icons';
import { API_URL } from 'constants/index';
import { palette } from 'theme/foundations';
import { getDocumentFileSize, getDocumentFileType } from 'utils';

import { DocType } from './styles';
import { DocumentsInfoType } from './types';

export const DocumentsInfo = ({ photos }: DocumentsInfoType) => {
  return photos?.length ? (
    <Stack direction={'row'} gap={'26px 32px'} flexWrap={'wrap'}>
      {photos.map(({ id, mimetype, path, size }) => {
        const fileType = getDocumentFileType(mimetype);
        const fileSize = getDocumentFileSize(size);
        const filePath = `${API_URL}/${path}`;

        return (
          <Stack gap={'10px'} key={id}>
            <a href={filePath} target='_blank'>
              <DocType>
                <DocumentIcon />
              </DocType>
            </a>
            <Stack width={'100%'} justifyContent={'flex-end'}>
              <Typography variant='body1' color={palette.black45}>
                {`${fileType} ${fileSize}`}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  ) : null;
};
