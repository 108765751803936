import { API_URL } from 'constants/index';
import { GetAccountByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesStep2 = (data?: GetAccountByIdQuery) => {
  if (data && data.getAccountById) {
    const { users } = data.getAccountById;

    return {
      users:
        users?.map(({ id, full_name, avatar }) => {
          return { id: Number(id), label: String(full_name), photo: `${API_URL}/${avatar?.path}` };
        }) || [],
    };
  }

  return {
    users: [],
  };
};
