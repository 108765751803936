import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useModalContext } from 'context';
import { useDeleteUserMutation } from 'graphql/generated/graphql';
import { GET_USERS } from 'graphql/queries';
import { toast } from 'react-toastify';

import { ActionsWrapper } from './styles';

export const ConfirmDeleteUserModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const userId = infoModal?.content?.id;
  const username = infoModal?.content?.username;
  const handleNavigate = infoModal?.content?.handleNavigate;

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    onCompleted: () => {
      toast.success('User deleted successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
      handleNavigate?.();
    },
    refetchQueries: [GET_USERS],
  });

  const onSubmit = () => {
    deleteUser({
      variables: {
        deleteUserId: userId,
      },
    });
  };

  return (
    <Stack gap={'24px'}>
      <Typography variant='h4' lineHeight={'32px'}>
        Are you sure delete user {username}?
      </Typography>
      <ActionsWrapper>
        <Button sx={{ maxWidth: '75px', padding: '4px 15px' }} onClick={onCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          variant='outlined'
          sx={{ maxWidth: '75px', padding: '4px 15px' }}
          loading={deleteUserLoading}
          onClick={onSubmit}
        >
          Delete
        </LoadingButton>
      </ActionsWrapper>
    </Stack>
  );
};
