import { gql } from '@apollo/client';

export const GET_EDUCATIONS = gql`
  query getEducations($input: FilterEducationInput!) {
    getEducations(input: $input) {
      count
      rows {
        title
        id
        files {
          id
          mimetype
          path
          size
        }
        comment
        type
      }
    }
  }
`;
