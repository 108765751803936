import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const EducationIcon = ({ fill = palette.gray60 }: PropsType) => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.6845 4.50297L8.62203 1.44047C8.58137 1.39985 8.53311 1.36765 8.48 1.34569C8.42689 1.32373 8.36997 1.31245 8.3125 1.3125H3.0625C2.83044 1.3125 2.60788 1.40469 2.44378 1.56878C2.27969 1.73288 2.1875 1.95544 2.1875 2.1875V11.8125C2.1875 12.0446 2.27969 12.2671 2.44378 12.4312C2.60788 12.5953 2.83044 12.6875 3.0625 12.6875H10.9375C11.1696 12.6875 11.3921 12.5953 11.5562 12.4312C11.7203 12.2671 11.8125 12.0446 11.8125 11.8125V4.8125C11.8125 4.75503 11.8013 4.69811 11.7793 4.645C11.7574 4.59189 11.7251 4.54363 11.6845 4.50297ZM8.75 2.80602L10.319 4.375H8.75V2.80602ZM10.9375 11.8125H3.0625V2.1875H7.875V4.8125C7.875 4.92853 7.92109 5.03981 8.00314 5.12186C8.08519 5.20391 8.19647 5.25 8.3125 5.25H10.9375V11.8125Z'
        fill={fill}
      />
    </svg>
  );
};
