import { FILE_MIMETYPES } from 'constants/index';
import { FileObject } from 'graphql/generated/graphql';

export const getDocumentFileType = (type: string) => {
  switch (type) {
    case FILE_MIMETYPES.JPEG:
      return 'Jpeg';
    default:
      return '';
  }
};

export const getDocumentFilesTypes = (files: FileObject[]) => {
  return files
    ?.map((file) =>
      file.mimetype === FILE_MIMETYPES.PDF
        ? 'PDF_file'
        : file.mimetype === FILE_MIMETYPES.TXT
          ? 'TXT_file'
          : '',
    )
    .join(', ');
};
