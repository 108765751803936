import { COLUMN_IDS, TABLE_SORT_FIELDS } from 'components/UI/TableList/constants';
import { CurrencyEnum } from 'graphql/generated/graphql';
import {
  CRUISES_CONTACTS_TABLE_OPTIONS,
  DMC_CONTACTS_TABLE_OPTIONS,
  HOTEL_CONTACTS_TABLE_OPTIONS,
  OTHER_CONTACTS_TABLE_OPTIONS,
} from 'pages/Contacts/constants';

export const getOptionsForContactsTable = (
  tabIndex: number,
  handleChangeSort: (field: TABLE_SORT_FIELDS) => void,
) => {
  switch (tabIndex) {
    case 0:
      return () => HOTEL_CONTACTS_TABLE_OPTIONS(handleChangeSort);
    case 1:
      return () => CRUISES_CONTACTS_TABLE_OPTIONS(handleChangeSort);
    case 2:
      return () => DMC_CONTACTS_TABLE_OPTIONS(handleChangeSort);
    case 3:
      return () => OTHER_CONTACTS_TABLE_OPTIONS(handleChangeSort);
    default:
      return () => HOTEL_CONTACTS_TABLE_OPTIONS(handleChangeSort);
  }
};

// earned
export const getOptionsForEarnedTable = (currency: CurrencyEnum, userName: string) => {
  const EARN_TABLE_OPTIONS = [
    { id: COLUMN_IDS.INVOICE, label: 'Invoice', minWidth: 115, type: 'number' },
    { id: COLUMN_IDS.TITLE, label: 'Title', minWidth: 115 },
    { id: COLUMN_IDS.DATE, label: 'Dates of travel', minWidth: 210, type: 'date' },
    { id: COLUMN_IDS.DESCRIPTION, label: 'Description', minWidth: 115 },
    {
      id: COLUMN_IDS.BOOKING_AMOUNT,
      label: `Booking amount, ${currency}`,
      minWidth: 115,
      type: 'number',
    },
    { id: COLUMN_IDS.MARK_UP, label: 'Mark up, %', minWidth: 115, type: 'number' },
    {
      id: COLUMN_IDS.USER_PROFIT,
      label: `${userName} profit, %`,
      minWidth: 115,
      type: 'number',
    },
    {
      id: COLUMN_IDS.TOTAL_PROFIT,
      label: `Total profit, ${currency}`,
      minWidth: 115,
      readonly: true,
    },
    {
      id: COLUMN_IDS.USER_PROFIT_VALUE,
      label: `${userName} Account, ${currency}`,
      minWidth: 115,
      readonly: true,
    },
    {
      id: COLUMN_IDS.ALMONT_PROFIT_VALUE,
      label: `Almont account, ${currency}`,
      minWidth: 160,
      readonly: true,
    },
    { id: COLUMN_IDS.ACTION, label: 'Action', minWidth: 178 },
  ];

  return EARN_TABLE_OPTIONS;
};

//paid
export const getOptionsForPaidTable = (currency: CurrencyEnum) => {
  const EARN_TABLE_OPTIONS = [
    { id: COLUMN_IDS.INVOICE, label: 'Invoice', minWidth: 115, type: 'number' },
    { id: COLUMN_IDS.DESCRIPTION, label: 'Description', minWidth: 115 },
    {
      id: COLUMN_IDS.AMOUNT,
      label: `Amount, ${currency}`,
      minWidth: 115,
      type: 'number',
    },
    { id: COLUMN_IDS.NOTE, label: 'Notes', minWidth: 115 },

    { id: COLUMN_IDS.ACTION, label: 'Action', minWidth: 130 },
  ];

  return EARN_TABLE_OPTIONS;
};
