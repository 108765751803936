import { Button, Stack, Typography } from '@mui/material';
import { Wrapper } from 'components/Modal/ModalContent/styles';

import { ActionsWrapper } from './styles';

export const ConfirmDeleteUserModal = ({
  infoModal,
  onCloseModal,
}: {
  infoModal: {
    content: Record<any, any>;
    open: boolean;
  };
  onCloseModal: VoidFunction;
}) => {
  const name = infoModal.content.name;
  const removeUser = infoModal.content.removeUser;

  const onSubmit = () => {
    removeUser();
    onCloseModal();
  };

  return (
    <Wrapper open={infoModal.open} onClose={onCloseModal} isconfirmmodal={'true'}>
      <Stack gap={'24px'}>
        <Typography variant='h4' lineHeight={'32px'}>
          Are you sure you want to delete {name}'s access to this account?
        </Typography>
        <ActionsWrapper>
          <Button sx={{ maxWidth: '75px', padding: '4px 15px' }} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            sx={{ maxWidth: '75px', padding: '4px 15px' }}
            onClick={onSubmit}
          >
            Delete
          </Button>
        </ActionsWrapper>
      </Stack>
    </Wrapper>
  );
};
