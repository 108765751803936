import { Tab, Tabs } from '@mui/material';

import { CustomTabsPropsType } from './types';

export const CustomTabs = ({ options, handleChange, value }: CustomTabsPropsType) => {
  return (
    <Tabs value={value} onChange={handleChange}>
      {options.map((option) => {
        return (
          <Tab
            label={option.label}
            id={option.label}
            key={option.index}
            sx={{ textTransform: 'none' }}
          />
        );
      })}
    </Tabs>
  );
};
