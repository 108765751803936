import { Box, styled } from '@mui/material';

import { IRightBlock } from './types';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.skyBlue,
  maxHeight: '100vh',
}));

export const LeftBlock = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '40px',
}));

export const RightBlock = styled(Box)(({ backgroundimg }: IRightBlock) => ({
  display: 'flex',
  width: '50%',
  position: 'relative',
  backgroundImage: `url(${backgroundimg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));

export const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: 'calc(100vh - 146px)',
}));

export const FormInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '420px',
  background: theme.palette.primary.main,
  padding: '56px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  form: {
    width: '100%',
  },
}));

export const ShadowBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  background: theme.palette.black20,
  width: '100%',
  minHeight: '100vh',
}));
