import { TableBody, TableContainer } from '@mui/material';
import { RequestHandler } from 'components';

import {
  EditableEarnedTableListRow,
  EditablePaidTableListRow,
  TableListHeader,
  TableListRow,
} from './components';
import { TABLE_CONTENT_TYPE } from './constants';
import { EmptyText, TableCSS, TableListWrapper } from './styles';
import { TableListType } from './types';

export const TableList = ({
  isLoading,
  headCells,
  rows,
  tableContainerSx,
  tableSx,
  sortInfo,
  isEditable,
  setTableRows,
  contentType,
  handleRow,
}: TableListType) => {
  return (
    <TableListWrapper>
      <TableContainer
        sx={{
          height: 'calc(100vh - 230px)',
          ...tableContainerSx,
        }}
      >
        <RequestHandler loading={isLoading}>
          {!rows?.length ? (
            <EmptyText variant='h3'>The table is empty</EmptyText>
          ) : (
            <TableCSS sx={{ ...tableSx }}>
              <TableListHeader headCells={headCells} sortInfo={sortInfo} isEditable={isEditable} />
              <TableBody>
                {rows.map((row, index, arr) => {
                  if (isEditable) {
                    const isLastRow = arr.length - 1 === index;
                    const key = row?.id || row?.tempId;
                    const isEarnedContentType = contentType === TABLE_CONTENT_TYPE.EARNED;

                    const EditableTableListRow = isEarnedContentType
                      ? EditableEarnedTableListRow
                      : EditablePaidTableListRow;

                    return (
                      <EditableTableListRow
                        key={key}
                        row={row}
                        headCells={headCells}
                        index={index}
                        isLastRow={isLastRow}
                        isEditable={isEditable}
                        setTableRows={setTableRows}
                        contentType={contentType}
                      />
                    );
                  }
                  return (
                    <TableListRow
                      key={index}
                      row={row}
                      headCells={headCells}
                      handleRow={handleRow}
                    />
                  );
                })}
              </TableBody>
            </TableCSS>
          )}
        </RequestHandler>
      </TableContainer>
    </TableListWrapper>
  );
};
