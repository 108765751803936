import { Stack, styled } from '@mui/material';
import { palette } from 'theme/foundations';

export const InfoItem = styled(Stack)(() => ({
  background: palette.primary.main,
  width: '100%',
  padding: '24px',
  borderRadius: '4px',
}));

export const EarnedInfoItem = styled(Stack)(() => ({
  border: `1px solid ${palette.blue90}`,
  boxShadow: `0px 2px 20px 0px ${palette.black8}`,
  width: '100%',
  padding: '16px',
  borderRadius: '4px',
  gap: '2px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
