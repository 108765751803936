import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
  size?: number;
};

export const UsersIcon = ({ fill = palette.gray60, size = 14 }: PropsType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.6284 11.5933C11.7955 10.1534 10.512 9.12086 9.01414 8.63141C9.75506 8.19033 10.3307 7.51823 10.6527 6.71833C10.9747 5.91842 11.0252 5.03494 10.7964 4.20356C10.5677 3.37217 10.0724 2.63886 9.38655 2.11623C8.70071 1.5936 7.86227 1.31055 7 1.31055C6.13772 1.31055 5.29929 1.5936 4.61345 2.11623C3.92761 2.63886 3.43229 3.37217 3.20356 4.20356C2.97482 5.03494 3.02532 5.91842 3.3473 6.71833C3.66928 7.51823 4.24493 8.19033 4.98586 8.63141C3.48796 9.12032 2.20445 10.1528 1.37156 11.5933C1.34101 11.6431 1.32076 11.6985 1.31198 11.7563C1.3032 11.814 1.30607 11.873 1.32044 11.9296C1.3348 11.9862 1.36037 12.0394 1.39562 12.086C1.43087 12.1326 1.47509 12.1716 1.52568 12.2009C1.57627 12.2301 1.6322 12.2489 1.69017 12.2561C1.74814 12.2634 1.80698 12.259 1.86321 12.2431C1.91944 12.2272 1.97193 12.2003 2.01757 12.1638C2.06322 12.1274 2.1011 12.0821 2.12898 12.0308C3.15929 10.2502 4.98039 9.18703 7 9.18703C9.01961 9.18703 10.8407 10.2502 11.871 12.0308C11.8989 12.0821 11.9368 12.1274 11.9824 12.1638C12.0281 12.2003 12.0806 12.2272 12.1368 12.2431C12.193 12.259 12.2518 12.2634 12.3098 12.2561C12.3678 12.2489 12.4237 12.2301 12.4743 12.2009C12.5249 12.1716 12.5691 12.1326 12.6044 12.086C12.6396 12.0394 12.6652 11.9862 12.6796 11.9296C12.6939 11.873 12.6968 11.814 12.688 11.7563C12.6792 11.6985 12.659 11.6431 12.6284 11.5933ZM3.9375 5.24953C3.9375 4.64383 4.11711 4.05173 4.45362 3.5481C4.79013 3.04448 5.26843 2.65195 5.82803 2.42015C6.38763 2.18836 7.00339 2.12771 7.59746 2.24588C8.19153 2.36405 8.73721 2.65572 9.16551 3.08402C9.59381 3.51232 9.88548 4.058 10.0037 4.65207C10.1218 5.24614 10.0612 5.8619 9.82938 6.4215C9.59758 6.9811 9.20506 7.4594 8.70143 7.79591C8.1978 8.13242 7.6057 8.31203 7 8.31203C6.18804 8.31117 5.40958 7.98823 4.83544 7.41409C4.2613 6.83995 3.93836 6.06149 3.9375 5.24953Z'
        fill={fill}
      />
    </svg>
  );
};
