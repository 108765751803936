import { Avatar, IconButton, Typography } from '@mui/material';
import { CloseIcon, UsersIcon } from 'components/Icons';
import { palette } from 'theme/foundations';

import { AutocompeteOptionType } from '../AutocompeteRHF/types';
import { UserItem } from './styles';

export type UserItemAutocompleteProps = {
  user?: AutocompeteOptionType;
  handleClick: (id?: number, name?: string) => void;
};

export const UserItemAutocomplete = ({ user, handleClick }: UserItemAutocompleteProps) => {
  return (
    <UserItem key={user?.id}>
      <Avatar sx={{ width: 24, height: 24 }} src={user?.photo}>
        <UsersIcon size={20} fill={palette.secondary.main} />
      </Avatar>
      <Typography variant='body1'>{user?.label}</Typography>
      <IconButton
        sx={{ marginLeft: 'auto', padding: 0 }}
        onClick={() => handleClick(user?.id, user?.label)}
      >
        <CloseIcon />
      </IconButton>
    </UserItem>
  );
};
