import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValuesEarned } from 'connectors/Earned';
import { AddEditEarnedFormData } from 'connectors/Earned/types';
import { defaultValuesPaid } from 'connectors/Paid';
import { AddEditPaidFormData } from 'connectors/Paid/types';
import { UserRoleEnum } from 'graphql/generated/graphql';
import { useCRUDPayments } from 'hooks';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { palette } from 'theme/foundations';
import { getEditibleTableSellValue, getUserRoleEnum } from 'utils';
import { createEditEarnedSchema, createEditPaidSchema } from 'validation';

import { TableCellCSS, TableRowCSS } from '../../styles';
import { EditibleTableCellContent, TableCellContent } from '../TableCellContent';
import { TableListRowType } from './types';

export const TableListRow = ({ row, headCells, handleRow }: TableListRowType) => {
  return (
    <>
      <TableRowCSS
        key={row.id}
        onClick={(event) => handleRow?.(event, row?.id, row?.role)}
        sx={{
          cursor:
            handleRow && getUserRoleEnum(row?.role) !== UserRoleEnum.Customer ? 'pointer' : 'auto',
        }}
      >
        {headCells?.map((column) => {
          const value = row[column.id];

          return (
            <TableCellCSS key={column.id} width={column.minWidth}>
              <TableCellContent id={column.id} value={value} />
            </TableCellCSS>
          );
        })}
      </TableRowCSS>
    </>
  );
};

export const EditableEarnedTableListRow = ({
  row,
  headCells,
  index = 0,
  isEditable,
  isLastRow,
  setTableRows,
  contentType,
}: TableListRowType) => {
  const { handleCreateEarned, handleUpdateEarned, handleOpenDeletePayment } = useCRUDPayments({
    setTableRows,
    index,
    contentType,
  });

  const [isEditRow, setIsEditRow] = useState(row?.isInitEdit || false);

  const methods = useForm<{ row: AddEditEarnedFormData }>({
    defaultValues: {
      row: defaultValuesEarned(row),
    },

    resolver: yupResolver(createEditEarnedSchema),
  });

  const { handleSubmit, setValue, watch } = methods;

  const handleSetIsEditRow = (value: boolean) => {
    setIsEditRow(value);
  };

  const handleClearRowValue = () => {
    if (!row?.id) {
      setTableRows?.((prev) => prev.filter((item, i) => index !== i));
    } else {
      setValue?.(`row`, row as AddEditEarnedFormData);
    }
  };

  const handleDeleteRow = () => {
    handleOpenDeletePayment(row);
  };

  const onSubmit = (data: { row: AddEditEarnedFormData }) => {
    const { row } = data;

    if (row?.id) {
      handleUpdateEarned(row);
      handleSetIsEditRow(false);

      return;
    }

    handleCreateEarned(row);
  };

  const rowFormValue = watch('row');

  return (
    <FormProvider {...methods}>
      <TableRowCSS sx={{ backgroundColor: isLastRow ? palette.yellow11 : 'transparent' }}>
        {headCells?.map((column) => {
          const value = isLastRow
            ? row[column.id] ?? ''
            : getEditibleTableSellValue({
                row: rowFormValue,
                id: column.id,
              });

          return (
            <TableCellCSS key={column.id} width={column.minWidth}>
              <EditibleTableCellContent
                id={column.id}
                isEdit={isEditRow}
                value={value}
                isLastRow={isLastRow}
                textFieldType={column.type}
                isEditable={isEditable}
                readonly={column.readonly}
                handleSetIsEditRow={handleSetIsEditRow}
                handleClearRowValue={handleClearRowValue}
                handleDeleteRow={handleDeleteRow}
                onSubmit={handleSubmit(onSubmit)}
              />
            </TableCellCSS>
          );
        })}
      </TableRowCSS>
    </FormProvider>
  );
};

export const EditablePaidTableListRow = ({
  row,
  headCells,
  index = 0,
  isEditable,
  isLastRow,
  setTableRows,
  contentType,
}: TableListRowType) => {
  const { handleOpenDeletePayment, handleCreatePaid, handleUpdatePaid } = useCRUDPayments({
    setTableRows,
    index,
    contentType,
  });

  const [isEditRow, setIsEditRow] = useState(row?.isInitEdit || false);

  const methods = useForm<{ row: AddEditPaidFormData }>({
    defaultValues: {
      row: defaultValuesPaid(row),
    },

    resolver: yupResolver(createEditPaidSchema),
  });

  const { handleSubmit, setValue } = methods;

  const handleSetIsEditRow = (value: boolean) => {
    setIsEditRow(value);
  };

  const handleClearRowValue = () => {
    if (!row?.id) {
      setTableRows?.((prev) => prev.filter((item, i) => index !== i));
    } else {
      setValue?.(`row`, row as AddEditPaidFormData);
    }
  };

  const handleDeleteRow = () => {
    handleOpenDeletePayment(row);
  };

  const onSubmit = (data: { row: AddEditPaidFormData }) => {
    const { row } = data;

    if (row?.id) {
      handleUpdatePaid(row);
      handleSetIsEditRow(false);

      return;
    }

    handleCreatePaid(row);
  };

  return (
    <FormProvider {...methods}>
      <TableRowCSS sx={{ backgroundColor: isLastRow ? palette.yellow11 : 'transparent' }}>
        {headCells?.map((column) => {
          const value = row[column.id] ?? '';

          return (
            <TableCellCSS key={column.id} width={column.minWidth}>
              <EditibleTableCellContent
                id={column.id}
                isEdit={isEditRow}
                value={value}
                isLastRow={isLastRow}
                textFieldType={column.type}
                isEditable={isEditable}
                readonly={column.readonly}
                handleSetIsEditRow={handleSetIsEditRow}
                handleClearRowValue={handleClearRowValue}
                handleDeleteRow={handleDeleteRow}
                onSubmit={handleSubmit(onSubmit)}
              />
            </TableCellCSS>
          );
        })}
      </TableRowCSS>
    </FormProvider>
  );
};
