import { gql } from '@apollo/client';

export const GET_OFFERS = gql`
  query getOffers($input: OfferFiltersInput!) {
    getOffers(input: $input) {
      count
      rows {
        title
        id
        date_from
        date_to
        price_from
        price_to
        city
        currency
        information
        link
        additional_data {
          country
          id
          comment
          contact_person
          created_at
          emails {
            additional_data_id
            created_at
            email
            id
            email
            updated_at
          }
          phones {
            additional_data_id
            created_at
            phone
            id
            updated_at
          }
          updated_at
          whats_app
        }
      }
    }
  }
`;
