import { Pagination, Stack } from '@mui/material';
import { PageContentWrapper, PageHeader, RequestHandler, TableList } from 'components/UI';
import { TABLE_PAGE_LIMIT } from 'constants/index';
import {
  NotificationHistoryObject,
  useGetNotificationHistoriesQuery,
} from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import { getItemDataForNotificationsHistoryTable } from 'utils';

import { NOTIFICATIONS_HISTORY_TABLE_OPTIONS } from './constants';

export const NotificationsHistory = () => {
  // Create data for table row
  const createData = (notificationHistory: NotificationHistoryObject) => {
    const notificationsHistoryData = {
      ...getItemDataForNotificationsHistoryTable(notificationHistory),
    };

    return notificationsHistoryData;
  };

  const [page, setPage] = useState(1);

  const { data, loading } = useGetNotificationHistoriesQuery({
    variables: {
      input: {
        limit: TABLE_PAGE_LIMIT,
        offset: (page - 1) * TABLE_PAGE_LIMIT,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const notificationsHistory = data?.getNotificationHistories?.rows;
  const allNotificationsHisotryCount = data?.getNotificationHistories.count;

  const rows = notificationsHistory?.map((notificationHistory) =>
    createData(notificationHistory as NotificationHistoryObject),
  );
  const pageCount = allNotificationsHisotryCount
    ? Math.ceil(allNotificationsHisotryCount / TABLE_PAGE_LIMIT)
    : 0;

  useEffect(() => {
    if (page > 1 && rows && !rows?.length) {
      setPage((prev) => prev - 1);
    }
  }, [rows]);

  return (
    <RequestHandler loading={loading}>
      <PageHeader
        title='Notifications history'
        subtitle={`${allNotificationsHisotryCount} numbers`}
      />
      <PageContentWrapper>
        <TableList
          headCells={NOTIFICATIONS_HISTORY_TABLE_OPTIONS}
          isLoading={loading}
          rows={rows}
          tableContainerSx={{ height: 'calc(100vh - 168px)' }}
        />

        <Stack mt={'16px'} direction={'row'} justifyContent={'flex-end'}>
          <Pagination count={pageCount} page={page} onChange={handlePaginationChange} />
        </Stack>
      </PageContentWrapper>
    </RequestHandler>
  );
};
