import { GetAccountByIdQuery } from 'graphql/generated/graphql';

export const defaultValuesStep1 = (data?: GetAccountByIdQuery) => {
  if (data && data.getAccountById) {
    const { id, title, link, password, login, comment } = data.getAccountById;

    return {
      title: title || '',
      link: link || '',
      password: password || '',
      login: login || '',
      comment: comment || '',
      accountId: Number(id) || null,
    };
  }
  return {
    title: '',
    link: '',
    password: '',
    login: '',
    comment: '',
    accountId: null,
  };
};
