import { EMAIL_REGEX } from 'constants/index';
import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const createEditContactOther = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  country: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  city: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  address: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  company: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  contactPerson: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  whatsapp: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  comment: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  phones: yup.array().of(
    yup.object().shape({
      phone: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
      id: yup.number().notRequired(),
    }),
  ),
  emails: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .matches(EMAIL_REGEX, MESSAGES.INCORRECT_EMAIL)
        .required(MESSAGES.REQUIRE_MESSAGE),
      id: yup.number().notRequired(),
    }),
  ),
  commission: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
});
