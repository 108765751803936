import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const ArrowUpIcon = ({ fill = palette.secondary.main }: PropsType) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.6923 13.4417C16.6342 13.4998 16.5653 13.5459 16.4894 13.5774C16.4136 13.6088 16.3322 13.625 16.2501 13.625C16.168 13.625 16.0866 13.6088 16.0108 13.5774C15.9349 13.5459 15.866 13.4998 15.8079 13.4417L10.0001 7.63311L4.19229 13.4417C4.07502 13.559 3.91596 13.6249 3.7501 13.6249C3.58425 13.6249 3.42519 13.559 3.30792 13.4417C3.19064 13.3244 3.12476 13.1654 3.12476 12.9995C3.12476 12.8337 3.19064 12.6746 3.30792 12.5573L9.55792 6.30733C9.61596 6.24922 9.68489 6.20312 9.76077 6.17167C9.83664 6.14021 9.91797 6.12402 10.0001 6.12402C10.0822 6.12402 10.1636 6.14021 10.2394 6.17167C10.3153 6.20312 10.3842 6.24922 10.4423 6.30733L16.6923 12.5573C16.7504 12.6154 16.7965 12.6843 16.828 12.7602C16.8594 12.8361 16.8756 12.9174 16.8756 12.9995C16.8756 13.0816 16.8594 13.163 16.828 13.2389C16.7965 13.3147 16.7504 13.3837 16.6923 13.4417Z'
        fill={fill}
      />
    </svg>
  );
};
