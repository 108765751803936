import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($getUserByIdId: Int!) {
    getUserById(id: $getUserByIdId) {
      birthday
      email
      full_name
      id
      phone_number
      avatar {
        id
        path
        mimetype
        size
      }
      profit
      is_success_manager
      managers {
        id
        full_name
      }
      comment
      sections {
        id
        name
      }
    }
  }
`;
