import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
  query getAccounts($input: PaginationInput!) {
    getAccounts(input: $input) {
      count
      rows {
        id
        link
        login
        password
        title
        comment
      }
    }
  }
`;
