import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const createEditMembershipSchema = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  brand: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  participating: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  benefits: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  usage: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  link: yup.string().url(MESSAGES.INCORRECT_LINK).required(MESSAGES.REQUIRE_MESSAGE),
  comment: yup.string().notRequired(),
});
