import { gql } from '@apollo/client';

export const GET_CONTACT_BY_ID = gql`
  query getContactById($getContactByIdId: Int!) {
    getContactById(id: $getContactByIdId) {
      id
      additional_data {
        address
        comment
        contact_person
        country
        emails {
          id
          email
        }
        id
        phones {
          id
          phone
        }
        whats_app
      }
      city
      title
      company
      profit
      commission
    }
  }
`;
