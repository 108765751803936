export const TAB_OPTIONS = [
  {
    label: 'Documents',
    index: 0,
  },
  {
    label: 'Payment',
    index: 1,
  },
];
