import { AddEditUserFormData } from 'components/Modal/UserModals/AddEditUserModal/types';

export const submitUser = (data: AddEditUserFormData) => {
  const {
    fullName,
    email,
    profit,
    phone,
    dateOfBirth,
    userId,
    isSuccessManager,
    comment,
    successManager,
    sections,
  } = data;

  const isEdit = !!userId;

  return {
    input: {
      birthday: String(new Date(dateOfBirth).getTime()),
      email: email,
      full_name: fullName,
      phone_number: phone,
      profit: profit,
      is_success_manager: isSuccessManager,
      comment: comment || null,
      success_manager_id: successManager?.id || null,
      ...(isEdit && { user_id: Number(userId) }),
      section_ids: sections?.map((section) => section.id),
    },
  };
};
