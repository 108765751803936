import { AddEditContactCruiserFormData } from 'components/Modal/ContactModals/AddEditContactCruisesModal/types';
import { ContactTypesEnum } from 'graphql/generated/graphql';

export const submitContactCruiser = (data: AddEditContactCruiserFormData) => {
  const {
    title,
    country,
    city,
    address,
    company,
    profit,
    contactPerson,
    whatsapp,
    phones,
    emails,
    comment,
    isSendNotification,
    additionalDataId,
    contactId,
  } = data;

  const isEdit = !!contactId;

  return isEdit
    ? {
        input: {
          additional_data: {
            address: address,
            comment: comment,
            contact_person: contactPerson,
            country: country,
            whats_app: whatsapp,
          },
          additional_data_id: Number(additionalDataId),
          contact_data: {
            city: city,
            title: title,
            type: ContactTypesEnum.Cruises,
            company: company,
            profit: profit ? Number(profit) : null,
          },
          contact_id: Number(contactId),
          emails: emails,
          phones: phones,
        },
        sendNotification: isSendNotification,
      }
    : {
        input: {
          title: title,
          country: country,
          city: city,
          address: address,
          contact_person: contactPerson,
          whats_app: whatsapp,
          phones: phones?.map((item) => item.phone),
          emails: emails?.map((item) => item.email),
          comment: comment,
          type: ContactTypesEnum.Cruises,
          company: company,
          profit: profit ? Number(profit) : null,
        },
        sendNotification: isSendNotification,
      };
};
