import { EMAIL_REGEX } from 'constants/index';
import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

import { YupMixedImageFileType, YupNumberOrNullFieldType } from './types';

export const createEditOfferSchema = yup.object().shape({
  title: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  country: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
  dateFrom: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  dateTo: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  city: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  address: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  index: yup
    .number()
    .nullable()
    .positive()
    .typeError(MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullFieldType,
  currency: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  priceFrom: yup
    .number()
    .typeError(MESSAGES.REQUIRE_MESSAGE)
    .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .nullable()
    .notRequired() as YupNumberOrNullFieldType,
  priceTo: yup
    .number()
    .typeError(MESSAGES.REQUIRE_MESSAGE)
    .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .nullable()
    .notRequired() as YupNumberOrNullFieldType,
  contactPerson: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  whatsapp: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
  link: yup.string().url(MESSAGES.INCORRECT_LINK).notRequired(),
  information: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  comment: yup.string().notRequired(),
  phones: yup.array().of(
    yup.object().shape({
      phone: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).notRequired(),
      id: yup.number().notRequired(),
    }),
  ),
  emails: yup.array().of(
    yup.object().shape({
      email: yup.string().test('', MESSAGES.INCORRECT_EMAIL, (value) => {
        if (!value) return true;
        return EMAIL_REGEX.test(value);
      }),
      id: yup.number().notRequired(),
    }),
  ),
  image: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE) as YupMixedImageFileType,
  isSendNotification: yup.boolean().notRequired(),
  additionalDataId: yup.number().notRequired(),
  offerId: yup.number().notRequired(),
});
