import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const EURIcon = ({ fill = palette.black50 }: PropsType) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 7C17.5 6.5 16 5.5 13.5 5.5C9.8679 5.5 8.1902 7.78012 7.67815 10.5M18 18C17.5 18.5 16 19.5 13.5 19.5C9.8679 19.5 8.1902 17.2199 7.67815 14.5M7.67815 10.5C7.5558 11.1499 7.5 11.825 7.5 12.5C7.5 13.175 7.5558 13.8501 7.67815 14.5M7.67815 10.5H4.5M7.67815 10.5H14.5M7.67815 14.5H4.5H14.5'
        stroke={fill}
        strokeWidth='2'
      />
    </svg>
  );
};
