import { TableRowType } from 'components/UI/TableList/types';

export const defaultValuesEarned = (row: TableRowType) => {
  const {
    almontProfitValue,
    bookingAmount,
    currency,
    date,
    description,
    isInitEdit,
    markUp,
    tempId,
    title,
    totalProfit,
    userId,
    userProfit,
    userProfitValue,
    invoice,
    id,
  } = row;
  return {
    almontProfitValue,
    bookingAmount,
    currency,
    date: date as string,
    description: String(description),
    isInitEdit,
    markUp,
    tempId,
    title,
    totalProfit,
    userId,
    userProfit,
    userProfitValue,
    invoice,
    id,
  };
};
