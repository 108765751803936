import { Button, styled } from '@mui/material';

export type RemoveButtonPropsType = {
  issublabel?: number;
};

export const RemoveButton = styled(Button)<RemoveButtonPropsType>(({ issublabel }) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '20px',
    height: '20px',
    marginTop: !issublabel ? '11px' : '-9px',

    span: {
      margin: 0,
    },
  };
});
