import { COLUMN_IDS } from 'components/UI/TableList/constants';

export const MEMBERSHIPD_TABLE_OPTIONS = [
  { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
  { id: COLUMN_IDS.TITLE, label: 'Title' },
  { id: COLUMN_IDS.BRAND, label: 'Brand' },
  { id: COLUMN_IDS.PARTICIPATING, label: 'Participating' },
  { id: COLUMN_IDS.EXCLUSIVE_BENEFITS, label: 'Exclusive Benefits', minWidth: 270 },
  { id: COLUMN_IDS.LINK, label: 'Link', minWidth: 100 },
  { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
];
