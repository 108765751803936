import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import {
  AutocompeteRHF,
  CheckboxRHF,
  RequestHandler,
  TextFieldRHF,
  UserItemAutocomplete,
} from 'components/UI';
import { AutocompeteOptionType } from 'components/UI/AutocompeteRHF/types';
import { defaultValuesNotifications, getUsersOptions, submitNotification } from 'connectors';
import { MODAL_NAME } from 'constants/index';
import { useModalContext } from 'context';
import {
  useCreateNotificationMutation,
  useGetUsersForNotificationsQuery,
} from 'graphql/generated/graphql';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createNotificationSchema } from 'validation';

import { AddNotificationFormData } from './types';

export const AddNotificationsModal = () => {
  const { onCloseModal, onOpenModal } = useModalContext();

  const handleNotificationsSent = () => {
    onOpenModal(MODAL_NAME.NOTIFICATIONS_SENT);
  };

  const { data: usersData, loading: usersLoading } = useGetUsersForNotificationsQuery({
    variables: {
      input: {}, // for get all users
    },
    fetchPolicy: 'network-only',
  });

  const userOptions = getUsersOptions(usersData);

  const methods = useForm<AddNotificationFormData>({
    defaultValues: defaultValuesNotifications(),
    resolver: yupResolver(createNotificationSchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = methods;

  const pickedUsers = watch('users');
  const isSendNotification = watch('isSendNotificationAll');

  const handleRemoveUser = (id?: number) => {
    setValue(
      'users',
      pickedUsers?.filter((user) => user.id !== id),
    );
  };

  const [createNotification, { loading: createNotificationLoading }] =
    useCreateNotificationMutation({
      onCompleted: () => {
        onCloseModal();
        handleNotificationsSent();
      },
    });

  const onSubmit = (data: AddNotificationFormData) => {
    createNotification({
      variables: submitNotification(data),
    });
  };

  useEffect(() => {
    if (pickedUsers?.length) {
      setValue('isSendNotificationAll', false);
    } else {
      isSubmitted && !isSendNotification && trigger('users');
    }
  }, [pickedUsers]);

  useEffect(() => {
    if (isSendNotification) {
      setValue('users', []);
      trigger('users');
    } else {
      isSubmitted && !pickedUsers?.length && trigger('users');
    }
  }, [isSendNotification]);

  return (
    <RequestHandler loading={usersLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant='h3' mb={'32px'}>
            Create notifications
          </Typography>
          <Stack direction={'row'} gap='24px'>
            <TextFieldRHF name='title' label='Title' placeholder='Enter title' />
            <TextFieldRHF name='link' label='Link' placeholder='Enter link' />
          </Stack>
          <Stack>
            <TextFieldRHF
              name='text'
              label='Text'
              placeholder='Enter text'
              multiline
              minRows={3}
              maxRows={3}
            />
          </Stack>
          {!!pickedUsers?.length && (
            <Stack mb={'24px'} direction={'row'} flexWrap={'wrap'} gap={'24px'}>
              {pickedUsers?.map((user) => {
                return (
                  <UserItemAutocomplete user={user} handleClick={handleRemoveUser} key={user.id} />
                );
              })}
            </Stack>
          )}
          <AutocompeteRHF
            options={userOptions as AutocompeteOptionType[]}
            name='users'
            label={'Choose users who get notification'}
          />
          <CheckboxRHF name='isSendNotificationAll' label='Send notification for all users' />
          <Stack>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createNotificationLoading}
            >
              Send notification
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
