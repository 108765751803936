import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

import { YupNumberOrNullOrUndefinedFieldType } from './types';

export const createEditPaidSchema = yup.object().shape({
  row: yup.object().shape({
    invoice: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,

    description: yup
      .string()
      .max(255, MESSAGES.MAX_LENGTH('255'))
      .required(MESSAGES.REQUIRE_MESSAGE),
    note: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),

    amount: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(10000000, MESSAGES.MAX_NUMBER_VALUE('10000000'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullOrUndefinedFieldType,

    userProfit: yup.number().notRequired(),

    userId: yup.number().notRequired(),
    isInitEdit: yup.boolean().notRequired(),
    tempId: yup.string().notRequired(),
    id: yup.number().notRequired(),

    currency: yup.string().notRequired(),
  }),
});
