import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
};

export const ArrowLeftIcon = ({ fill = palette.gray60 }: PropsType) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.5306 18.9698C15.6003 19.0395 15.6556 19.1222 15.6933 19.2132C15.731 19.3043 15.7504 19.4019 15.7504 19.5004C15.7504 19.599 15.731 19.6965 15.6933 19.7876C15.6556 19.8786 15.6003 19.9614 15.5306 20.031C15.461 20.1007 15.3782 20.156 15.2872 20.1937C15.1961 20.2314 15.0986 20.2508 15 20.2508C14.9015 20.2508 14.8039 20.2314 14.7128 20.1937C14.6218 20.156 14.5391 20.1007 14.4694 20.031L6.96938 12.531C6.89965 12.4614 6.84433 12.3787 6.80659 12.2876C6.76885 12.1966 6.74942 12.099 6.74942 12.0004C6.74942 11.9019 6.76885 11.8043 6.80659 11.7132C6.84433 11.6222 6.89965 11.5394 6.96938 11.4698L14.4694 3.96979C14.6101 3.82906 14.801 3.75 15 3.75C15.199 3.75 15.3899 3.82906 15.5306 3.96979C15.6714 4.11052 15.7504 4.30139 15.7504 4.50042C15.7504 4.69944 15.6714 4.89031 15.5306 5.03104L8.56032 12.0004L15.5306 18.9698Z'
        fill={fill}
      />
    </svg>
  );
};
