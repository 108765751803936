import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
  query getContacts($input: ContactFiltersInput!) {
    getContacts(input: $input) {
      count
      rows {
        id
        type
        title
        city
        company
        commission
        additional_data {
          address
          country
          contact_person
          emails {
            additional_data_id
            created_at
            email
            id
            email
            updated_at
          }
          phones {
            additional_data_id
            created_at
            phone
            id
            updated_at
          }
        }
      }
    }
  }
`;
