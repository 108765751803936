import { API_URL } from 'constants/index';
import {
  GetUsersForAccountsQuery,
  GetUsersForNotificationsQuery,
  UserRoleEnum,
} from 'graphql/generated/graphql';

export const getUsersOptions = (
  usersData?: GetUsersForAccountsQuery | GetUsersForNotificationsQuery | undefined,
) => {
  return usersData?.getUsers?.rows
    .filter((user) => user?.role !== UserRoleEnum.Customer)
    .map((user) => {
      return { id: user.id, label: user.full_name, photo: `${API_URL}/${user?.avatar?.path}` };
    });
};
