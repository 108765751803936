import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getFieldName } from 'utils';

import { IGeneralTFRHFProps } from './types';

const TextFieldCustom = ({
  name,
  label,
  errorMessage,
  errors,
  minRows,
  multiline,
  readOnly,
  size,
  type = 'text',
  mb,
  sx,
  register,
  maxRows,
  placeholder,
  subLabel,
}: IGeneralTFRHFProps) => {
  return (
    <Box mb={mb} sx={sx} width={'100%'}>
      {label && (
        <Box mb='8px' display={'flex'}>
          <label htmlFor={name}>
            <Typography variant='body1'>{label}</Typography>
          </label>
        </Box>
      )}
      <TextField
        size={size}
        error={errors}
        fullWidth
        {...register}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        type={type}
        placeholder={placeholder}
        inputProps={{
          ...(type === 'number' ? { step: 0.01 } : {}),
        }}
        InputProps={{
          readOnly: readOnly,
        }}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      />
      {subLabel && <Typography variant='body2'>{subLabel}</Typography>}
      <Box height={20}>
        {errorMessage && <Typography variant='error'>{String(errorMessage)}</Typography>}
      </Box>
    </Box>
  );
};

export const TextFieldRHF = ({ name, ...rest }: IGeneralTFRHFProps) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;

  const hasError = getFieldName(errors, name);

  return (
    <TextFieldCustom
      register={{ ...register(name) }}
      name={name}
      errors={!!hasError}
      errorMessage={hasError?.message}
      {...rest}
    />
  );
};
