import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

export const newPasswordSchema = yup.object().shape({
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.INCORRECT_CONFIRM_PASSWORD)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
