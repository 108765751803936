import { TableRowType } from 'components/UI/TableList/types';

export const defaultValuesPaid = (row: TableRowType) => {
  const {
    currency,
    description,
    isInitEdit,
    note,
    tempId,
    userId,
    userProfit,
    invoice,
    id,
    amount,
  } = row;
  return {
    currency,
    description: String(description),
    isInitEdit,
    tempId,
    amount,
    userId,
    userProfit,
    invoice,
    id,
    note,
  };
};
