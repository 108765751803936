import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { uploadFiles } from 'api';
import { DropzoneDocsRHF, RequestHandler, TextFieldRHF } from 'components/UI';
import { SelectRHF } from 'components/UI/SelectRHF';
import { defaultValuesEducation, submitEducation } from 'connectors';
import { EDUCATION_TYPE_OPTIONS, FILE_MIMETYPES } from 'constants/index';
import { useModalContext } from 'context';
import {
  CreateEducationMutationVariables,
  FileObject,
  UpdateEducationMutationVariables,
  useCreateEducationMutation,
  useGetEducationByIdQuery,
  useUpdateEducationMutation,
} from 'graphql/generated/graphql';
import { GET_EDUCATIONS } from 'graphql/queries';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createEditEducationSchema } from 'validation';

import { AddEditEducationFormData } from './types';

export const AddEditEducationModal = () => {
  const { onCloseModal, infoModal } = useModalContext();

  const educationId = infoModal?.content?.id;
  const isEdit = !!educationId;

  const { data: educationData, loading: educationLoading } = useGetEducationByIdQuery({
    variables: {
      getEducationByIdId: educationId,
    },
    skip: !educationId,
    fetchPolicy: 'network-only',
  });

  const [createEducation, { loading: createEducationLoading }] = useCreateEducationMutation({
    onCompleted: () => {
      toast.success('Education created successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_EDUCATIONS],
  });

  const [updateEducation, { loading: updateEducationLoading }] = useUpdateEducationMutation({
    onCompleted: () => {
      toast.success('Education updated successfully', {
        position: 'bottom-right',
      });
      onCloseModal();
    },
    refetchQueries: [GET_EDUCATIONS],
  });

  const methods = useForm<AddEditEducationFormData>({
    defaultValues: defaultValuesEducation(educationData),
    resolver: yupResolver(createEditEducationSchema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { isSubmitted },
  } = methods;

  const [pdfFile, txtFile] = watch(['pdf', 'txt']);
  const title = educationData?.getEducationById?.title;

  const onSubmit = async (data: AddEditEducationFormData) => {
    const isFilePdf = data?.pdf && !Object.hasOwn(data?.pdf as File, 'id');
    const isFileTxt = data?.txt && !Object.hasOwn(data?.txt as File, 'id');

    let filePdfId = (data?.pdf as FileObject)?.id || null;
    let fileTxtId = (data?.txt as FileObject)?.id || null;

    if (isFilePdf) {
      const fromDataFilePdf = new FormData();
      fromDataFilePdf.append('files', data.pdf as File);
      const fileResponse = await uploadFiles(fromDataFilePdf);
      filePdfId = Number(fileResponse?.files[0]?.id);
    }

    if (isFileTxt) {
      const fromDataFileTxt = new FormData();
      fromDataFileTxt.append('files', data.txt as File);
      const fileResponse = await uploadFiles(fromDataFileTxt);
      fileTxtId = Number(fileResponse?.files[0]?.id);
    }

    const fileIds = [filePdfId, fileTxtId].filter((item) => item);

    isEdit
      ? updateEducation({
          variables: {
            ...(submitEducation(data, fileIds as number[]) as UpdateEducationMutationVariables),
          },
        })
      : createEducation({
          variables: {
            ...(submitEducation(data, fileIds as number[]) as CreateEducationMutationVariables),
          },
        });
  };

  useEffect(() => {
    reset(defaultValuesEducation(educationData));
  }, [educationId, educationData]);

  useEffect(() => {
    if (isEdit || isSubmitted) {
      trigger('pdf');
      trigger('txt');
    }
  }, [pdfFile, txtFile]);

  return (
    <RequestHandler loading={educationLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant='h3' mb={'32px'}>
            {!isEdit ? 'Add new file' : `Edit “${title}”`}
          </Typography>
          <Stack mb={'20px'} sx={{ width: '100%', maxWidth: '208px' }}>
            <SelectRHF label='Category' name='category' options={EDUCATION_TYPE_OPTIONS} />
            <TextFieldRHF
              name='type'
              label='Type of document'
              placeholder='Enter type of document'
            />
            <Stack direction={'row'} gap={'20px'}>
              <DropzoneDocsRHF name='pdf' type={FILE_MIMETYPES.PDF} />
              <DropzoneDocsRHF name='txt' type={FILE_MIMETYPES.TXT} />
            </Stack>
          </Stack>
          <Stack>
            <TextFieldRHF
              name='comment'
              label='Comment'
              subLabel='Optional'
              placeholder='Enter comment'
              multiline
              minRows={3}
              maxRows={3}
            />
          </Stack>
          <Stack>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ maxWidth: '200px', mt: '32px' }}
              loading={createEducationLoading || updateEducationLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </RequestHandler>
  );
};
