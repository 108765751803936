export const TXTIcon = () => {
  return (
    <svg width='38' height='50' viewBox='0 0 38 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.5347 10.506L36.3854 9.33276L28.3598 1.13879L28.3581 1.13793L27.7079 0.473276C27.4081 0.168103 27.0104 0 26.5873 0H2.07987C1.07751 0 0 0.789655 0 2.52241V33.6207V47.8767V48.2759C0 48.9974 0.710178 49.7 1.5504 49.9216C1.59347 49.9336 1.63484 49.95 1.67876 49.9586C1.81049 49.9853 1.94476 50 2.07987 50H35.9201C36.0552 50 36.1895 49.9853 36.3212 49.9586C36.3652 49.95 36.4065 49.9336 36.4496 49.9216C37.2898 49.7 38 48.9974 38 48.2759V47.8767V33.6207V12.05C38 11.3888 37.9215 10.9 37.5347 10.506ZM27.8667 3.07328L34.9896 10.3448H27.8667V3.07328ZM2.07987 48.2759C2.01991 48.2759 1.96587 48.2543 1.91267 48.2336C1.78178 48.1698 1.68889 48.0353 1.68889 47.8767V35.3448H36.3111V47.8767C36.3111 48.0353 36.2182 48.1698 36.0873 48.2336C36.0341 48.2543 35.9801 48.2759 35.9201 48.2759H2.07987ZM1.68889 33.6207V2.52241C1.68889 2.33534 1.71676 1.72414 2.07987 1.72414H26.2268C26.1989 1.83276 26.1778 1.94483 26.1778 2.06121V12.069H35.9801C36.0941 12.069 36.2039 12.0474 36.3103 12.019C36.3103 12.0319 36.3111 12.0371 36.3111 12.05V33.6207H1.68889Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M7.34424 38.8306H9.88433V46.5505H11.281V38.8306H13.8211V37.8643H7.34424V38.8306Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M20.0902 37.8643L18.4621 41.3996H18.3472L16.8458 37.8643H15.2642L17.5619 42.3306L15.4026 46.5505H17.0079L18.4621 43.2737H18.5778L19.9289 46.5505H21.5333L19.3741 42.3306L21.6718 37.8643H20.0902Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M23.1035 38.8306H25.6436V46.5505H27.0403V38.8306H29.5804V37.8643H23.1035V38.8306Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M5.91134 16.5327H10.978C11.445 16.5327 11.8225 16.1465 11.8225 15.6707C11.8225 15.1948 11.445 14.8086 10.978 14.8086H5.91134C5.44436 14.8086 5.06689 15.1948 5.06689 15.6707C5.06689 16.1465 5.44436 16.5327 5.91134 16.5327Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M5.91134 20.8443H13.5113C13.9783 20.8443 14.3558 20.458 14.3558 19.9822C14.3558 19.5063 13.9783 19.1201 13.5113 19.1201H5.91134C5.44436 19.1201 5.06689 19.5063 5.06689 19.9822C5.06689 20.458 5.44436 20.8443 5.91134 20.8443Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M16.8889 20.8442C17.1084 20.8442 17.328 20.7494 17.4884 20.5942C17.6404 20.4304 17.7333 20.2063 17.7333 19.9822C17.7333 19.758 17.6404 19.5339 17.4884 19.3701C17.176 19.0511 16.6018 19.0511 16.2978 19.3701C16.1373 19.5339 16.0444 19.7494 16.0444 19.9822C16.0444 20.2149 16.1365 20.4304 16.2893 20.5942C16.4489 20.7494 16.6693 20.8442 16.8889 20.8442Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M20.2663 20.8443H27.0219C27.4889 20.8443 27.8663 20.458 27.8663 19.9822C27.8663 19.5063 27.4889 19.1201 27.0219 19.1201H20.2663C19.7993 19.1201 19.4219 19.5063 19.4219 19.9822C19.4219 20.458 19.7993 20.8443 20.2663 20.8443Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M32.089 19.1201H30.4001C29.9331 19.1201 29.5557 19.5063 29.5557 19.9822C29.5557 20.458 29.9331 20.8443 30.4001 20.8443H32.089C32.556 20.8443 32.9334 20.458 32.9334 19.9822C32.9334 19.5063 32.556 19.1201 32.089 19.1201Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M5.91134 25.1538H24.4891C24.9561 25.1538 25.3336 24.7676 25.3336 24.2918C25.3336 23.8159 24.9561 23.4297 24.4891 23.4297H5.91134C5.44436 23.4297 5.06689 23.8159 5.06689 24.2918C5.06689 24.7676 5.44436 25.1538 5.91134 25.1538Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M32.0888 23.4297H27.0222C26.5552 23.4297 26.1777 23.8159 26.1777 24.2918C26.1777 24.7676 26.5552 25.1538 27.0222 25.1538H32.0888C32.5558 25.1538 32.9333 24.7676 32.9333 24.2918C32.9333 23.8159 32.5558 23.4297 32.0888 23.4297Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M5.91134 29.4644H9.28912C9.7561 29.4644 10.1336 29.0782 10.1336 28.6023C10.1336 28.1264 9.7561 27.7402 9.28912 27.7402H5.91134C5.44436 27.7402 5.06689 28.1264 5.06689 28.6023C5.06689 29.0782 5.44436 29.4644 5.91134 29.4644Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M21.1112 27.7402H12.6667C12.1997 27.7402 11.8223 28.1264 11.8223 28.6023C11.8223 29.0782 12.1997 29.4644 12.6667 29.4644H21.1112C21.5781 29.4644 21.9556 29.0782 21.9556 28.6023C21.9556 28.1264 21.5781 27.7402 21.1112 27.7402Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
      <path
        d='M32.089 27.7402H24.489C24.022 27.7402 23.6445 28.1264 23.6445 28.6023C23.6445 29.0782 24.022 29.4644 24.489 29.4644H32.089C32.556 29.4644 32.9334 29.0782 32.9334 28.6023C32.9334 28.1264 32.556 27.7402 32.089 27.7402Z'
        fill='black'
        stroke='black'
        strokeWidth='0.002'
      />
    </svg>
  );
};
