import { CurrencyEnum } from 'graphql/generated/graphql';

import { AddEditPaidFormData } from './types';

export const submitPaid = (data: AddEditPaidFormData) => {
  const { id, invoice, description, amount, userProfit, userId, currency, note } = data;

  const isEdit = !!id;

  return {
    input: {
      currency: currency as CurrencyEnum,
      note: String(note),
      description,
      invoice: Number(invoice),
      amount: Number(amount?.toFixed(2)),
      user_id: Number(userId),
      ...(!isEdit ? { current_profit: Number(userProfit) } : {}),
    },
  };
};
