import { palette } from 'theme/foundations';

type PropsType = {
  fill?: string;
  size?: number;
};

export const PlusIcon = ({ fill = palette.gray10, size = 14 }: PropsType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.25 7C12.25 7.11603 12.2039 7.22731 12.1219 7.30936C12.0398 7.39141 11.9285 7.4375 11.8125 7.4375H7.4375V11.8125C7.4375 11.9285 7.39141 12.0398 7.30936 12.1219C7.22731 12.2039 7.11603 12.25 7 12.25C6.88397 12.25 6.77269 12.2039 6.69064 12.1219C6.60859 12.0398 6.5625 11.9285 6.5625 11.8125V7.4375H2.1875C2.07147 7.4375 1.96019 7.39141 1.87814 7.30936C1.79609 7.22731 1.75 7.11603 1.75 7C1.75 6.88397 1.79609 6.77269 1.87814 6.69064C1.96019 6.60859 2.07147 6.5625 2.1875 6.5625H6.5625V2.1875C6.5625 2.07147 6.60859 1.96019 6.69064 1.87814C6.77269 1.79609 6.88397 1.75 7 1.75C7.11603 1.75 7.22731 1.79609 7.30936 1.87814C7.39141 1.96019 7.4375 2.07147 7.4375 2.1875V6.5625H11.8125C11.9285 6.5625 12.0398 6.60859 12.1219 6.69064C12.2039 6.77269 12.25 6.88397 12.25 7Z'
        fill={fill}
      />
    </svg>
  );
};
