import {
  CustomTabs,
  PageContentWrapper,
  PageHeader,
  RequestHandler,
  UserDocuments,
  UserInfo,
  UserPayment,
} from 'components';
import { ROUTES } from 'constants/index';
import { useGetEarnedPaidDataByUserQuery, useGetUserByIdQuery } from 'graphql/generated/graphql';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TAB_OPTIONS } from './constants';
import { TabsWrapper } from './styles';

export const User = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  const { data, loading } = useGetUserByIdQuery({
    variables: {
      getUserByIdId: Number(id),
    },
    fetchPolicy: 'network-only',
  });

  const { data: earned, loading: earnedLoading } = useGetEarnedPaidDataByUserQuery({
    variables: {
      userId: Number(id),
    },
    fetchPolicy: 'network-only',
  });

  const userData = data?.getUserById;
  const earnedData = earned?.getEarnedPaidDataByUser;

  const handleBackUserList = () => {
    navigate(ROUTES.users);
  };

  const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const userName = userData?.full_name?.split(' ')?.[0];

  return (
    <RequestHandler loading={loading || earnedLoading}>
      <PageHeader
        title='Back to the list of all users'
        isBack
        handleClickBack={handleBackUserList}
      />
      <PageContentWrapper>
        <UserInfo userData={userData} earnedData={earnedData} />

        <TabsWrapper>
          <CustomTabs value={tabIndex} options={TAB_OPTIONS} handleChange={handleChangeTabIndex} />
        </TabsWrapper>

        {tabIndex ? (
          <UserPayment
            userId={Number(id)}
            userProfit={Number(userData?.profit)}
            userName={userName || ''}
          />
        ) : (
          <UserDocuments userId={Number(id)} />
        )}
      </PageContentWrapper>
    </RequestHandler>
  );
};
