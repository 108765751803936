import { AuthLayout, MainLayout } from 'components';
import { ROUTES } from 'constants/index';
import { useAuthContext } from 'context';
import {
  Contacts,
  Education,
  ForgotPassword,
  Home,
  Login,
  Memberships,
  NewPassword,
  NotFound,
  NotificationsHistory,
  SpecialOffers,
  User,
  Users,
  VerifyEmail,
} from 'pages';
import { Accounts } from 'pages/Accounts';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const routes = [
  {
    path: ROUTES.home,
    element: Home,
  },
  {
    path: ROUTES.login,
    element: Login,
  },
  {
    path: ROUTES.forgotPassword,
    element: ForgotPassword,
  },
  {
    path: ROUTES.verifyEmail,
    element: VerifyEmail,
  },
  {
    path: ROUTES.newPassword,
    element: NewPassword,
  },
  {
    path: ROUTES.specialOffers,
    element: SpecialOffers,
  },
  {
    path: ROUTES.contacts,
    element: Contacts,
  },
  {
    path: ROUTES.membership,
    element: Memberships,
  },
  {
    path: ROUTES.accounts,
    element: Accounts,
  },
  {
    path: ROUTES.users,
    element: Users,
  },
  {
    path: ROUTES.user,
    element: User,
  },
  {
    path: ROUTES.education,
    element: Education,
  },
  {
    path: ROUTES.notificationsHistory,
    element: NotificationsHistory,
  },
];

export const RouterWrapper = () => {
  const { isAuth } = useAuthContext();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectedRoute {...route} isAuth={isAuth} />}
          />
        ))}
        <Route key={'NotFound'} path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

type ProtectedRouteType = {
  element: () => JSX.Element;
  path: string;
  isAuth: boolean;
};

const ProtectedRoute = ({ element: Element, path, isAuth = true }: ProtectedRouteType) => {
  // exception for pages that do not require authorization
  if (authRoutCheck(path)) {
    if (isAuth) return <Navigate to={ROUTES.specialOffers} />;
    return (
      <AuthLayout>
        <Element />
      </AuthLayout>
    );
  }

  if (isAuth) {
    if (path === ROUTES.home) return <Navigate to={ROUTES.specialOffers} />;
    return (
      <MainLayout>
        <Element />
      </MainLayout>
    );
  }

  return <Navigate to={ROUTES.login} />;
};

const authRoutCheck = (path: string) => {
  const authRout = [ROUTES.login, ROUTES.forgotPassword, ROUTES.verifyEmail, ROUTES.newPassword];
  return authRout.includes(path);
};
