import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { TextFieldRHF } from 'components/UI';
import { defaultValuesStep1 } from 'connectors';
import { GetAccountByIdQuery } from 'graphql/generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { step1Schema } from 'validation';

import { Step1FormData } from '../types';

export const Step1 = ({
  handleNextStep,
  accountData,
}: {
  handleNextStep: (data: Step1FormData) => void;
  accountData?: GetAccountByIdQuery;
}) => {
  const methods = useForm<Step1FormData>({
    defaultValues: defaultValuesStep1(accountData),
    resolver: yupResolver(step1Schema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: Step1FormData) => {
    handleNextStep(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'row'} gap='24px'>
          <TextFieldRHF name='title' label='Title' placeholder='Enter title' />
          <TextFieldRHF name='link' label='Link' placeholder='Enter link' />
        </Stack>
        <Stack direction={'row'} gap='24px'>
          <TextFieldRHF name='password' label='Password' placeholder='Enter password' />
          <TextFieldRHF name='login' label='Login' placeholder='Enter login' />
        </Stack>
        <Stack>
          <TextFieldRHF
            name='comment'
            label='Comment'
            subLabel='Optional'
            placeholder='Enter comment'
            multiline
            minRows={3}
            maxRows={3}
          />
        </Stack>

        <Stack>
          <Button type='submit' sx={{ maxWidth: '200px', mt: '32px' }}>
            Next step
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
