import uuid from 'react-uuid';

import { TableRowType } from '../TableList/types';
import { IDefaultPaidRowParams } from './types';

// earned

export const getDefaultEarnedRow = ({
  userProfit,
  userId,
  currency,
}: IDefaultPaidRowParams): TableRowType => {
  return {
    tempId: uuid(),
    title: '',
    date: '',
    description: '',
    bookingAmount: null,
    markUp: null,
    userProfit: userProfit,
    isInitEdit: true,
    currency: currency,
    userId: userId,
    totalProfit: null,
    userProfitValue: null,
    almontProfitValue: null,
    invoice: null,
  };
};

export const getLastEarnedRow = ({ rows }: { rows: Array<TableRowType> }): TableRowType => {
  const bookingAmount = rows?.reduce((acc, item) => {
    return acc + Number(item?.bookingAmount);
  }, 0);
  const markUp =
    rows?.reduce((acc, item) => {
      return acc + Number(item?.markUp);
    }, 0) / rows?.length;

  const totalProfit = rows?.reduce((acc, item) => {
    return acc + Number(item?.totalProfit);
  }, 0);

  const userProfitValue = rows?.reduce((acc, item) => {
    return acc + Number(item?.userProfitValue);
  }, 0);

  const almontProfitValue = rows?.reduce((acc, item) => {
    return acc + Number(item?.almontProfitValue);
  }, 0);

  const userProfit =
    rows?.reduce((acc, item) => {
      return acc + Number(item?.userProfit);
    }, 0) / rows.length;

  const lastRowData = {
    id: Math.random(),
    invoice: null,
    title: '',
    date: '',
    description: '',
    bookingAmount: bookingAmount ? +bookingAmount.toFixed(2) : 0,
    markUp: markUp ? +markUp.toFixed(2) : 0,
    userProfit: userProfit ? Number(userProfit.toFixed(2)) : 0,
    totalProfit: totalProfit ? +totalProfit.toFixed(2) : 0,
    userProfitValue: userProfitValue ? +userProfitValue.toFixed(2) : 0,
    almontProfitValue: almontProfitValue ? +almontProfitValue.toFixed(2) : 0,
  };

  return lastRowData;
};

// paid

export const getDefaultPaidRow = ({
  userProfit,
  userId,
  currency,
}: IDefaultPaidRowParams): TableRowType => {
  return {
    tempId: uuid(),
    description: '',
    userProfit: userProfit,
    isInitEdit: true,
    currency: currency,
    userId: userId,
    invoice: null,
    note: '',
  };
};

export const getLastPaidRow = ({ rows }: { rows: Array<TableRowType> }): TableRowType => {
  const amount = rows?.reduce((acc, item) => {
    return acc + Number(item?.amount);
  }, 0);

  const lastRowData = {
    id: Math.random(),
    invoice: null,
    description: '',
    amount: amount ? +amount.toFixed(2) : 0,
    userProfit: 0,
    note: '',
  };

  return lastRowData;
};
