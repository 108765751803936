import { Stack, styled } from '@mui/material';
import { palette } from 'theme/foundations';

export const DocType = styled(Stack)(() => ({
  background: palette.primary.main,
  border: `1px solid ${palette.gray50}`,
  borderRadius: '2px',
  justifyContent: 'center',
  alignItems: 'center',
  width: 94,
  height: 94,
}));
