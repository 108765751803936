import { gql } from '@apollo/client';

export const GET_USERS_FOR_ACCOUNTS = gql`
  query getUsersForAccounts($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;
