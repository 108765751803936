import { IconButton, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icons';

import { AutocompeteOptionType } from '../AutocompeteRHF/types';
import { SectionItem } from './styles';

export type SectionItemAutocompleteProps = {
  section?: AutocompeteOptionType;
  handleClick: (id?: number, name?: string) => void;
};

export const SectionItemAutocomplete = ({ section, handleClick }: SectionItemAutocompleteProps) => {
  return (
    <SectionItem key={section?.id}>
      <Typography variant='body1'>{section?.label}</Typography>
      <IconButton
        sx={{ marginLeft: 'auto', padding: 0 }}
        onClick={() => handleClick(section?.id, section?.label)}
      >
        <CloseIcon />
      </IconButton>
    </SectionItem>
  );
};
