import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers($input: UserFiltersInput!) {
    getUsers(input: $input) {
      count
      rows {
        id
        full_name
        email
        phone_number
        avatar {
          id
          path
          mimetype
          size
        }
        role
      }
    }
  }
`;
