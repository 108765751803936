export const getActionButtonTitleForContacts = (tabIndex: number) => {
  switch (tabIndex) {
    case 0:
      return 'Add new hotel';
    case 1:
      return 'Add new cruise';
    case 2:
      return 'Add new DMC’s';
    case 3:
      return 'Add new other';
    default:
      return 'Add new hotel';
  }
};
