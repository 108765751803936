export const PDFIcon = () => {
  return (
    <svg width='38' height='50' viewBox='0 0 38 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.5356 10.506L36.8845 9.84138L28.3598 1.13879L27.7088 0.474138C27.414 0.173276 27.0053 0 26.5873 0H2.07987C1.07836 0 0 0.789655 0 2.52241V33.6207V47.8767V48.2759C0 48.9974 0.711022 49.7009 1.55209 49.9224C1.59431 49.9336 1.63484 49.95 1.67876 49.9586C1.81049 49.9853 1.94476 50 2.07987 50H35.9201C36.0552 50 36.1895 49.9853 36.3212 49.9586C36.3652 49.95 36.4057 49.9336 36.4479 49.9224C37.289 49.7009 38 48.9974 38 48.2759V47.8767V33.6207V12.05C38 11.3888 37.9223 10.9009 37.5356 10.506ZM34.9896 10.3448H27.8667V3.07328L34.9896 10.3448ZM2.07987 48.2759C2.01991 48.2759 1.96587 48.2534 1.91267 48.2336C1.78093 48.1698 1.68889 48.0353 1.68889 47.8767V35.3448H36.3111V47.8767C36.3111 48.0353 36.2191 48.169 36.0873 48.2336C36.0341 48.2534 35.9801 48.2759 35.9201 48.2759H2.07987ZM1.68889 33.6207V2.52241C1.68889 2.33534 1.71676 1.72414 2.07987 1.72414H26.2268C26.1989 1.83276 26.1778 1.94483 26.1778 2.06121V12.069H35.9809C36.0949 12.069 36.2039 12.0474 36.3103 12.019C36.3103 12.0319 36.3111 12.0371 36.3111 12.05V33.6207H1.68889Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
      <path
        d='M13.1244 38.5702C12.8432 38.3348 12.5257 38.1572 12.1719 38.04C11.818 37.9219 11.46 37.8633 11.0986 37.8633H8.65137V46.5495H10.0371V43.4141H11.0648C11.5107 43.4141 11.9194 43.3478 12.2884 43.2141C12.6574 43.0805 12.9732 42.8917 13.235 42.6486C13.4968 42.4055 13.7003 42.1047 13.8472 41.7469C13.9933 41.3891 14.0668 40.9909 14.0668 40.5504C14.0668 40.134 13.9798 39.759 13.8067 39.4245C13.6336 39.09 13.4056 38.8064 13.1244 38.5702ZM12.5991 41.44C12.5139 41.6797 12.4032 41.8659 12.2647 41.9995C12.1263 42.1331 11.9743 42.2297 11.8087 42.2883C11.6432 42.3469 11.4752 42.3771 11.3063 42.3771H10.0363V38.9357H11.0749C11.4287 38.9357 11.7133 38.9926 11.9295 39.1064C12.1448 39.2202 12.312 39.3616 12.4319 39.5305C12.551 39.6995 12.6295 39.8762 12.6684 40.0607C12.7064 40.2452 12.7258 40.4081 12.7258 40.5495C12.7258 40.9038 12.6836 41.2004 12.5991 41.44Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
      <path
        d='M21.4947 39.1357C21.1366 38.7512 20.6866 38.4417 20.1436 38.2107C19.6006 37.9797 18.9715 37.8633 18.2562 37.8633H15.6934V46.5495H18.9141C19.0213 46.5495 19.1868 46.5357 19.4106 46.5081C19.6335 46.4805 19.8801 46.4185 20.1495 46.3193C20.4189 46.221 20.6975 46.0736 20.9863 45.8771C21.2751 45.6805 21.5344 45.4116 21.7658 45.0693C21.9971 44.7271 22.1871 44.3029 22.3374 43.796C22.4878 43.2891 22.5629 42.6779 22.5629 41.9633C22.5629 41.4443 22.4742 40.9391 22.2978 40.4486C22.1196 39.959 21.8527 39.521 21.4947 39.1357ZM20.4847 44.5926C20.0693 45.2055 19.392 45.5116 18.453 45.5116H17.0791V38.9348H17.8872C18.5493 38.9348 19.088 39.0236 19.5035 39.2003C19.919 39.3771 20.2483 39.609 20.4906 39.896C20.733 40.1831 20.896 40.5029 20.9813 40.8564C21.0657 41.2098 21.1079 41.5676 21.1079 41.9288C21.1079 43.0917 20.9002 43.9805 20.4847 44.5926Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
      <path
        d='M24.5718 46.5495H25.9803V42.6366H29.5363V41.6702H25.9803V38.9357H29.8935V37.8633H24.5718V46.5495Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
      <path
        d='M26.9611 19.7949C26.1851 19.7949 25.2325 19.8983 24.1255 20.1035C22.5801 18.4293 20.9672 15.9845 19.8289 13.5845C20.9579 8.73192 20.393 8.04485 20.1439 7.72072C19.8787 7.37589 19.5047 6.81641 19.0791 6.81641C18.9009 6.81641 18.4145 6.89916 18.2211 6.96468C17.7347 7.1302 17.4729 7.51296 17.2635 8.0121C16.6665 9.4371 17.4856 11.8664 18.3283 13.7388C17.608 16.6638 16.3996 20.1647 15.1296 23.0061C11.9291 24.5026 10.2293 25.9724 10.0756 27.375C10.0199 27.8854 10.1381 28.6345 11.0171 29.3078C11.2578 29.4914 11.5399 29.5888 11.8337 29.5888C12.5726 29.5888 13.3191 29.0112 14.183 27.7724C14.8129 26.869 15.4893 25.6371 16.1953 24.1078C18.4567 23.0983 21.2543 22.1862 23.65 21.675C24.9843 22.9828 26.1791 23.6449 27.206 23.6449C27.9626 23.6449 28.6111 23.2897 29.0807 22.6181C29.5696 21.919 29.6811 21.2931 29.41 20.7561C29.0849 20.1104 28.2835 19.7949 26.9611 19.7949ZM11.8523 28.1336C11.4571 27.8242 11.4799 27.6155 11.4883 27.5371C11.5407 27.0578 12.2762 26.2069 14.0808 25.1716C12.7128 27.7509 11.9781 28.0931 11.8523 28.1336ZM18.7767 8.3733C18.8131 8.36123 19.6592 9.32244 18.8578 11.1457C17.6536 9.88796 18.694 8.40175 18.7767 8.3733ZM17.0313 22.1888C17.8884 20.1035 18.6855 17.8009 19.2893 15.6681C20.2376 17.4078 21.3768 19.0957 22.5168 20.4474C20.7147 20.8793 18.7962 21.4888 17.0313 22.1888ZM27.9255 21.7759C27.6654 22.1474 27.1013 22.1561 26.9037 22.1561C26.4536 22.1561 26.2855 21.8828 25.5973 21.3423C26.1648 21.2681 26.7002 21.2492 27.1266 21.2492C27.8773 21.2492 28.015 21.3621 28.1188 21.419C28.1003 21.4802 28.0513 21.5957 27.9255 21.7759Z'
        fill='black'
        fillOpacity='0.85'
        stroke='black'
        strokeOpacity='0.85'
        strokeWidth='0.002'
      />
    </svg>
  );
};
