import { COLUMN_IDS } from 'components/UI/TableList/constants';
import { TableRowType } from 'components/UI/TableList/types';
import { CURRENCIES } from 'constants/index';
import dayjs from 'dayjs';
import {
  AccountObject,
  ContactObject,
  ContactTypesEnum,
  EarnedObject,
  EducationObject,
  FileObject,
  ListDocumentObject,
  MembershipObject,
  NotificationHistoryObject,
  OfferObject,
  PaidObject,
  UserObject,
} from 'graphql/generated/graphql';

import { getCurrencyLabel } from './getCurrencyLabel';
import { getDocumentFilesTypes } from './getDocumentFileType';
import { getDocumentType } from './getDocumentType';
import { getUserRole } from './getUserRole';

// create item data for offers

export const getItemDataForOffersTable = ({
  id,
  title,
  city,
  additional_data,
  date_from,
  date_to,
  currency,
  price_from,
  price_to,
}: OfferObject) => {
  const phones = additional_data?.phones?.reduce((acc, item, index, arr) => {
    return acc + item.phone + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const getPrice = () => {
    if (price_from !== null && price_to !== null) {
      return `${currencyLabel}${price_from} - ${currencyLabel}${price_to}`;
    }
    return '';
  };

  const currencyLabel = getCurrencyLabel(currency as CURRENCIES);

  const offerData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.LOCATION]: `${city ? `${city}, ` : ''}${additional_data?.country}`,
    [COLUMN_IDS.DATE]: `${dayjs.utc(date_from).format('DD.MM.YYYY')} - ${dayjs
      .utc(date_to)
      .format('DD.MM.YYYY')}`,
    [COLUMN_IDS.PRICE]: getPrice(),
    [COLUMN_IDS.CONTACT_PERSON]: additional_data?.contact_person,
    [COLUMN_IDS.PHONE_NUMBER]: phones,
  };

  return offerData;
};

// contacts

// create item data for hotel contacts

export const getItemDataForHotelContactsTable = ({
  id,
  title,
  city,
  additional_data,
}: ContactObject) => {
  const phones = additional_data?.phones?.reduce((acc, item, index, arr) => {
    return acc + item.phone + (index === arr.length - 1 ? '' : ', ');
  }, '');
  const emails = additional_data?.emails?.reduce((acc, item, index, arr) => {
    return acc + item.email + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const contactData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.LOCATION]: `${city ? `${city}, ` : ''}${additional_data?.country}`,
    [COLUMN_IDS.CONTACT_PERSON]: additional_data?.contact_person,
    [COLUMN_IDS.PHONE_NUMBER]: phones,
    [COLUMN_IDS.EMAIL]: emails,
  };

  return contactData;
};

// create item data for cruises contacts

export const getItemDataForCruisesContactsTable = ({
  id,
  title,
  city,
  company,
  additional_data,
}: ContactObject) => {
  const phones = additional_data?.phones?.reduce((acc, item, index, arr) => {
    return acc + item.phone + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const address = additional_data?.address;

  const contactData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.LOCATION]: `${city}${address && city ? `, ${address}` : `${address}`}`,
    [COLUMN_IDS.COUNTRY]: additional_data?.country,
    [COLUMN_IDS.COMPANY]: company,
    [COLUMN_IDS.CONTACT_PERSON]: additional_data?.contact_person,
    [COLUMN_IDS.PHONE_NUMBER]: phones,
  };

  return contactData;
};

// create item data for DMC contacts

export const getItemDataForDMCsContactsTable = ({
  id,
  title,
  company,
  additional_data,
}: ContactObject) => {
  const phones = additional_data?.phones?.reduce((acc, item, index, arr) => {
    return acc + item.phone + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const emails = additional_data?.emails?.reduce((acc, item, index, arr) => {
    return acc + item.email + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const contactData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title || '',
    [COLUMN_IDS.COUNTRY]: additional_data?.country,
    [COLUMN_IDS.COMPANY]: company,
    [COLUMN_IDS.CONTACT_PERSON]: additional_data?.contact_person,
    [COLUMN_IDS.EMAIL]: emails,
    [COLUMN_IDS.PHONE_NUMBER]: phones,
  };

  return contactData;
};

// create item data for other contacts

export const getItemDataForOtherContactsTable = ({
  id,
  title,
  city,
  company,
  commission,
  additional_data,
}: ContactObject) => {
  const address = additional_data?.address;

  const phones = additional_data?.phones?.reduce((acc, item, index, arr) => {
    return acc + item.phone + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const emails = additional_data?.emails?.reduce((acc, item, index, arr) => {
    return acc + item.email + (index === arr.length - 1 ? '' : ', ');
  }, '');

  const contactData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.LOCATION]: `${city}${address && city ? `, ${address}` : `${address}`}`,
    [COLUMN_IDS.COUNTRY]: additional_data?.country,
    [COLUMN_IDS.COMPANY]: company,
    [COLUMN_IDS.CONTACT_PERSON]: additional_data?.contact_person,
    [COLUMN_IDS.EMAIL]: emails,
    [COLUMN_IDS.COMISSION]: commission,
    [COLUMN_IDS.PHONE_NUMBER]: phones,
  };

  return contactData;
};

export const getItemDataForContactsItem = (contact: ContactObject) => {
  switch (contact.type) {
    case ContactTypesEnum.Hotel:
      return getItemDataForHotelContactsTable(contact);
    case ContactTypesEnum.Cruises:
      return getItemDataForCruisesContactsTable(contact);
    case ContactTypesEnum.Dmc:
      return getItemDataForDMCsContactsTable(contact);
    case ContactTypesEnum.Other:
      return getItemDataForOtherContactsTable(contact);
    default:
      return getItemDataForHotelContactsTable(contact);
  }
};

// users
export const getItemDataForUsersTable = ({
  id,
  full_name,
  email,
  phone_number,
  avatar,
  role,
}: UserObject) => {
  const userData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.FULL_NAME]: { name: full_name, preview: avatar?.path },
    [COLUMN_IDS.EMAIL]: email,
    [COLUMN_IDS.ROLE]: getUserRole(role),
    [COLUMN_IDS.PHONE_NUMBER]: phone_number ?? '',
  };
  return userData;
};

// earned

export const getItemDataForEarnedTable = ({
  id,
  invoice,
  title,
  date_to_travel,
  description,
  booking_amount,
  mark_up,
  current_profit,
  currency,
  almont_account,
  user_account,
  total_profit,
  user_id,
}: EarnedObject): TableRowType => {
  return {
    id,
    invoice,
    title,
    date: date_to_travel,
    description,
    bookingAmount: booking_amount,
    markUp: mark_up,
    userProfit: current_profit,
    userProfitValue: user_account,
    almontProfitValue: almont_account,
    totalProfit: total_profit,
    userId: user_id,
    currency,
  };
};

// paid

export const getItemDataForPaidTable = ({
  id,
  invoice,
  description,
  current_profit,
  amount,
  currency,
  note,
  user_id,
}: PaidObject): TableRowType => {
  return {
    id,
    invoice,
    description,
    amount,
    userProfit: current_profit,
    userId: user_id,
    currency,
    note: String(note),
  };
};

// create item data for memberships

export const getItemDataForMembershipsTable = ({
  id,
  title,
  brand,
  exclusiveBenefits,
  link,
  participating,
}: MembershipObject) => {
  const membershipData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.BRAND]: brand,
    [COLUMN_IDS.PARTICIPATING]: participating,
    [COLUMN_IDS.EXCLUSIVE_BENEFITS]: exclusiveBenefits,
    [COLUMN_IDS.LINK]: link,
  };

  return membershipData;
};

// create item data for documents

export const getItemDataForDocumentsTable = ({
  id,
  owner,
  type,
  date_from,
  date_to,
  comment,
}: ListDocumentObject) => {
  const documentData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TYPE]: getDocumentType(type),
    [COLUMN_IDS.OWNER]: owner || '',
    [COLUMN_IDS.DATE]: `${dayjs(date_from).format('DD.MM.YYYY')} ${
      date_to ? `- ${dayjs(date_to).format('DD.MM.YYYY')}` : ''
    }`,
    [COLUMN_IDS.COMMENT]: comment || '',
  };

  return documentData;
};

// create item data for account

export const getItemDataForAccountsTable = ({
  id,
  title,
  login,
  password,
  link,
  comment,
}: AccountObject) => {
  const accountsData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.LOGIN]: login,
    [COLUMN_IDS.PASSWORD]: password,
    [COLUMN_IDS.LINK]: link,
    [COLUMN_IDS.COMMENT]: comment,
  };

  return accountsData;
};

// education
export const getItemDataForEducationTable = ({ id, title, comment, files }: EducationObject) => {
  const userData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.TYPE]: getDocumentFilesTypes(files as FileObject[]),
    [COLUMN_IDS.COMMENT]: comment,
  };
  return userData;
};

// create item data for memberships

export const getItemDataForNotificationsHistoryTable = ({
  id,
  data,
  notificationMessage,
  created_at,
  users,
  sender,
}: NotificationHistoryObject) => {
  const { link, title } = JSON.parse(data);

  const notificationsHistoryData = {
    [COLUMN_IDS.ID]: id,
    [COLUMN_IDS.SENDER]: sender?.full_name,
    [COLUMN_IDS.DATE]: `${dayjs(created_at).format('DD.MM.YYYY')}`,
    [COLUMN_IDS.TITLE]: title,
    [COLUMN_IDS.COMMENT]: notificationMessage,
    [COLUMN_IDS.USERS]: users?.length
      ? users.map((user) => user.full_name).join(', ')
      : 'All users',
    [COLUMN_IDS.LINK]: link,
  };

  return notificationsHistoryData;
};
