import { Pagination, Stack } from '@mui/material';
import { DeleteIcon, EditIcon, PlusIcon } from 'components/Icons';
import { PageContentWrapper, PageHeader, RequestHandler, TableList } from 'components/UI';
import { COLUMN_IDS } from 'components/UI/TableList/constants';
import { MODAL_NAME, TABLE_PAGE_LIMIT } from 'constants/index';
import { useModalContext } from 'context';
import { AccountObject, useGetAccountsQuery } from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import { getItemDataForAccountsTable } from 'utils';

import { ACCOUNTS_TABLE_OPTIONS } from './constants';
import { AddButton } from './styles';

export const Accounts = () => {
  // Create data for table row
  const createData = (account: AccountObject) => {
    const accountsData = {
      ...getItemDataForAccountsTable(account),
      [COLUMN_IDS.ACTION]: [
        {
          label: 'Edit',
          Icon: <EditIcon />,
          action: () => {
            handleEditAccount(Number(account.id));
          },
        },
        {
          label: 'Delete',
          Icon: <DeleteIcon />,
          action: () => {
            handleDeleteAccount(Number(account.id), String(account.title));
          },
        },
      ],
    };

    return accountsData;
  };

  const { onOpenModal } = useModalContext();

  const [page, setPage] = useState(1);

  const { data, loading } = useGetAccountsQuery({
    variables: {
      input: {
        limit: TABLE_PAGE_LIMIT,
        offset: (page - 1) * TABLE_PAGE_LIMIT,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddAccount = () => {
    onOpenModal(MODAL_NAME.ADD_EDIT_ACCOUNTS);
  };

  const handleEditAccount = (id: number) => {
    onOpenModal(MODAL_NAME.ADD_EDIT_ACCOUNTS, { id });
  };

  const handleDeleteAccount = (id: number, title: string) => {
    onOpenModal(MODAL_NAME.DELETE_ACCOUNTS, { id, title });
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const accounts = data?.getAccounts?.rows;
  const allAccountsCount = data?.getAccounts.count;

  const rows = accounts?.map((account) => createData(account));
  const pageCount = allAccountsCount ? Math.ceil(allAccountsCount / TABLE_PAGE_LIMIT) : 0;

  useEffect(() => {
    if (page > 1 && rows && !rows?.length) {
      setPage((prev) => prev - 1);
    }
  }, [rows]);

  return (
    <RequestHandler loading={loading}>
      <PageHeader title='Accounts' subtitle={`${allAccountsCount} numbers`} />
      <PageContentWrapper>
        <AddButton startIcon={<PlusIcon />} onClick={handleAddAccount} sx={{ mb: '16px' }}>
          Add new accounts
        </AddButton>

        <TableList headCells={ACCOUNTS_TABLE_OPTIONS} isLoading={loading} rows={rows} />

        <Stack mt={'16px'} direction={'row'} justifyContent={'flex-end'}>
          <Pagination count={pageCount} page={page} onChange={handlePaginationChange} />
        </Stack>
      </PageContentWrapper>
    </RequestHandler>
  );
};
