import { Box, Stack, Typography } from '@mui/material';
import { dropzonePlaceholder } from 'assets/images';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

import { ImageBlock, ImagePlaceholderBlock, Wrapper } from './styles';
import { DropzonePropsType, DropzoneRHFPropsType } from './types';

export const DropzoneRHF = ({ subLabel, name, sx, mb }: DropzoneRHFPropsType) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        const handleChange = (file: File[]) => {
          // fix for Firefox download file from FileDialog
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
          }, 0);
        };
        return (
          <Dropzone
            hasError={!!errors[name]}
            value={getValues(name)}
            onChange={handleChange}
            sx={sx}
            mb={mb}
            subLabel={subLabel}
          />
        );
      }}
    />
  );
};

const Dropzone = ({ value, onChange, sx, mb, hasError, subLabel }: DropzonePropsType) => {
  const onDrop = (acceptedFiles: File[]) => {
    onChange(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...DEFAULT_DROPZONE_OPTIONS,
    onDrop,
  });

  return (
    <Wrapper sx={sx} mb={mb}>
      <Stack>
        <input {...getInputProps()} />
        {value ? (
          <ImageBlock {...getRootProps()} sx={{ cursor: 'pointer' }}>
            <img src={value?.preview || value?.url} alt='dropzone-img' />
          </ImageBlock>
        ) : (
          <Box {...getRootProps()} sx={{ cursor: 'pointer' }}>
            <ImagePlaceholderBlock haserror={String(hasError)}>
              <img src={dropzonePlaceholder} alt='dropzone-empty' />
            </ImagePlaceholderBlock>
          </Box>
        )}
      </Stack>
      {subLabel && (
        <Box {...getRootProps()} sx={{ cursor: 'pointer' }}>
          <Typography
            variant={hasError ? 'error' : 'body1'}
            fontSize={'14px'}
            lineHeight={'22px'}
            mt={'12px'}
            sx={{ textDecoration: 'underline' }}
          >
            {subLabel}
          </Typography>
        </Box>
      )}
    </Wrapper>
  );
};

export const DEFAULT_DROPZONE_OPTIONS = {
  maxSize: 5242880, // max size 5 mb
  multiple: false,
  accept: {
    'image/jpeg': [],
    'image/png': [],
  },
};
