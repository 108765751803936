import { Button, Pagination, Stack } from '@mui/material';
import {
  CustomTabs,
  DeleteIcon,
  EditIcon,
  PageContentWrapper,
  PageHeader,
  PlusIcon,
  RequestHandler,
  TableList,
} from 'components';
import {
  COLUMN_IDS,
  TABLE_SORT_DIRECTIONS,
  TABLE_SORT_FIELDS,
} from 'components/UI/TableList/constants';
import { TableSortType } from 'components/UI/TableList/types';
import { MODAL_NAME, TABLE_PAGE_LIMIT } from 'constants/index';
import { useModalContext } from 'context';
import { EducationObject, FileObject, useGetEducationsQuery } from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import {
  getActionButtonTitleForEducations,
  getItemDataForEducationTable,
  getSortDirectionValue,
  getTypeValueForEducations,
} from 'utils';

import { EDUCATION_TAB_OPTIONS } from './constants';
import { AddButton, TabsWrapper } from './styles';

export const Education = () => {
  const [tabIndex, setTabIndex] = useState(0);

  // Create data for table row
  const createData = (document: EducationObject) => {
    const educationData = {
      ...getItemDataForEducationTable(document),
      [COLUMN_IDS.ACTION]: [
        {
          label: 'Edit',
          Icon: <EditIcon />,
          action: () => {
            handleEditEducation(Number(document.id));
          },
        },
        {
          label: 'Delete',
          Icon: <DeleteIcon />,
          action: () => {
            handleDeleteEducation(
              Number(document.id),
              String(document.title),
              document.files as FileObject[],
            );
          },
        },
      ],
    };

    return educationData;
  };

  const { onOpenModal } = useModalContext();

  const [page, setPage] = useState(1);
  const [sortInfo, setSortInfo] = useState<TableSortType | undefined>({
    direction: TABLE_SORT_DIRECTIONS.ASC,
    field: null,
  });

  const { data, loading } = useGetEducationsQuery({
    variables: {
      input: {
        limit: TABLE_PAGE_LIMIT,
        offset: (page - 1) * TABLE_PAGE_LIMIT,
        ...(sortInfo?.field
          ? {
              sort: getSortDirectionValue(sortInfo.direction),
            }
          : {}),
        type: getTypeValueForEducations(tabIndex),
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddEducation = () => {
    onOpenModal(MODAL_NAME.ADD_EDIT_EDUCATION);
  };

  const handleEditEducation = (id: number) => {
    onOpenModal(MODAL_NAME.ADD_EDIT_EDUCATION, { id });
  };

  const handleDeleteEducation = (id: number, title: string, files: FileObject[]) => {
    onOpenModal(MODAL_NAME.DELETE_EDUCATION, {
      id,
      title,
      files,
    });
  };

  const handleSendNotification = () => {
    onOpenModal(MODAL_NAME.ADD_NOTIFICATIONS);
  };

  const handleChangeSort = (field: TABLE_SORT_FIELDS) => {
    setSortInfo((prev) => ({
      field: field,
      direction:
        prev?.field === field
          ? prev.direction === TABLE_SORT_DIRECTIONS.ASC
            ? TABLE_SORT_DIRECTIONS.DESC
            : TABLE_SORT_DIRECTIONS.ASC
          : TABLE_SORT_DIRECTIONS.ASC,
    }));
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setPage(1);
  }, [tabIndex]);

  const EDUCATION_TABLE_OPTIONS = [
    { id: COLUMN_IDS.ID, label: 'Id', minWidth: 100 },
    { id: COLUMN_IDS.TITLE, label: 'Type of document', handleChangeSort },
    { id: COLUMN_IDS.TYPE, label: 'Type' },
    { id: COLUMN_IDS.COMMENT, label: 'Comment' },
    { id: COLUMN_IDS.ACTION, label: '', minWidth: 56 },
  ];

  const educations = data?.getEducations?.rows;
  const allEducationsCount = data?.getEducations.count;

  const rows = educations?.map((documents) => createData(documents));
  const pageCount = allEducationsCount ? Math.ceil(allEducationsCount / TABLE_PAGE_LIMIT) : 0;

  const actionButtonTitle = getActionButtonTitleForEducations(tabIndex);

  useEffect(() => {
    if (page > 1 && rows && !rows?.length) {
      setPage((prev) => prev - 1);
    }
  }, [rows]);

  return (
    <RequestHandler loading={loading}>
      <PageHeader title='Education'>
        <Button
          variant='outlined'
          onClick={handleSendNotification}
          sx={{ width: '200px', marginLeft: 'auto' }}
        >
          Send notification
        </Button>
      </PageHeader>
      <PageContentWrapper>
        <TabsWrapper>
          <CustomTabs
            value={tabIndex}
            options={EDUCATION_TAB_OPTIONS}
            handleChange={handleChangeTabIndex}
          />
          <AddButton startIcon={<PlusIcon />} onClick={handleAddEducation}>
            {actionButtonTitle}
          </AddButton>
        </TabsWrapper>

        <TableList
          headCells={EDUCATION_TABLE_OPTIONS}
          isLoading={loading}
          rows={rows}
          sortInfo={sortInfo}
        />

        <Stack mt={'16px'} direction={'row'} justifyContent={'flex-end'}>
          <Pagination count={pageCount} page={page} onChange={handlePaginationChange} />
        </Stack>
      </PageContentWrapper>
    </RequestHandler>
  );
};
