import { Paper, styled, Table, TableCell, TableRow, Typography } from '@mui/material';

export const TableListWrapper = styled(Paper)({
  borderRadius: '4px',
  width: '100%',
  overflow: 'hidden',
});

export const TableCSS = styled(Table)({
  overflowX: 'auto',
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  maxWidth: 'calc(100vw - 298px)',
  height: '100%',
  borderCollapse: 'separate',
});

export const TableRowCSS = styled(TableRow)({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
});

export const TableCellCSS = styled(TableCell)(({ theme }) => {
  return {
    height: '56px',
    padding: '0 10px',
    borderBottom: `1px solid ${theme.palette.black8}`,
  };
});

export const EmptyText = styled(Typography)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };
});
