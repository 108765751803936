import { TableSortLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { palette } from 'theme/foundations';

import { TABLE_SORT_DIRECTIONS, TABLE_SORT_FIELDS } from '../../constants';
import { TableCellCSS, TableRowCSS } from '../../styles';
import { TableHeadCSS } from './styles';
import { TableListHeaderType } from './types';

export const TableListHeader = ({ headCells, sortInfo, isEditable }: TableListHeaderType) => {
  return (
    <TableHeadCSS iseditable={String(isEditable)}>
      <TableRowCSS>
        {headCells?.map((column) => {
          const isActiveSortColumn = column.id === sortInfo?.field;
          return (
            <TableCellCSS key={column.id} width={column.minWidth}>
              <Typography
                variant='body2'
                fontWeight={500}
                color={isEditable ? palette.black85 : palette.blue91}
              >
                {column.label}

                {column?.handleChangeSort ? (
                  <TableSortLabel
                    sx={{ ml: '5px' }}
                    active={isActiveSortColumn}
                    direction={isActiveSortColumn ? sortInfo?.direction : TABLE_SORT_DIRECTIONS.ASC}
                    onClick={() => {
                      column?.handleChangeSort?.(column.id as TABLE_SORT_FIELDS);
                    }}
                  />
                ) : null}
              </Typography>
            </TableCellCSS>
          );
        })}
      </TableRowCSS>
    </TableHeadCSS>
  );
};
