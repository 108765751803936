import { TABLE_CONTENT_TYPE } from 'components/UI/TableList/constants';
import { TableRowType } from 'components/UI/TableList/types';
import { submitEarned } from 'connectors/Earned';
import { AddEditEarnedFormData } from 'connectors/Earned/types';
import { submitPaid } from 'connectors/Paid';
import { AddEditPaidFormData } from 'connectors/Paid/types';
import { MODAL_NAME } from 'constants/index';
import { useModalContext } from 'context';
import {
  CreateEarnedMutationVariables,
  CreatePaidMutationVariables,
  CurrencyEnum,
  useCreateEarnedMutation,
  useCreatePaidMutation,
  useUpdateEarnedMutation,
  useUpdatePaidMutation,
} from 'graphql/generated/graphql';
import { EARNED_PAID_BY_USER } from 'graphql/queries';
import { getCurrencyLabel } from 'utils';

interface IProps {
  setTableRows?: React.Dispatch<React.SetStateAction<TableRowType[]>>;
  index: number;
  contentType?: TABLE_CONTENT_TYPE;
}

export const useCRUDPayments = ({ setTableRows, index, contentType }: IProps) => {
  const { onOpenModal } = useModalContext();
  // Earned
  const [createEarned] = useCreateEarnedMutation({});
  const [updateEarned] = useUpdateEarnedMutation({});

  const handleCreateEarned = (row: AddEditEarnedFormData) => {
    createEarned({
      variables: {
        ...(submitEarned(row) as CreateEarnedMutationVariables),
      },
      refetchQueries: [EARNED_PAID_BY_USER],
      onCompleted: (data) => {
        setTableRows?.((prev) => {
          const newRowsData = prev.map((item, i) => {
            if (index === i) {
              return {
                ...(row as TableRowType),
                id: data.createEarned.id,
                isInitEdit: false,
                tempId: null,
                totalProfit: data.createEarned.total_profit,
                userProfitValue: data.createEarned.user_account,
                almontProfitValue: data.createEarned.almont_account,
              };
            } else {
              return item;
            }
          });

          return newRowsData;
        });

        onOpenModal(MODAL_NAME.ADD_PAYMENT_NOTIFICATION, {
          userId: row.userId,
          description: data?.createEarned.description,
          userProfitValue: data.createEarned.user_account,
          currencyLabel: getCurrencyLabel(data.createEarned?.currency as CurrencyEnum),
          isUpdate: false,
          isEarned: true,
        });
      },
    });
  };
  const handleUpdateEarned = (row: AddEditEarnedFormData) => {
    updateEarned({
      variables: {
        ...submitEarned(row),
        earnedId: Number(row.id),
      },
      refetchQueries: [EARNED_PAID_BY_USER],
      onCompleted: (data) => {
        setTableRows?.((prev) => {
          const newRowsData = prev.map((item, i) => {
            if (index === i) {
              return {
                ...(row as TableRowType),

                totalProfit: data.updateEarned.total_profit,
                userProfitValue: data.updateEarned.user_account,
                almontProfitValue: data.updateEarned.almont_account,
              };
            } else {
              return item;
            }
          });

          return newRowsData;
        });

        return;

        onOpenModal(MODAL_NAME.ADD_PAYMENT_NOTIFICATION, {
          userId: row.userId,
          isUpdate: true,
          isEarned: true,
        });
      },
    });
  };

  // Paid

  const [createPaid] = useCreatePaidMutation({});
  const [updatePaid] = useUpdatePaidMutation({});

  const handleCreatePaid = (row: AddEditPaidFormData) => {
    createPaid({
      variables: {
        ...(submitPaid(row) as CreatePaidMutationVariables),
      },
      refetchQueries: [EARNED_PAID_BY_USER],
      onCompleted: (data) => {
        setTableRows?.((prev) => {
          const newRowsData = prev.map((item, i) => {
            if (index === i) {
              return {
                ...(row as TableRowType),
                id: data.createPaid.id,
                isInitEdit: false,
                tempId: null,
              };
            } else {
              return item;
            }
          });

          return newRowsData;
        });

        onOpenModal(MODAL_NAME.ADD_PAYMENT_NOTIFICATION, {
          userId: row.userId,
          description: data?.createPaid.description,
          userProfitValue: data.createPaid.amount,
          currencyLabel: getCurrencyLabel(data.createPaid?.currency as CurrencyEnum),
          isUpdate: false,
          isEarned: false,
        });
      },
    });
  };
  const handleUpdatePaid = (row: AddEditPaidFormData) => {
    updatePaid({
      variables: {
        ...submitPaid(row),
        earnedId: Number(row.id),
      },
      refetchQueries: [EARNED_PAID_BY_USER],
      onCompleted: () => {
        setTableRows?.((prev) => {
          const newRowsData = prev.map((item, i) => {
            if (index === i) {
              return {
                ...(row as TableRowType),
              };
            } else {
              return item;
            }
          });

          return newRowsData;
        });

        return;

        onOpenModal(MODAL_NAME.ADD_PAYMENT_NOTIFICATION, {
          userId: row.userId,
          isUpdate: true,
          isEarned: false,
        });
      },
    });
  };

  // Payments delete

  const handleDeleteRow = () => {
    setTableRows?.((prev) => prev.filter((_, i) => index !== i));
  };

  const handleOpenDeletePayment = (row: TableRowType) => {
    onOpenModal(
      contentType === TABLE_CONTENT_TYPE.EARNED ? MODAL_NAME.DELETE_EARNED : MODAL_NAME.DELETE_PAID,
      { id: row.id, onSubmit: handleDeleteRow },
    );
  };

  return {
    handleCreateEarned,
    handleUpdateEarned,
    handleOpenDeletePayment,
    handleCreatePaid,
    handleUpdatePaid,
  };
};
